import { JobOfCostForecast, QueryExpr } from '@apis/Resources';
import styled from '@emotion/styled';
import { Box, Popover, Stack, Text } from '@mantine/core';
import { ConfidenceChartSettings, ConfidenceLineChart } from '@root/Components/Charts/ConfidenceChart';
import { FillerSwitch, LoadingFiller } from '@root/Design/Filler';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IForecast } from '@root/Services/Invoices/InvoiceSchemaService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { ForecastRangeInfo } from './Models';

export function CostForecastChart({
    invoiceApi,
    filters,
    job,
    range,
    forecastStartDate,
    showHistorical,
}: {
    invoiceApi: InvoiceApiService;
    filters?: QueryExpr[];
    job?: JobOfCostForecast;
    range: ForecastRangeInfo;
    forecastStartDate: Date;
    showHistorical: boolean;
}) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Record<string, string | number | Date>[]>();
    const [extendedData, setExtendedData] = useState<{ Date: string; P90: number; P50: number; P10: number }[]>();
    const chartProps = useMemo(
        () => ({
            groups: ['Date'],
            values: ['Total'],
            settings: {
                sort: 'none',
                format: 'money-whole',
                orientation: 'Vertical',
                showTrend: true,
                barPadding: 0.8,
                enableLabel: false,
                chartColors: undefined,
                hidePoints: true,
                enableArea: false,
                confidenceStartDate: forecastStartDate ? fmtSvc.toUtcJsonShortDate(forecastStartDate) : '',
                extendedData,
            } as ConfidenceChartSettings,
        }),
        [forecastStartDate, extendedData]
    );
    const reportKey = JSON.stringify([range, filters, job?.Id, showHistorical]);
    const reportKeyRef = useMemo(() => ({ reportKey }), []);

    const reportAllData = useCallback(async (filters: QueryExpr[] | undefined, from: Date | undefined, to: Date | undefined, jobId?: string) => {
        let qb = queryBuilder<IForecast>();
        filters = filters ? filters.slice() : [];
        if (from) {
            filters.push({ Operation: 'gte', Operands: [{ Field: 'Date' }, { Value: fmtSvc.toJsonShortDate(from) }] });
        }
        if (filters.length) {
            qb = qb.where((b) => b.and(...filters!.map((f) => b.fromExpr<boolean>(f))));
        }
        const results = await qb
            .select((b) => ({
                Date: b.truncDate('day', b.model.Date, 0, from, to),
                IsWholeMonth: b.countIf(b.model.IsWholeMonth!.eq(true)),
                P10: b.sum(b.model.P10),
                P50: b.sum(b.model.P50),
                P90: b.sum(b.model.P90),
            }))
            .execute((query) => invoiceApi.queryForecastData(query, jobId));

        const data = results.Results ?? [];
        const records = data.map((r) => ({ ...r, Date: fmtSvc.toUtcJsonShortDate(new Date(r.Date)) }));
        type RecordType = (typeof records)[0];
        const dataSets = records.reduce(
            (dataSets, item) => {
                if (item.IsWholeMonth) {
                    dataSets.monthData.push(item as RecordType);
                } else if (!dataSets.monthData.length) {
                    dataSets.dayData.push(item as RecordType);
                }
                return dataSets;
            },
            { dayData: [] as RecordType[], monthData: [] as RecordType[] }
        );

        return dataSets;
    }, []);

    useEffect(() => {
        setLoading(true);
        (async () => {
            reportKeyRef.reportKey = reportKey;
            const from = showHistorical ? range?.historicalRange.from : range?.forecastRange.from;
            const to = range?.forecastRange.to;
            if ((from && to) || !job) {
                const { dayData, monthData } = await reportAllData(filters, from, to, job?.ResultDetail?.ForecastRequestJobId ?? '');
                if (reportKeyRef.reportKey === reportKey) {
                    setData(dayData);
                    setExtendedData(monthData);
                }
            }
        })().finally(() => setLoading(false));
    }, [reportKey]);

    return (
        <FillerSwitch loading={!data} noData={data?.length === 0}>
            {() => (
                <>
                    <ConfidenceLineChart data={data!} {...chartProps} />
                    <ChartInfoIcon />
                    <Box hidden={!loading} sx={{ position: 'absolute', height: '100%', width: '100%', top: 0 }}>
                        <LoadingFiller />
                    </Box>
                </>
            )}
        </FillerSwitch>
    );
}

function ChartInfoIcon() {
    return (
        <Popover position="left-start" width={400} withArrow shadow="md" radius="lg" withinPortal>
            <Popover.Target>
                <IconContainer>
                    <InfoCircle size={20} />
                </IconContainer>
            </Popover.Target>
            <Popover.Dropdown p="lg">
                <Stack>
                    <Text size="sm" color="dimmed">
                        <InfoKeyLabel>P90</InfoKeyLabel> represents an upper forecast boundary, showing there is a 10% probability costs will exceed
                        this value.
                    </Text>
                    <Text size="sm" color="dimmed">
                        <InfoKeyLabel>Median</InfoKeyLabel>is the middle point of the cost distribution, where there is an equal probability (50%) of
                        the actual cost being higher or lower.
                    </Text>
                    <Text size="sm" color="dimmed">
                        <InfoKeyLabel>P10</InfoKeyLabel>represents a lower forecast boundary, indicating that there is a 90% probability costs will
                        exceed this value.
                    </Text>
                </Stack>
            </Popover.Dropdown>
        </Popover>
    );
}

const IconContainer = styled.div`
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary[6]};
`;

const InfoKeyLabel = styled.span`
    display: inline-block;
    font-weight: bold;
    margin-right: 0.5rem;
    color: ${(p) => p.theme.black};
`;
