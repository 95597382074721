import { QueryExpr, QueryField } from '@apis/Resources';
import { IQueryExpr, Query } from '@apis/Resources/model';
import { Anchor, Box, Button, Group, Menu, Popover, Space, Stack, Sx, Text, ThemeIcon, Tooltip, useMantineTheme } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { BaseChartKeySelectionStrategy } from '@root/Components/DataGrid/BaseChartKeySelectionStrategy';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, DataGridState, GridColumnState } from '@root/Components/DataGrid/Models';
import { IncreaseIndicator } from '@root/Design/Data';
import { useDi, useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import {
    cleanBoolExpr,
    cleanExprDates,
    exprBuilder,
    groupExprs,
    INumericFluentOperators,
    queryBuilder,
    traverseExpr,
} from '@root/Services/QueryExpr';
import { addDays, getDaysInMonth, startOfDay } from 'date-fns';
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { ChartLine, Columns, FileSpreadsheet, Rotate2, Table } from 'tabler-icons-react';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { ActivityPanelModel } from '@root/Components/Actions/ActivityPanel/Models';
import { ExportRequest, ExportRequestTarget, ExportRequestType } from '@apis/Export/model';
import { postExportExport } from '@apis/Export';
import {
    DataFilterPercent,
    DataFilterSingleDate,
    FilterExpr,
    PercentTokenProvider,
    PickerInput,
    UtcDataFilterSingleDate,
} from '@root/Components/Filter/Filters';
import { useNav } from '@root/Services/NavigationService';
import {
    ChipMfgFieldDescriptor,
    ChipMfgMarketshareDigest,
    ChipMfgMarketshareModel,
    computeMktshrMfgs,
    DisplayMode,
    IChipMfgGridRow,
} from './ComputeMktshrModels';
import { useGridResizer } from '@root/Site/Invoices/Components/GridResizer';
import { useLink } from '@root/Services/Router/Router';
import { Company } from '@apis/Customers/model';
import { Picker } from '@root/Components/Picker/Picker';
import { SectionedPopover, SectionedPopoverToolbar, TooltipWhite } from '@root/Design/Primitives';
import { BasicOperationInfoProvider, IOperationInfoProvider, IQueryToken } from '@root/Components/Filter/Services';
import { Combobox } from '@root/Components/Picker/Combobox';

interface IChipMfgGridProps {
    chipSvc: ChipMfgMarketshareModel;
    scrollContainer: HTMLDivElement | null;
}
export function ChipMfgGrid({ chipSvc, scrollContainer }: IChipMfgGridProps) {
    const csp = useEventValue(chipSvc.cspFilter);
    const mode = useEventValue(chipSvc.displayMode);
    const fmtSvc = useDiMemo(FormatService);
    const theme = useMantineTheme();
    const notificationSvc = useDi(NotificationService);
    const activityPanelModel = useDi(ActivityPanelModel);
    const company = useCompany();
    const disposer = useRef(() => {});
    const { model, selectionStrategy } = useMemo(() => {
        disposer.current();
        const model = new ChipMfgGridService(chipSvc, fmtSvc, notificationSvc, activityPanelModel, company!).init();
        disposer.current = () => model.dispose();
        return {
            model,
            selectionStrategy: new ChipMfgChartKeySelectionStrategy(mode === 'company' ? 1 : 10),
        };
    }, [chipSvc, csp, mode, company]);
    const datasource = useEventValue(model.datasource);
    const rowSelector = useRowSelector({ gridSelectionStrategy: selectionStrategy, tooltip: 'Chart this data' });

    const handleSelectionChanged = useCallback(
        async ({ getItems }: { getItems: () => Promise<IChipMfgGridRow[]> }) => {
            const selections = await getItems();
            chipSvc.rowsSelected.emit(selections.map((r) => ({ item: r, color: selectionStrategy.getSelectionColor(r)! })));
        },
        [chipSvc, selectionStrategy]
    );
    const { Resizer, containerStyle, setScrollContainer } = useGridResizer();
    useEffect(() => {
        setScrollContainer(scrollContainer);
    }, [scrollContainer]);

    return (
        <Box sx={{ height: '100%', overflow: 'hidden', ...containerStyle }}>
            <DataGrid
                key={'' + csp + mode}
                statePersistence={{ key: '' + csp + mode + 'ChipMfgMarketshare' }}
                columns={model.columns}
                dataSource={datasource!}
                groupConfig={model.groupConfig}
                operationInfoProvider={model.operationInfoProvider}
                showHeaderGroups
                onModelLoaded={model.attach}
                state={model.defaultState}
                onStateLoaded={model.handleStateChanged}
                onStateChanged={model.handleStateChanged}
                selectionStrategy={selectionStrategy}
                renderRowSelector={rowSelector}
                onSelectedChanged={handleSelectionChanged}
                selectionMode="multiple"
                onRowClick="select"
                rowStyle={(item, idx, hovered) => ({ background: hovered ? theme.colors.primary[1] : '#fff' })}
                hideHeader
                allowSavedViews
                exportName="Compute Marketshare Data"
                rightToolPlaceHolder={
                    <>
                        <ColumnPickerButton model={model} visible={mode === 'custom'} />
                        <Space w="md" />
                        <RevertButton visible={model.revertEvent} onClick={model.revert} />
                        <Space w="md" />
                        <Resizer />
                        <Space w="md" />
                        <ExportDataButton exportHistoricalData={model.exportHistorical} exportVisibleData={model.export} />
                        <Space w="md" />
                    </>
                }
            />
        </Box>
    );
}

class ChipMfgGridService {
    public columns: ColumnConfig<IChipMfgGridRow>[] = [];
    public datasource = new EventEmitter<IChipMfgGridRow[]>([]);
    public defaultState?: DataGridState;
    public groupConfig = computeMktshrMfgs.mfgChipNames.reduce((result, item) => ({ ...result, [item.mfg]: { color: item.light } }), {});
    public revertEvent = new EventEmitter<boolean>(false);
    public operationInfoProvider: IOperationInfoProvider;

    private disposers: (() => void)[] = [];
    private csp = '';
    private mode: DisplayMode = 'company';
    private dataGridModel!: DataGridModel;
    private queryKey = '';

    public constructor(
        private readonly chipSvc: ChipMfgMarketshareModel,
        private readonly fmtSvc: FormatService,
        private readonly notificationSvc: NotificationService,
        private readonly activityPanelModel: ActivityPanelModel,
        private readonly company: Company
    ) {
        this.mode = chipSvc.displayMode.value;
        this.csp = chipSvc.cspFilter.value;
        this.disposers.push(this.chipSvc.selectedDateRange.listen(this.dateRangeUpdated).dispose);
        this.operationInfoProvider = new BasicOperationInfoProvider()
            .extendComparitors('month', { eq: 'is', ne: 'is not' })
            .extendDefaultOpProvider((f, t) => (t !== 'month' ? undefined : { Operation: 'eq', Operands: [f, { Value: [] }] }));
    }
    public init() {
        this.columns = this.createColumns();
        this.defaultState = this.createDefaultState(this.columns);
        return this;
    }
    public dispose() {
        this.disposers.forEach((d) => d());
        this.disposers.splice(0, Infinity);
    }

    public attach = (model: DataGridModel) => {
        this.dataGridModel = model;

        model.gridStateChanged.listen(this.handleStateChanged);
        model.stateSaved.listen(this.handleStateChanged);
        this.reload();
    };

    public getGridModel() {
        return this.dataGridModel;
    }

    public revert = () => {
        this.revertEvent.emit(false);
        return this.dataGridModel?.revert();
    };

    public handleStateChanged = () => {
        this.ensureFilterFieldColumns();
        this.revertEvent.emit(!!this.dataGridModel?.canRevert().length);
        this.reload();
    };

    private createQueryKey() {
        const columns = this.dataGridModel.gridState.columns
            .map((c) => c.id)
            .sort()
            .join(',');
        const dates = JSON.stringify(this.chipSvc.getSelectedAvailDateRange() ?? {});
        return `${columns}[${dates}]`;
    }

    private dateRangeUpdated = () => {
        this.reload();
    };

    public async reload() {
        if (this.dataGridModel) {
            const columns = new Set(this.dataGridModel.gridState.columns.map((c) => c.id));
            const currentState = this.createQueryKey();

            if (!columns.size || this.queryKey === currentState) {
                return;
            }
            this.queryKey = currentState;

            const statByMfg = this.mode === 'msp' || this.mode === 'company';
            const mfgGroups: { mfg: string; chip: string }[] = !statByMfg ? [{ mfg: 'any' as 'any', chip: 'any' }] : computeMktshrMfgs.mfgChipNames;
            const query = this.createQuery(columns, mfgGroups);
            const response = await this.chipSvc.query<Record<string, number>>(query);
            const periodDays = this.chipSvc.getDaysInPeriod();
            const hasDateRows = columns.has('Date') || columns.has('Month');

            if (currentState === this.queryKey) {
                const results = (response.Results ?? []).map((r) => {
                    const month = 'Month' in r ? this.fmtSvc.parseDateNoTime(r['Month'] as unknown as number) : null;
                    const days = 'Date' in r ? 1 : month ? getDaysInMonth(month) : periodDays;
                    const vcpuQty = (r.vCPUQuantity ?? 0) / days;
                    return {
                        ...r,
                        vCPUQuantity: vcpuQty,
                        Month: !month ? null : this.fmtSvc.formatYearMonth(month),
                        MfgStats: mfgGroups.reduce((acc, { mfg }) => {
                            const qty = (r[`${mfg}-vCPUs`] ?? 0) / days;
                            const share = !r.vCPUQuantity ? 0 : qty / vcpuQty;
                            const later = (r[`${mfg}-vCPULater`] ?? 0) / days;
                            const prior = (r[`${mfg}-vCPUPrior`] ?? 0) / days;
                            const delta = hasDateRows ? null : !!prior ? (later - prior) / prior : later ? 1 : later === prior ? 0 : null;
                            acc[mfg as keyof IChipMfgGridRow['MfgStats']] = { qty, share, delta };
                            return acc;
                        }, {} as IChipMfgGridRow['MfgStats']),
                    } as IChipMfgGridRow;
                });

                this.queryKey = currentState;
                this.datasource.emit(results);
            }
        }
    }

    public exportHistorical = async () => {
        const openActivity = this.activityPanelModel.toggleRequested.emit;
        const name = 'Compute Marketshare Data';
        const { filters } = this.getDateRangeCriteria();
        if (this.csp) {
            const { builder: xb } = exprBuilder<ChipMfgMarketshareDigest>();
            filters.push(xb.resolve(xb.model.CloudProvider.eq(this.csp)));
        }
        cleanExprDates(filters);
        const config = this.dataGridModel.getDashboardConfigForExport(name, name, filters as QueryExpr[]);

        const fieldLabels = this.chipSvc.getFieldDescriptors().reduce((acc, f) => ({ ...acc, [f.field]: f.label }), {});
        try {
            const requestBody: ExportRequest = {
                CompanyId: this.company?.Id,
                Target: ExportRequestTarget.ComputeMarketShareQuery,
                Type: ExportRequestType.Excel,
                DashboardConfig: config,
                TargetParameters: {
                    ...this.chipSvc.getDataRelationshipParameters(),
                    UserFriendlyColumnNames: fieldLabels,
                    OmittedFields: ['MspId', 'CustomerId', 'AverageRate', 'TotalCost', 'vCpuHours', 'vCPUs'],
                },
            };
            await postExportExport(requestBody);
            this.notificationSvc.notify(
                'Export Compute Marketshare Data',
                'Export Requested. Please check the activity log for download',
                'primary',
                <ThemeIcon variant="light" size="xl" radius="xl">
                    <FileSpreadsheet />
                </ThemeIcon>,
                openActivity
            );
        } catch {
            this.notificationSvc.notify('Export Failed', `Error: Export failed. Please check the activity log for status details.`, 'error', null);
        }
    };

    public export = async () => {
        await this.dataGridModel.export();
        return;
    };

    private getDateRangeCriteria() {
        const { createExpr } = exprBuilder<ChipMfgMarketshareDigest>();
        const { from: statStartDate, to: statEndDate } = this.chipSvc.getSelectedAvailDateRange();

        const filters = [] as IQueryExpr[];
        filters.push(createExpr((b) => b.model.Date.onOrAfter(statStartDate)));
        filters.push(createExpr((b) => b.model.Date.onOrBefore(statEndDate)));

        return { statStartDate, statEndDate, filters };
    }

    private createQuery(columns: Set<string>, mfgGroups: { mfg: string; chip: string }[]) {
        const builder = exprBuilder<ChipMfgMarketshareDigest>();
        const createExpr = builder.createExpr.bind(builder);
        const { statStartDate, statEndDate, filters: dateFilters } = this.getDateRangeCriteria();
        const periodDays = this.chipSvc.getDaysInPeriod();
        const midDays = Math.floor(periodDays / 2);
        const midStart = addDays(statStartDate, midDays);
        const midEnd = addDays(statEndDate, -midDays);

        const aggregations = mfgGroups.flatMap(({ mfg, chip }) => {
            return [
                {
                    Alias: `${mfg}-vCPUs`,
                    Expr: createExpr((b) =>
                        b.aggIf(mfg === 'any' ? b.model.ProcessorFamily.isNotNull() : b.model.ProcessorFamily.eq(chip), b.sum(b.model.vCPUQuantity))
                    ),
                },
                {
                    Alias: `${mfg}-vCPUPrior`,
                    Expr: createExpr((b) =>
                        b.aggIf(
                            b.and(
                                mfg === 'any' ? b.model.ProcessorFamily.isNotNull() : b.model.ProcessorFamily.eq(chip),
                                b.model.Date.onOrBefore(midStart)
                            ),
                            b.sum(b.model.vCPUQuantity)
                        )
                    ),
                },
                {
                    Alias: `${mfg}-vCPULater`,
                    Expr: createExpr((b) =>
                        b.aggIf(
                            b.and(
                                mfg === 'any' ? b.model.ProcessorFamily.isNotNull() : b.model.ProcessorFamily.eq(chip),
                                b.model.Date.onOrAfter(midEnd)
                            ),
                            b.sum(b.model.vCPUQuantity)
                        )
                    ),
                },
            ];
        });
        const createAggExpr = (d: ChipMfgFieldDescriptor) =>
            createExpr((b) => {
                const fieldExpr = b.model[d.field] as number & INumericFluentOperators;
                return d.aggs.includes('group') ? fieldExpr : d.aggs.includes('avg') ? b.avg(fieldExpr) : b.sum(fieldExpr);
            });
        const fields = this.chipSvc.getFieldDescriptors();
        const aggsExprs = fields.filter((f) => !f.aggs.includes('group')).map((f) => ({ Alias: f.field, Expr: createAggExpr(f) }));
        aggregations.push(...aggsExprs.filter((f) => f.Alias !== 'vCPUQuantity' && columns.has(f.Alias)));
        const possibleGroups = fields.filter((f) => f.aggs.includes('group')).map((f) => f.field);
        const selectedGroups = possibleGroups
            .filter((f) => columns.has(f))
            .map((field) => ({ Alias: field as string, Expr: createExpr((b) => b.model[field]) }));
        if (columns.has('Month')) {
            selectedGroups.push({ Alias: 'Month', Expr: createExpr((b) => b.truncDate('month', b.model.Date, 0)) });
        }
        if (this.mode === 'msp') {
            selectedGroups.push({ Alias: 'MspId', Expr: createExpr((b) => b.model.MspId) });
        }
        if (this.mode === 'company') {
            selectedGroups.push({ Alias: 'CustomerId', Expr: createExpr((b) => b.model.CustomerId) });
        }
        const filters: IQueryExpr[] = [...dateFilters];
        if (this.csp) {
            filters.push(createExpr((b) => b.model.CloudProvider.eq(this.csp)));
        }

        const query = {
            Select: [...selectedGroups, ...aggregations, { Alias: 'vCPUQuantity', Expr: createExpr((b) => b.sum(b.model.vCPUQuantity)) }],
            Where: !filters.length ? null : filters.length === 1 ? filters[0] : { Operation: 'and', Operands: filters },
        } as Query;

        return query;
    }

    private createDefaultState(columns: ColumnConfig<IChipMfgGridRow>[]) {
        const colWidthLookup = columns.reduce((result, c) => result.set(c.id, c.defaultWidth), new Map<string, number>());
        const { defaultCols, available } = this.getModeAvailableColumns()[this.mode];
        const visibleCols = defaultCols ?? available;
        return {
            columns: visibleCols.map((c) => ({ id: c, width: colWidthLookup.get(c) ?? 120 })),
            filters: [],
            sort: [],
        } as DataGridState;
    }

    private ensureFilterFieldColumns() {
        if (this.mode === 'custom') {
            const selectedCols = new Set(this.dataGridModel.gridState.columns.map((c) => c.id));
            const availableCols = new Map(this.columns.map((c) => [c.id, c]));

            const filterExpr = groupExprs('and', this.dataGridModel.gridState.filters ?? []);
            const fieldsToAdd: GridColumnState[] = [];
            if (filterExpr) {
                traverseExpr(filterExpr, (x) => {
                    if ('Field' in x) {
                        const colConfig = availableCols.get(x.Field);
                        if (!selectedCols.has(x.Field) && colConfig) {
                            fieldsToAdd.push({ id: x.Field, width: colConfig.defaultWidth });
                        }
                    }
                });

                if (fieldsToAdd.length) {
                    this.dataGridModel.gridState.columns.splice(0, 0, ...fieldsToAdd);
                }
            }
        }
    }

    private createBaseColumnOptions(accessor: keyof IChipMfgGridRow, defaultWidth: number, label?: string): ColumnConfig<IChipMfgGridRow> {
        const descriptor = this.chipSvc.getFieldDescriptor(accessor);
        const header = label ?? descriptor?.label ?? accessor;
        return {
            id: accessor,
            accessor,
            sortField: accessor,
            header,
            defaultWidth,
            helpText: descriptor?.description,
        };
    }

    private createNumericColumn(accessor: keyof IChipMfgGridRow, options?: Partial<ColumnConfig<IChipMfgGridRow>>): ColumnConfig<IChipMfgGridRow> {
        const baseOptions = this.createBaseColumnOptions(accessor, 120, options?.header);
        const descriptor = this.chipSvc.getFieldDescriptor(accessor);
        const { description, aggs } = descriptor ?? {};
        const aggPrefix = aggs?.includes('avg') ? 'Average' : aggs?.includes('sum') ? 'Sum' : '';
        const helpTextPrefix = aggPrefix ? `(${aggPrefix}) ` : '';
        const descriptorFmt = descriptor?.format ? this.chipSvc.getFieldFormatter(descriptor?.format) : undefined;
        const formatter = (item: IChipMfgGridRow) => {
            const value = item[accessor];
            return typeof value !== 'number' ? 'N/A' : descriptorFmt ? descriptorFmt(value) : value;
        };

        return {
            ...baseOptions,
            cellRenderer: (item) => <>{formatter(item)}</>,
            formatter: (item) => formatter(item).toString(),
            exportOptions: { renderer: (item) => formatter(item) },
            helpText: helpTextPrefix + description,
            type: 'number' as const,
            defaultWidth: 120,
            filter: true,
            align: 'right',
            ...options,
        };
    }

    private createStringColumn(
        accessor: keyof IChipMfgGridRow,
        options: { defaultWidth: number } & Partial<ColumnConfig<IChipMfgGridRow>>
    ): ColumnConfig<IChipMfgGridRow> {
        const baseOptions = this.createBaseColumnOptions(accessor, options.defaultWidth, options?.header);
        return {
            ...baseOptions,
            cellRenderer: (item) => {
                return <>{item[accessor]}</>;
            },
            type: 'string' as const,
            filter: {
                options: {
                    getValueProvider: (field: QueryField) => {
                        const qb = queryBuilder<Record<string, string>>();
                        const getValues = async (filter: string) => {
                            const response = await qb
                                .select((b) => ({
                                    term: b.values(b.fromExpr<string>(field), filter),
                                }))
                                .execute((q) => this.chipSvc.query(q));

                            return (response.Results ?? []).map((r) => r.term);
                        };
                        return async (filter: string) => await getValues(filter);
                    },
                },
                filterType: 'string' as const,
                name: options?.header ?? accessor,
                filterField: accessor,
            },
            ...options,
        };
    }

    private getModeAvailableColumns(): Record<DisplayMode, { available: string[]; defaultCols?: string[] }> {
        const { mfgChipNames } = computeMktshrMfgs;
        return {
            company: {
                available: ['CustomerName', 'ActualCost', ...mfgChipNames.flatMap(({ mfg }) => [`${mfg}-qty`, `${mfg}-share`, `${mfg}-delta`])],
            },
            msp: { available: ['MspName', 'ActualCost', ...mfgChipNames.flatMap(({ mfg }) => [`${mfg}-qty`, `${mfg}-share`, `${mfg}-delta`])] },
            custom: {
                available: [
                    'ActualCost',
                    `any-delta`,
                    'CalculatedRate',
                    `CloudProvider`,
                    'CustomerName',
                    'Date',
                    'DistinctInstanceCount',
                    'HoursConsumed',
                    'InstanceVCPUs',
                    'InstanceType',
                    'ListCost',
                    'ListPrice',
                    'Month',
                    'MspName',
                    'PhysicalProcessor',
                    'ProcessorFamily',
                    'PurchaseMethod',
                    'Region',
                    'vCPUQuantity',
                    'vCPUHours',
                ],
                defaultCols: [
                    'CustomerName',
                    'ProcessorFamily',
                    'vCPUQuantity',
                    'vCPUHours',
                    'HoursConsumed',
                    'InstanceVCPUs',
                    `any-delta`,
                    `CloudProvider`,
                    'PurchaseMethod',
                    'ActualCost',
                    'CalculatedRate',
                    'Region',
                    'DistinctInstanceCount',
                ],
            },
            'instance-type': {
                available: [
                    'InstanceType',
                    'PhysicalProcessor',
                    'ProcessorFamily',
                    'vCPUQuantity',
                    'vCPUHours',
                    'HoursConsumed',
                    'InstanceVCPUs',
                    `any-delta`,
                    `CloudProvider`,
                    'Region',
                    'CalculatedRate',
                    'ActualCost',
                    'ListPrice',
                    'ListCost',
                    'PurchaseMethod',
                    'DistinctInstanceCount',
                ],
            },
        };
    }

    private createColumns() {
        const allColumns = [] as ColumnConfig<IChipMfgGridRow>[];

        allColumns.push(
            this.createNumericColumn('ActualCost'),
            this.createNumericColumn('CalculatedRate'),
            this.createStringColumn('CloudProvider', { defaultWidth: 100, align: 'right', header: 'CSP' }),
            this.createStringColumn('CustomerName', { defaultWidth: 200 }),
            this.createNumericColumn('DistinctInstanceCount'),
            this.createNumericColumn('HoursConsumed'),
            this.createNumericColumn('InstanceVCPUs'),
            this.createStringColumn('InstanceType', { defaultWidth: 200, header: 'Instance Type' }),
            this.createNumericColumn('ListCost'),
            this.createNumericColumn('ListPrice'),
            this.createStringColumn('MspName', {
                defaultWidth: 160,
                cellRenderer: (item) => {
                    return <MspGridCell chipSvc={this.chipSvc} row={item} />;
                },
            }),
            this.createStringColumn('PhysicalProcessor', { defaultWidth: 160, align: 'right', header: 'Processor' }),
            this.createStringColumn('ProcessorFamily', { defaultWidth: 160, align: 'right', header: 'Vendor' }),
            this.createStringColumn('PurchaseMethod', { defaultWidth: 160 }),
            this.createStringColumn('Region', {
                defaultWidth: 160,
                align: 'right',
            }),
            this.createNumericColumn('vCPUQuantity'),
            this.createNumericColumn('vCPUHours'),
            {
                id: `any-delta`,
                accessor: (r) => r.MfgStats['any']?.delta ?? 0,
                header: `% Change`,
                helpText: `% Change in vCPUs between first and last half of selected period`,
                defaultWidth: 100,
                type: 'number',
                sortField: `any-delta`,
                filter: {
                    filterType: 'number',
                    name: `% Change`,
                    filterField: `any-delta`,
                    valueRenderer: DataFilterPercent,
                    tokenProvider: PercentTokenProvider,
                },
                align: 'center',
                formatter: (r) => this.fmtSvc.formatPercent(r.MfgStats['any'].delta ?? 0, 10000),
                exportOptions: { renderer: (r) => this.fmtSvc.formatPercent(r.MfgStats['any'].delta ?? 0, 10000) },
                cellRenderer: (r) =>
                    typeof r.MfgStats['any']?.delta !== 'number' ? (
                        'N/A'
                    ) : (
                        <>
                            {this.fmtSvc.formatPercent(r.MfgStats['any'].delta ?? 0, 10000)}
                            <IncreaseIndicator size="sm" value={r.MfgStats['any'].delta ?? 0} />
                        </>
                    ),
            },
            {
                ...this.createBaseColumnOptions('Date', 150),
                type: 'date',
                defaultHidden: true,
                formatter: (r) => this.fmtSvc.tryOrFallback((f) => f.formatDate(this.fmtSvc.parseDateNoTime(r.Date)), ''),
                exportOptions: {
                    renderer: (r) => this.fmtSvc.tryOrFallback((f) => f.formatDate(this.fmtSvc.parseDateNoTime(r.Date)), ''),
                },
                filter: {
                    filterType: 'date',
                    name: 'Date',
                    filterField: 'Date',
                },
            },
            {
                ...this.createBaseColumnOptions('Month', 120),
                type: 'string',
                formatter: (r) => this.fmtSvc.tryOrFallback((f) => f.formatShortMonthYear(this.fmtSvc.parseDateNoTime(r.Month)), ''),
                exportOptions: {
                    renderer: (r) => this.fmtSvc.tryOrFallback((f) => f.formatShortMonthYear(this.fmtSvc.parseDateNoTime(r.Month)), ''),
                },
                sortField: 'Month',
                filter: createMonthColumnFilterOptions(this.fmtSvc, this.chipSvc),
                defaultHidden: true,
            }
        );

        computeMktshrMfgs.mfgChipNames
            .filter((n) => !this.csp || n.universal || n.mfg === this.csp)
            .forEach(({ mfg }) => {
                allColumns.push(
                    {
                        id: `${mfg}-qty`,
                        accessor: (r) => r.MfgStats[mfg].qty ?? 0,
                        header: `vCPUs`,
                        defaultWidth: 100,
                        type: 'number',
                        sortField: `${mfg}-qty`,
                        filter: {
                            filterType: 'number',
                            name: `${mfg} vCPUs`,
                            filterField: `${mfg}-qty`,
                        },
                        groupName: mfg,
                        align: 'right',
                        formatter: (r) => this.fmtSvc.formatDecimal2(r.MfgStats[mfg].qty ?? 0),
                        exportOptions: { renderer: (r) => this.fmtSvc.formatDecimal2(r.MfgStats[mfg].qty ?? 0) },
                    },
                    {
                        id: `${mfg}-share`,
                        accessor: (r) => r.MfgStats[mfg].share ?? 0,
                        header: `Marketshare`,
                        defaultWidth: 100,
                        type: 'number',
                        sortField: `${mfg}-share`,
                        filter: {
                            filterType: 'number',
                            name: `${mfg} Marketshare`,
                            filterField: `${mfg}-share`,
                            valueRenderer: DataFilterPercent,
                            tokenProvider: PercentTokenProvider,
                        },
                        groupName: mfg,
                        align: 'right',
                        formatter: (r) => this.fmtSvc.formatPercent(r.MfgStats[mfg].share ?? 0, 10000),
                        exportOptions: { renderer: (r) => this.fmtSvc.formatPercent(r.MfgStats[mfg].share ?? 0, 10000) },
                    },
                    {
                        id: `${mfg}-delta`,
                        accessor: (r) => r.MfgStats[mfg].delta ?? 0,
                        header: `% Change`,
                        helpText: `% Change in vCPUs between first and last half of selected period`,
                        defaultWidth: 100,
                        type: 'number',
                        sortField: `${mfg}-delta`,
                        filter: {
                            filterType: 'number',
                            name: `${mfg} % Change`,
                            filterField: `${mfg}-delta`,
                            valueRenderer: DataFilterPercent,
                            tokenProvider: PercentTokenProvider,
                        },
                        align: 'center',
                        groupName: mfg,
                        exportOptions: { renderer: (r) => this.fmtSvc.formatPercent(r.MfgStats[mfg].delta ?? 0, 10000) },
                        cellRenderer: (r) =>
                            r.MfgStats[mfg].delta === null ? (
                                'N/A'
                            ) : (
                                <>
                                    {this.fmtSvc.formatPercent(r.MfgStats[mfg].delta ?? 0, 10000)}
                                    <IncreaseIndicator size="sm" value={r.MfgStats[mfg].delta ?? 0} />
                                </>
                            ),
                    }
                );
            });

        if (this.mode !== 'custom') {
            for (const column of allColumns) {
                column.noRemove = true;
            }
        }

        const availableCols = this.getModeAvailableColumns();
        const { available } = availableCols[this.mode];
        const result = allColumns.filter((c) => available.includes(c.id));

        result.sort((a, b) => (a.header?.toString() ?? '').localeCompare(b.header?.toString() ?? ''));

        return result;
    }
}

function RevertButton({ visible, onClick }: { visible: EventEmitter<boolean>; onClick: () => void }) {
    const canRevert = useEventValue(visible);

    return !canRevert ? null : (
        <Tooltip label="Reset Table">
            <ThemeIcon sx={{ cursor: 'pointer' }} variant="light" onClick={onClick} data-atid="grid-revert-icon">
                <Rotate2 />
            </ThemeIcon>
        </Tooltip>
    );
}

//#region Grid toolbar
function ColumnPickerButton({ model, visible }: { model: ChipMfgGridService; visible: boolean }) {
    const [opened, { close, toggle }] = useToggle(false);

    const buttonSx: Sx = { height: 30, fontWeight: 'normal', transition: '500ms all', opacity: visible ? 1 : 0 };

    return (
        <Popover opened={opened} onClose={close} position="left" withArrow arrowSize={10} offset={-10} shadow="lg">
            <Popover.Target>
                <Button hidden={!visible} onClick={toggle} sx={buttonSx} radius="lg" leftIcon={<Columns size={16} />}>
                    Select Columns
                </Button>
            </Popover.Target>
            <SectionedPopover sx={{ width: 300 }}>{!opened ? null : <ColumnPicker model={model} onClose={close} />}</SectionedPopover>
        </Popover>
    );
}

function ColumnPicker({ model, onClose }: { model: ChipMfgGridService; onClose: () => void }) {
    const gridModel = model.getGridModel();
    type Option = { label: string; defaultWidth: number; description?: string; column: ColumnConfig<IChipMfgGridRow> };
    const { available, selected, origSelected } = useMemo(() => {
        if (!gridModel) {
            return { selected: [], available: [], origSelected: [] };
        }
        const columnLookup = model.columns
            .map((c) => ({ label: c.header ?? c.id, defaultWidth: c.defaultWidth, description: c.helpText, column: c }))
            .reduce((result, item) => result.set(item.column.id, item), new Map<string, Option>());
        const selected = gridModel.gridState.columns.map((c) => columnLookup.get(c.id)!).filter((c) => !!c);
        const available = [...columnLookup.values()].sort((a, b) => a.label.localeCompare(b.label));
        return { selected, available, origSelected: selected.slice() };
    }, [gridModel]);

    const applySelection = useCallback(() => {
        const { added, kept } = selected.reduce(
            (result, item) => {
                const orig = origSelected.find((o) => o.column.id === item.column.id);
                if (!orig) {
                    result.added.push(item);
                } else {
                    result.kept.push(item);
                }
                return result;
            },
            { added: [] as Option[], kept: [] as Option[] }
        );
        gridModel.applyColumnSelection([...added, ...kept].map((c) => c.column));
        onClose();
    }, [gridModel]);

    const updateSelections = useCallback((items: Option[]) => selected.splice(0, Infinity, ...items), [selected]);

    return (
        <>
            <SectionedPopoverToolbar>
                <Group position="right">
                    <Button onClick={applySelection}>Apply</Button>
                    <Button onClick={onClose} variant="outline">
                        Cancel
                    </Button>
                </Group>
            </SectionedPopoverToolbar>
            <Box mx={-16} mb={-12}>
                <Picker
                    items={available}
                    selections={selected}
                    nameAccessor={(o) => o.label}
                    onChange={updateSelections}
                    onApply={applySelection}
                    noFilter
                    onCancel={onClose}
                    minimizeHeight
                    height={400}
                    width="100%"
                    renderItem={(item) => (
                        <TooltipWhite disabled={!item.description} withinPortal label={item.description} position="right">
                            <span>{item.label}</span>
                        </TooltipWhite>
                    )}
                />
            </Box>
        </>
    );
}

function ExportDataButton(props: { exportHistoricalData: () => void; exportVisibleData: () => void }) {
    return (
        <>
            <Menu>
                <Menu.Dropdown>
                    <Menu.Item icon={<ChartLine size={16} />} onClick={props.exportHistoricalData}>
                        Export Historical Data
                    </Menu.Item>
                    <Menu.Item icon={<Table size={16} />} onClick={props.exportVisibleData}>
                        Export Visible Rows
                    </Menu.Item>
                </Menu.Dropdown>
                <Menu.Target>
                    <Button variant="outline" sx={{ height: 30, fontWeight: 'normal' }} radius="lg" leftIcon={<FileSpreadsheet size={16} />}>
                        Export
                    </Button>
                </Menu.Target>
            </Menu>
        </>
    );
}

class ChipMfgChartKeySelectionStrategy extends BaseChartKeySelectionStrategy<IChipMfgGridRow> {}

function useRowSelector({
    gridSelectionStrategy,
    color,
    tooltip,
}: {
    gridSelectionStrategy: ChipMfgChartKeySelectionStrategy;
    color?: string;
    tooltip?: string;
}) {
    return useCallback(
        (item: IChipMfgGridRow | null, { toggle }: { toggle: (selected: boolean) => void }) => (
            <ChartKeyRowSelector selectionStrategy={gridSelectionStrategy} toggle={toggle} item={item} color={color} tooltip={tooltip} />
        ),
        [gridSelectionStrategy, color, tooltip]
    );
}
//#endregion

export function ChartKeyRowSelector({
    selectionStrategy,
    item,
    toggle,
    color,
    tooltip,
}: {
    selectionStrategy: BaseChartKeySelectionStrategy<any>;
    item: any | null;
    toggle: (selected: boolean) => void;
    color?: string;
    tooltip?: string;
}) {
    const theme = useMantineTheme();
    useEvent(selectionStrategy.selectionChanged);
    const isSelected = item ? selectionStrategy.isSelected(item) : false;
    const itemColor = item ? selectionStrategy.getSelectionColor(item) : undefined;
    const allSelected = selectionStrategy.isAllSelected();
    const selectAll = useCallback(() => {
        toggle(true);
    }, [selectionStrategy, toggle]);
    const iconColor = allSelected ? theme.colors.primary[6] : isSelected ? color ?? itemColor ?? theme.colors.primary[6] : theme.colors.gray[4];

    return (
        <Tooltip withinPortal label={tooltip} position="right">
            {item ? (
                <i className="ti ti-chart-histogram" style={{ color: iconColor }} />
            ) : (
                <i className="ti ti-chart-histogram" style={{ color: theme.colors.primary[6] }} onClick={selectAll} />
            )}
        </Tooltip>
    );
}

function MspGridCell({ row, chipSvc }: { row: IChipMfgGridRow; chipSvc: ChipMfgMarketshareModel }) {
    const nav = useNav();
    const relId = (!row.MspId ? 0 : chipSvc.getRelId(row.MspId)).toString();
    const url = nav.getDescendUrl('cpu-mktshr', chipSvc.getLinkParams({ relId }));
    const showLink = chipSvc.displayMode.value === 'msp';
    const link = useLink();

    return showLink ? <Anchor {...link(url)}>{row.MspName}</Anchor> : <>{row.MspName}</>;
}

function createMonthColumnFilterOptions(fmtSvc: FormatService, chipSvc: ChipMfgMarketshareModel) {
    const getMonthOptions = () => {
        const months = Array.from(chipSvc.getDatesInPeriod())
            .map((d) => ({ label: fmtSvc.formatShortMonthYear(d), value: fmtSvc.formatYearMonth(d) }))
            .reduce((result, m) => result.set(m.value, m), new Map<string, { label: string; value: string }>())
            .values();
        const sortedMonths = Array.from(months).sort((a, b) => a.value.localeCompare(b.value));
        return sortedMonths;
    };
    function MonthPicker({ filter }: { filter: FilterExpr; apply: () => void }) {
        const options = getMonthOptions();
        return <PickerInput onChange={(v) => filter.setValue(v)} value={filter.value} valueProvider={options} canShowAny={false} />;
    }
    function tokenProvider(filter: FilterExpr, tokens: IQueryToken[]) {
        return tokens.map((token) => {
            if (token.type === 'constant') {
                const rawTexts = filter.value instanceof Array ? filter.value : typeof filter.value === 'string' ? [filter.value] : [];
                const monthsText = rawTexts
                    .map((t) => fmtSvc.tryOrFallback((f) => f.formatShortMonthYear(f.fromMonthYear(t)), ''))
                    .filter((t) => !!t);
                const text = monthsText.join(' or ');
                return {
                    expr: token.expr,
                    name: text,
                    text,
                    type: 'constant',
                } as IQueryToken;
            }
            return token;
        });
    }
    return {
        valueRenderer: MonthPicker,
        tokenProvider,
        filterType: 'month',
        name: 'Month',
        filterField: 'Month',
    };
}
