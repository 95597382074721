import { QueryResult } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { inject, injectable } from 'tsyringe';
import { FormatService } from '../FormatService';
import { normalizeExpr } from '../QueryExpr';

@injectable()
/**
 * Wraps a query API to handle date-only fields and correctly convert their results time-zone agnostic date values
 */
export class DateOnlyQueryApiWrapper {
    public constructor(@inject(FormatService) private readonly fmtSvc: FormatService) {}

    public wrap<T>(wrapped: (query: Query) => Promise<QueryResult<T>>): (query: Query) => Promise<QueryResult<T>> {
        return async (query: Query) => {
            const dateFields = this.getDateSelectFields(query);
            const result = await wrapped(query);
            return !dateFields.length ? result : this.convertDateFields(result, dateFields);
        };
    }

    private getDateSelectFields(query: Query): string[] {
        const result = new Set<string>();

        if (query.Select) {
            for (const item of query.Select) {
                if (item.Expr) {
                    normalizeExpr(item.Expr);
                    if ('Operation' in item.Expr && item.Expr.Operation?.toLocaleLowerCase() === 'truncdate') {
                        result.add(item.Alias ?? '');

                        const operands = item.Expr?.Operands ?? [];
                        if (operands[2]) {
                            // Request should use a timezone offset of zero
                            operands[2] = { Value: 0 };
                        }
                    }
                }
            }
        }

        return [...result];
    }

    private convertDateFields<T>(result: QueryResult<T>, dateFields: string[]): QueryResult<T> {
        for (const item of result.Results ?? []) {
            if (item) {
                const untypedItem = item as any;
                for (const field of dateFields) {
                    if (untypedItem[field]) {
                        untypedItem[field] = this.fmtSvc.parseDateNoTime(untypedItem[field]);
                    }
                }
            }
        }
        return result;
    }
}
