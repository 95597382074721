import { IQueryExpr } from '@apis/Customers/model';
import { getInvoiceRuleGetProcessingReport, postDailyRollupMultiQuery, postForecastMultiQuery, postMonthlyRollupMultiQuery } from '@apis/Invoices';
import { ObjectQueryResult, PostDailyRollupQueryParams, PostMonthlyRollupQueryParams, Query } from '@apis/Invoices/model';
import { QueryResult } from '@apis/Resources';
import { endOfMonth, startOfMonth } from 'date-fns';
import { inject, injectable } from 'tsyringe';
import { AsyncBundler } from '../AsyncBundler';
import { FormatService } from '../FormatService';
import { normalizeAndTraverseQuery } from '../QueryExpr';
import { InvoiceSchemaService, ITranformationDetail } from './InvoiceSchemaService';

type QueryExecutor = (queries: Query[]) => Promise<ObjectQueryResult[]>;
@injectable()
export class InvoiceApiService {
    private asyncBundler = new AsyncBundler();

    public constructor(
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService
    ) {}

    public async getDateRange() {
        const months = await this.getMonths();
        return months
            .map((m) => ({ start: startOfMonth(m), end: endOfMonth(m) }))
            .reduce(
                (result, { start, end }) => ({
                    from: result.from ? (start < result.from ? start : result.from) : start,
                    to: result.to ? (end > result.to ? end : result.to) : end,
                }),
                {} as { from?: Date; to?: Date }
            );
    }

    public async getMonths() {
        const { months } = await this.invoiceSchemaSvc.getDailySchemaTransformationDetail();
        return months.sort().map((m) => this.fmtSvc.parseDateNoTime(m));
    }

    public async getProcessingReport(month: Date) {
        return await getInvoiceRuleGetProcessingReport({ month: this.fmtSvc.toJsonShortDate(month) });
    }

    /**
     * Query daily invoice rollup. The passed date will be used to determine the index, and criteria will be added to filter results to that usage month
     * @param query
     * @param usageDate
     * @returns
     */
    public queryByUsageMonth(query: Query, usageDate: Date) {
        const month = this.fmtSvc.formatYearMonth(usageDate);
        const params = { from: month, to: month } as PostDailyRollupQueryParams;
        const dateCriteria: IQueryExpr[] = [{ Operation: 'eq', Operands: [{ Field: 'UsageMonth' }, { Value: month }] }];
        const where = { Operation: 'and', Operands: dateCriteria };
        if (query.Where) {
            where.Operands.push(query.Where);
        }
        query.Where = where;
        const key = `queryByUsageMonth-${month}`;
        return this.cleanAndBundle(key, query, this.dailyQueryRunner(params));
    }

    /**
     * Query daily invoice rollup. Multi-query will be used by default for bundling.
     * @param query
     * @param indexMonthRange inclusive months of indices to query, day will be ignored
     * @param appendDateCriteria true to auto-add indexMonthRange's dates as criteria to query
     * @returns
     */
    public query<T = unknown>(query: Query, { from, to }: { from?: Date; to?: Date }, appendDateCriteria: boolean = true): Promise<QueryResult<T>> {
        const params = {} as PostDailyRollupQueryParams;
        const dateCriteria: IQueryExpr[] = [];
        if (from) {
            params.from = this.fmtSvc.toJsonShortDate(from);
        }
        if (to) {
            params.to = this.fmtSvc.toJsonShortDate(to);
        }
        if (appendDateCriteria) {
            if (params.from) {
                dateCriteria.push({ Operation: 'gte', Operands: [{ Field: 'ChargePeriodStart' }, { Value: params.from }] });
            }
            if (params.to) {
                dateCriteria.push({ Operation: 'lte', Operands: [{ Field: 'ChargePeriodStart' }, { Value: params.to }] });
            }
        }
        if (dateCriteria.length) {
            const where = { Operation: 'and', Operands: dateCriteria };
            if (query.Where) {
                where.Operands.push(query.Where);
            }
            query.Where = where;
        }

        const key = `${params.from}-${params.to}`;
        return this.cleanAndBundle(key, query, this.dailyQueryRunner(params)) as Promise<QueryResult<T>>;
    }

    public queryMonthlyRollup(query: Query, months: Date[]) {
        const params = {
            months: months.map((m) => this.fmtSvc.toJsonShortDate(m)),
        } as PostMonthlyRollupQueryParams;
        const key = JSON.stringify(params);
        return this.cleanAndBundle(key, query, this.monthlyQueryRunner(params));
    }

    public queryForecastData(query: Query, jobId?: string) {
        const where: IQueryExpr[] = !jobId ? [] : [{ Operation: 'eq', Operands: [{ Field: 'JobId' }, { Value: jobId }] }];
        if (query.Where) {
            where.push(query.Where);
        }
        query.Where = !where.length ? undefined : where.length === 1 ? where[0] : { Operation: 'and', Operands: where };
        const key = `forecast-${jobId}`;
        return this.cleanAndBundle(key, query, (queries: Query[]) => postForecastMultiQuery(queries));
    }

    private monthlyQueryRunner = (params: PostMonthlyRollupQueryParams) => {
        return async (queries: Query[]) => {
            const transformations = await this.invoiceSchemaSvc.getMonthlySchemaTransformationDetail();
            queries = await this.applySchemaFieldTransformations(queries, transformations);
            return postMonthlyRollupMultiQuery(queries, params);
        };
    };

    private dailyQueryRunner = (params: PostDailyRollupQueryParams) => {
        return async (queries: Query[]) => {
            const transformations = await this.invoiceSchemaSvc.getDailySchemaTransformationDetail();
            queries = await this.applySchemaFieldTransformations(queries, transformations);
            return postDailyRollupMultiQuery(queries, params);
        };
    };

    private async cleanAndBundle(key: string, query: Query, handler: QueryExecutor) {
        return this.asyncBundler.bundle(key, query, (queries: Query[]) => handler(queries));
    }

    private applySchemaFieldTransformations(queries: Query[], transformationDetails: ITranformationDetail) {
        return queries.map((q) =>
            normalizeAndTraverseQuery(q, (expr) => {
                if ('Field' in expr && !!expr.Field) {
                    expr.Field = transformationDetails.getIndexedPath(expr.Field) ?? expr.Field;
                }
            })
        );
    }
}
