import { CompanyStat, CompanyStatCompanyType, CompanyStatQueryResult, CompanyType, IQueryExpr } from '@apis/Customers/model';
import type { Company } from '@apis/Customers/model';
import { MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { DataGrid } from '@root/Components/DataGrid';
import { getCompanyGetCompany, postCompanyStatsQueryCompanyStats } from '@apis/Customers';
import { ColumnConfig, DataColumnConfig, DataGridProps } from '@root/Components/DataGrid/Models';
import { useDi, useDiContainer } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { ActionIcon, Group, Radio, Text, Tooltip } from '@mantine/core';
import { SchemaService, TypeInfo, queryBuilder } from '@root/Services/QueryExpr';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { useLink } from '@root/Services/Router/Router';
import { inject, injectable } from 'tsyringe';
import { FieldInfoColumnAdapter } from '@root/Components/DataGrid/FieldInfoColumnAdapter';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { Logger } from '@root/Services/Logger';
import { CompanyContextService, ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { ColumnSelectorOption, IColumnSelectorOption } from '@root/Components/DataGrid/ColumnSelector';
import { useEvent } from '@root/Services/EventEmitter';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { LayoutSidebarRightExpand } from 'tabler-icons-react';
import styled from '@emotion/styled';
import { useGetDefaultPageByCompany } from '../Router/Hooks';
import { FilterExpr } from '../Filter/Filters';
import { IQueryToken } from '../Filter/Services';
import { CompanyTenantPrereqService } from '../Router/CompanyContent';

export interface MyCompaniesGridProps {
    selectCompany?: (selectedCompanyId: number) => void;
    refreshNeeded?: EventEmitter<void>;
    rightTopPlaceHolder?: ReactNode;
    onCheckedCompanyChange?: (items: CompanyStat[] | undefined) => void;
}

export function CompanyStatsGrid(props: MyCompaniesGridProps) {
    const [grid, setGrid] = useState<MyCompaniesExplorerGridModel>();
    const tenantSvc = useDi(CompanyTenantPrereqService);

    const { getRootUrl, goto } = useNav();
    useEvent(
        props.refreshNeeded,
        useCallback(() => {
            grid?.refreshDataOnly();
        }, [grid])
    );

    const onSelectedCompanyChange = useCallback(
        (item: CompanyStat | undefined) => {
            if (item?.Id) {
                props.selectCompany?.(item.Id);
            }
        },
        [props.selectCompany]
    );
    const companyDefaultPageProvider = useGetDefaultPageByCompany();

    const onLoadCompany = useCallback(async (item: CompanyStat | undefined) => {
        if (!item?.Type || item?.Type === 'Customer') {
            const url = item ? await companyDefaultPageProvider(item) : null;

            if (url) {
                goto(url);
                return;
            }
        } else {
            const company = await tenantSvc.getCompanyById(item.Id ?? 0);
            if (company) {
                const url = `/${company.DatabaseName}`;
                window.open(url, '_blank');
                return;
            }
        }
        props.selectCompany?.(item?.Id!);
    }, []);

    const onCheckedCompanyChange = useCallback(
        (items: CompanyStat[] | undefined) => {
            props.onCheckedCompanyChange?.(items);
        },
        [props.onCheckedCompanyChange]
    );

    return (
        <>
            <MyCompaniesExplorerGrid
                selectionMode="single"
                onRowClick={onLoadCompany}
                rightTopPlaceHolder={props.rightTopPlaceHolder}
                showRefresh
                onSelectionChanged={onSelectedCompanyChange}
                onModelLoaded={setGrid}
                onCheckedCompanyChange={onCheckedCompanyChange}
            />
        </>
    );
}

interface MyCompaniesExplorerGridProps {
    selectionMode: string;
    showRefresh?: boolean;
    rightTopPlaceHolder?: ReactNode;
    onModelLoaded?: (model: MyCompaniesExplorerGridModel) => void;
    onRowClick?: (item: CompanyStat) => void;
    onSelectionChanged: (item: CompanyStat | undefined) => void;
    onCheckedCompanyChange: (items: CompanyStat[] | undefined) => void;
}

function MyCompaniesExplorerGrid(props: MyCompaniesExplorerGridProps) {
    const di = useDiContainer();
    const model = useMemo(() => di.resolve(MyCompaniesExplorerGridModel).init(), []);
    const loading = useEventValue(model.loading);
    const handleModelLoaded = (dataGrid: DataGridModel) => {
        model.attachGrid(dataGrid);
        props.onModelLoaded?.(model);
        model.editClicked.listen(props.onSelectionChanged);
    };

    const handleRowClick = async (item: CompanyStat) => {
        const previousCheckedItems = await model.dataGrid?.getSelectedItems();
        setTimeout(async () => {
            const checkedItems = (await model.dataGrid?.getSelectedItems()) as CompanyStat[];
            if (!checkedItems || checkedItems?.length == 0) {
                if (previousCheckedItems && previousCheckedItems?.length == 0) {
                    props.onRowClick?.(item);
                }
            }
        }, 100);
    };

    useEvent(model?.dataGrid?.selectionChanged, () => {
        setTimeout(async () => {
            const checkedItems = (await model.dataGrid?.getSelectedItems()) as CompanyStat[];
            props.onCheckedCompanyChange(checkedItems);
        }, 100);
    });

    return loading || !model.gridProps ? null : (
        <DataGrid
            {...model.gridProps}
            onRowClick={handleRowClick}
            onModelLoaded={handleModelLoaded}
            rightTopPlaceHolder={props.rightTopPlaceHolder}
            showRefresh
            exportName="My Companies"
            allowLoadAllPages
            selectionMode={'multiple'}
        />
    );
}

function BoolActiveFilter({ filter }: { filter: FilterExpr; apply: () => void }) {
    useEvent(filter.changed);

    return (
        <Radio.Group
            onChange={(e) => {
                filter.setValue(e === 'true' ? true : false);
            }}
            value={filter.value !== undefined ? (filter.value === true ? 'true' : 'false') : undefined}
        >
            <Group mt="xs">
                <Radio value="true" label="Active" />
                <Radio value="false" label="Inactive" />
            </Group>
        </Radio.Group>
    );
}

export function BoolActiveProvider(filter: FilterExpr, tokens: IQueryToken[]) {
    return tokens.map((token) => {
        if (token.type === 'constant' && typeof filter.value === 'boolean') {
            const text = filter.value ? 'Active' : 'Inactive';
            return {
                expr: token.expr,
                name: text,
                text,
                type: 'constant',
            } as IQueryToken;
        }
        return token;
    });
}

@injectable()
class MyCompaniesExplorerGridModel {
    public loading = new EventEmitter<boolean>(false);
    public schema = new SchemaService([]);
    public gridProps?: DataGridProps<CompanyStat>;
    public dataGrid?: DataGridModel;
    public editClicked = new EventEmitter<CompanyStat | undefined>(undefined);
    private mspNames: { [id: string]: string } = {};
    private companyTypes: { [id: string]: string } = {};
    private readonly columnInfo = new Map<string, { exclude?: boolean; group?: string; name?: string }>([
        ['Id', { exclude: true }],
        ['Date', { exclude: true }],
        ['MspCompanyId', { exclude: true }],
        ['CompanyName', { name: 'Company Name' }],
        ['CompanyType', { name: 'Company Type' }],
        ['TotalResources', { name: 'Total Resources' }],
        ['ClarityScore', { group: 'Tag Health Scores', name: 'Clarity Score' }],
        ['CompletenessScore', { group: 'Tag Health Scores', name: 'Completeness Score' }],
        ['ComplianceScore', { group: 'Tag Health Scores', name: 'Compliance Score' }],
        ['CoverageScore', { group: 'Tag Health Scores', name: 'Coverage Score' }],
        ['AwsResources', { group: 'AWS Environment', name: 'AWS Resources' }],
        ['AwsAnnualSpend', { group: 'AWS Environment', name: 'AWS Spend' }],
        ['AwsConnectionDate', { exclude: true, group: 'AWS Environment', name: 'Date Connected' }],
        ['AzureResources', { group: 'Azure Environment', name: 'Azure Resources' }],
        ['AzureAnnualSpend', { group: 'Azure Environment', name: 'Azure Spend' }],
        ['AzureConnectionDate', { exclude: true, group: 'Azure Environment', name: 'Date Connected' }],
        ['SubscriptionActive', { name: 'Subscription' }],
        ['SubscriptionStatus', { exclude: true }],
        ['TagHealth', { group: 'Tag Health Scores', name: 'Tag Health' }],
        ['EligibleResources', { group: 'MAP Contract', name: 'Eligible Resources' }],
        ['EligibleTagCoverage', { group: 'MAP Contract', name: 'Eligible Tag Coverage' }],
        ['NotTagged', { group: 'MAP Contract', name: 'Not Tagged' }],
        ['TaggedCorrectly', { group: 'MAP Contract', name: 'Tagged Correctly' }],
        ['TaggedIncorrectly', { group: 'MAP Contract', name: 'Tagged Incorrectly' }],
        ['IneligibleMapTags', { group: 'MAP Contract', name: 'Ineligible Tags' }],
        ['TagsInLast30Days', { name: '30 Day Tags' }],
        ['TotalSpendLast30Days', { name: '30 Day Spend' }],
        ['MostTagsUserLast30Days', { name: 'Most Active User' }],
        ['ActiveTagAutomationRules', { name: 'Active Automation Rules' }],
        ['AwsPolicyVersion', { name: 'Aws Policy Version' }],
    ]);

    public constructor(
        @inject(FieldInfoColumnAdapter) private readonly columnAdapter: FieldInfoColumnAdapter<CompanyStat>,
        @inject(NavigationService) private readonly navigationSvc: NavigationService,
        @inject(Logger) private readonly logger: Logger,
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(CompanyContextService) private readonly companyContextSvc: CompanyContextService<Company>,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public init() {
        this.loading.emit(true);
        this.load();
        return this;
    }

    private getMspCompanyName = (company: CompanyStat) => {
        const mspName = this.company.Type === CompanyType.Msp ? this.company.CompanyName : this.mspNames[company.MspCompanyId!];
        const isMspChild = company.CompanyType === CompanyStatCompanyType.MspChild;
        const isMspParent = company.CompanyType === CompanyStatCompanyType.MspParent;

        return isMspParent ? 'MSP' : isMspChild ? `MSP -  ${mspName !== undefined ? mspName : 'not assigned to support company'}` : 'Direct';
    };

    private async loadCompanies() {
        let query = queryBuilder<CompanyStat>()
            .where((c) => c.model.Id!.ne(this.companyContextSvc.parentCompany.Id!))
            .take(1000)

            .select((b) => ({
                CompanyName: b.model['CompanyName'],
                CompanyType: b.model['CompanyType'],
                MspCompanyId: b.model['MspCompanyId'],
                Id: b.model['Id'],
            }))

            .build();

        await postCompanyStatsQueryCompanyStats(query).then((results) => {
            if (results.Results) {
                results.Results.filter((m) => m.CompanyType === CompanyStatCompanyType.MspParent).forEach((item) => {
                    this.mspNames[item.Id!] = item.CompanyName!;
                });

                results.Results.forEach((item) => {
                    this.companyTypes[item.Id!] = this.getMspCompanyName(item);
                });
            } else {
                this.mspNames = {};
                this.companyTypes = {};
            }
        });
    }

    private async load() {
        try {
            const schemaResults = await postCompanyStatsQueryCompanyStats({ IncludeSchema: true, Take: 0 });
            const types = schemaResults.Types ?? [];
            this.schema = new SchemaService(types);
            await this.loadCompanies();
            this.gridProps = {
                dataSource: (query) => {
                    const where: IQueryExpr = {
                        Operation: 'and',
                        Operands: [{ Operation: 'ne', Operands: [{ field: 'Id' }, { value: this.company.Id }] }],
                    };
                    if (query.Where) {
                        where.Operands.push(query.Where);
                    }
                    query.Where = where;
                    let resolve = (_: CompanyStatQueryResult) => {};
                    const result = new Promise<CompanyStatQueryResult>((r) => (resolve = r));
                    this.companyContextSvc.withParentCompany(async () => {
                        postCompanyStatsQueryCompanyStats(query).then(resolve);
                    });
                    return result;
                },
                allowPinning: true,
                columns: this.createColumns(this.schema),
                onModelLoaded: (model) => {
                    model.modifyColumnSelectorOptions = this.modifyColumnOptions;
                },
                statePersistence: {
                    key: 'CompanyStatsGridState',
                },
                groupConfig: {
                    ['AWS Environment']: { color: '#FF9900' },
                    ['Azure Environment']: { color: '#007FFF' },
                    ['Tag Health Scores']: { color: '#71C562' },
                    ['MAP Contract']: { color: '#007FFF' },
                },
                showHeaderGroups: true,
                state: {
                    columns: this.getInitialColumns(),
                    filters: [],
                    sort: [{ Expr: { field: 'CompanyName' }, Direction: 'Asc' }],
                },
                allowSavedViews: false,
            };
        } finally {
            this.loading.emit(false);
        }
    }

    private getInitialColumns() {
        switch (this.company.Type) {
            case 'PlatformSupport':
                return [
                    { id: 'company-stats.CompanyName', width: 200, fixed: true },
                    { id: 'company-stats.CompanyType', width: 140 },
                    { id: 'company-stats.TotalResources', width: 140 },
                    { id: 'company-stats.CoverageScore', width: 130 },
                    { id: 'company-stats.TagManagerStat.TagsInLast30Days', width: 110 },
                    { id: 'company-stats.GeneralStat.TotalSpendLast30Days', width: 120 },
                    { id: 'company-stats.TagManagerStat.ActiveTagAutomationRules', width: 200 },
                    { id: 'company-stats.MapManagerStat.EligibleTagCoverage', width: 170 },
                    { id: 'company-stats.TagManagerStat.MostTagsUserLast30Days', width: 140 },
                    { id: 'company-stats.SubscriptionActive', width: 120 },
                ];
            case 'Support':
                return [
                    { id: 'company-stats.CompanyName', width: 200, fixed: true },
                    { id: 'company-stats.CompanyType', width: 140 },
                    { id: 'company-stats.AwsAnnualSpend', width: 120 },
                    { id: 'company-stats.AwsResources', width: 140 },
                    { id: 'company-stats.MapManagerStat.EligibleResources', width: 150 },
                    { id: 'company-stats.MapManagerStat.EligibleTagCoverage', width: 170 },
                    { id: 'company-stats.MapManagerStat.TaggedCorrectly', width: 140 },
                    { id: 'company-stats.MapManagerStat.TaggedIncorrectly', width: 150 },
                    { id: 'company-stats.MapManagerStat.NotTagged', width: 110 },
                    { id: 'company-stats.MapManagerStat.IneligibleMapTags', width: 130 },
                ];
            default:
                return [
                    { id: 'company-stats.CompanyName', width: 300, fixed: true },
                    { id: 'company-stats.AwsAnnualSpend', width: 120 },
                    { id: 'company-stats.AwsResources', width: 140 },
                    { id: 'company-stats.AzureAnnualSpend', width: 120 },
                    { id: 'company-stats.AzureResources', width: 140 },
                    { id: 'company-stats.Tags', width: 80 },
                    { id: 'company-stats.TagHealth', width: 100 },
                    { id: 'company-stats.CoverageScore', width: 140 },
                    { id: 'company-stats.ComplianceScore', width: 160 },
                    { id: 'company-stats.CompletenessScore', width: 160 },
                    { id: 'company-stats.ClarityScore', width: 120 },
                    { id: 'company-stats.SubscriptionActive', width: 120 },
                ];
        }
    }

    public getGrid = () => {
        return this.dataGrid;
    };

    private resolvePath = (object: any, path: string) => {
        return path.split('.').reduce((o, p) => (o ? o[p] : null), object);
    };

    private createColumns(schema: SchemaService): ColumnConfig<CompanyStat>[] {
        const rootTypes = schema.rootTypeInfo;
        const columns = this.createBaseColumns(rootTypes, []);

        const columnsPercent = ['ClarityScore', 'CompletenessScore', 'CoverageScore', 'ComplianceScore', 'MapManagerStat.EligibleTagCoverage'];

        columnsPercent.forEach((field) => {
            this.updateColumn(columns, field, {
                cellRenderer: (item: any) => (
                    <>{typeof this.resolvePath(item, field) === 'number' ? this.formatSvc.formatPercent(this.resolvePath(item, field)) : <></>}</>
                ),
            });
        });

        const columnsInt = ['AwsResources', 'AzureResources', 'Tags', 'TagHealth'];

        columnsInt.forEach((field) => {
            this.updateColumn(columns, field, {
                cellRenderer: (item: any) => <>{this.resolvePath(item, field) ? this.formatSvc.formatInt(this.resolvePath(item, field)) : 0}</>,
            });
        });

        const columnsMoney = ['AwsAnnualSpend', 'AzureAnnualSpend', 'GeneralStat.TotalSpendLast30Days'];

        columnsMoney.forEach((field) => {
            this.updateColumn(columns, field, {
                cellRenderer: (item: any) => (
                    <>{this.resolvePath(item, field) ? this.formatSvc.formatMoneyNoDecimals(this.resolvePath(item, field)) : <>$0</>}</>
                ),
            });
        });

        const columnsDate = ['AwsConnectionDate', 'AzureConnectionDate'];

        columnsDate.forEach((field) => {
            this.updateColumn(columns, field, {
                cellRenderer: (item: any) => (
                    <>
                        {this.resolvePath(item, field) ? (
                            this.formatSvc.formatDate(this.formatSvc.toLocalDate(this.resolvePath(item, field)))
                        ) : (
                            <>&mdash;</>
                        )}
                    </>
                ),
            });
        });

        this.updateColumn(columns, 'CompanyName', {
            defaultFixed: true,
            filter: {
                filterType: 'string',
                name: 'CompanyName',
                filterField: 'CompanyName',
                options: {
                    getValueProvider: () => {
                        return async () => {
                            const results = await queryBuilder<CompanyStat>()
                                .take(10000)
                                .select((b) => ({
                                    name: b.model.CompanyName,
                                    ct: b.count(),
                                }))
                                .execute(postCompanyStatsQueryCompanyStats);
                            return results?.Results?.map((r) => r.name) ?? [];
                        };
                    },
                },
            },
            cellRenderer: (item) => {
                return (
                    <GridFullCell style={{ padding: 0 }}>
                        <CompanyNameCell company={item} grid={this.getGrid} onEdit={this.editClicked.emit} />
                    </GridFullCell>
                );
            },
        });

        this.updateColumn(columns, 'SubscriptionActive', {
            filter: {
                name: 'Subscription',
                filterType: 'boolean',
                filterField: 'SubscriptionActive',
                valueRenderer: BoolActiveFilter,
                tokenProvider: BoolActiveProvider,
            },
            cellRenderer: (item) => (item.SubscriptionActive ? 'Active' : 'Inactive'),
        });

        this.updateColumn(columns, 'CompanyType', {
            filter: {
                name: 'Company Type',
                filterType: 'string',
                filterField: 'CompanyType',
                options: {
                    getValueProvider() {
                        return Object.values(CompanyStatCompanyType).map((v) => {
                            let label =
                                v === CompanyStatCompanyType.MspParent ? 'MSP' : v === CompanyStatCompanyType.MspChild ? 'MSP Child' : 'Direct';
                            return { value: v, label: label };
                        });
                    },
                },
            },
            accessor: (item) => this.companyTypes[item.Id!],
            cellRenderer: (item) => <>{this.companyTypes[item.Id!]}</>,
        });

        return columns;
    }

    public refreshDataOnly() {
        setTimeout(() => {
            this.dataGrid?.refresh();
        }, 1500);
    }

    private modifyColumnOptions = (options: { options: ColumnSelectorOption[]; selections: IColumnSelectorOption[] }) => {
        options.options.sort((a, b) => {
            const isAGroup = 'name' in a;
            const isBGroup = 'name' in b;
            const nameA = isAGroup ? a.name : a.column.header ?? '';
            const nameB = isBGroup ? b.name : b.column.header ?? '';
            return isAGroup === isBGroup ? nameA.localeCompare(nameB, undefined, { sensitivity: 'base' }) : isAGroup ? -1 : 1;
        });
    };

    private createBaseColumns(types: TypeInfo[], columns: DataColumnConfig<CompanyStat>[]) {
        for (const type of types) {
            for (const field of type.fields) {
                const columnInfo = this.columnInfo.get(field.fieldName);
                if (!columnInfo?.exclude) {
                    if (field.isPrimitive) {
                        const column = this.columnAdapter.adapt(field);
                        column.groupName = columnInfo?.group;
                        column.header = columnInfo?.name ?? column.header;
                        columns.push(column);
                    } else if (field.typeInfo) {
                        this.createBaseColumns([field.typeInfo], columns);
                    }
                }
            }
        }
        return columns;
    }

    private updateColumn(
        columns: DataColumnConfig<CompanyStat>[],
        id: string,
        changes: Partial<DataColumnConfig<CompanyStat>> | ((column: DataColumnConfig<CompanyStat>) => void)
    ) {
        id = `company-stats.${id}`;
        const column = columns.find((c) => c.id === id);
        if (column) {
            if (typeof changes === 'object') {
                Object.assign(column, changes);
            } else {
                changes(column);
            }
        } else {
            this.logger.warn(`No column found for ID ${id}`, columns);
        }
    }

    public attachGrid = (grid: DataGridModel) => {
        this.dataGrid = grid;
        //this.dataGrid.loadSchema(this.schema);
    };
}

function CompanyNameCell({
    company,
    ...props
}: {
    company: CompanyStat;
    grid: () => DataGridModel | undefined;
    onEdit: (company: CompanyStat) => void;
}) {
    const onEdit = useCallback(
        (evt: MouseEvent) => {
            evt.stopPropagation();
            props.onEdit(company);
        },
        [company, props.onEdit]
    );
    const hovered = useEventValue(props.grid()?.hovered)?.item === company;

    const link = useLink();
    return (
        <CompanyNameBox>
            <Text
                pl="xs"
                color="primary"
                sx={{
                    display: 'block',
                    flex: 1,
                    height: '100%',
                    lineHeight: '30px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                }}
            >
                {company.CompanyName ? company.CompanyName : 'No Name'}{' '}
            </Text>
            <Tooltip label="View Details" withArrow withinPortal position="bottom">
                <ActionIcon
                    radius={0}
                    sx={{ height: '30px', textAlign: 'center', opacity: hovered ? 1 : 0 }}
                    onClick={onEdit}
                    className="panel-button"
                    color="primary"
                >
                    <LayoutSidebarRightExpand size={20} />
                </ActionIcon>
            </Tooltip>
        </CompanyNameBox>
    );
}
const CompanyNameBox = styled.div`
    display: flex;
`;
