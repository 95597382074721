import { AllocationDimension, DisbursementTarget, DisbursementTargetDisbursementMethod, DisbursementTargetTargetType } from '@apis/Invoices/model';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { Query, QuerySortExpr, QuerySortExprDirection } from '@apis/Resources/model';
import {
    ActionIcon,
    Box,
    Button,
    Card,
    Checkbox,
    CloseButton,
    Divider,
    Group,
    MantineColor,
    Skeleton,
    Space,
    Stack,
    Switch,
    Sx,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { GridFullCell } from '@root/Components/DataGrid/Design';
import { DataColumnConfig, DataGridState, GridGroupByState, ISelectionStrategy } from '@root/Components/DataGrid/Models';
import { DailyInvoiceGrid } from '@root/Components/Invoices/DailyInvoiceGrid';
import { VisibleSpaces } from '@root/Components/Text/VisibleSpaces';
import { FillerSwitch } from '@root/Design/Filler';
import { InfoIconTooltip, TooltipWhite } from '@root/Design/Primitives';
import { SidePanelToolbarEl } from '@root/Design/SidePanel';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { IInvoiceRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { ShowbackPersistence } from '@root/Services/Invoices/ShowbackService';
import { ArrayDataSource } from '@root/Services/Query/ArrayDataSource';
import { queryBuilder, SchemaService } from '@root/Services/QueryExpr';
import { useMemo, useState, useCallback, useEffect, ChangeEventHandler, ReactNode, Fragment } from 'react';
import { AlertTriangle, Asterisk, Check, ExclamationMark, Filter, Plus, SortAscending2, SortDescending2, X } from 'tabler-icons-react';
import { FilterSetEditor } from '../FilterSetEditor/FilterSetEditor';
import { NamedFilterSetModel } from '../FilterSetEditor/NamedFilterSetModel';
import { useFilterSetExpr, useInvoiceDatasource } from './Components';
import { BaseAllocSettingsEditorProps, InvoiceDataSource } from './Models';
import { SwitchCard } from '@root/Design/Switches';

export interface DisbTargetEditorProps extends BaseAllocSettingsEditorProps {
    target: DisbursementTarget;
    onApply?: (target: DisbursementTarget) => void;
    onCancel: () => void;
}
export function AdvancedDisbTargetEditor({ month, target, schemaSvc, onApply, onCancel, allocationDimension }: DisbTargetEditorProps) {
    const theme = useMantineTheme();

    const unsavedUsageFilterSet = useMemo(() => ({ filter: target.UsageStatFilter ?? {} }), [target]);
    const usageFilterSetModel = useMemo(() => NamedFilterSetModel.create(unsavedUsageFilterSet, 'filter', true), [unsavedUsageFilterSet]);
    const targetFilterSet = useMemo(() => ({ filter: target.TargetExistingFilter ?? {} }), [target]);
    const targetFilterSetModel = useMemo(() => NamedFilterSetModel.create(targetFilterSet, 'filter', true), [targetFilterSet]);
    const { datasource } = useInvoiceDatasource(month);
    const [settings, setSettings] = useState<TargetSettings>({
        name: target.Name ?? '',
        targetType: target.TargetType ?? 'Existing',
        method: target.DisbursementMethod ?? 'SplitByUsage',
        dimensions: target.DimensionValues ?? [],
        blacklist: target.IsDimensionValuesExclusionList ?? false,
    });
    const handleApplyClick = useCallback(() => {
        onApply?.({
            Name: settings.name,
            TargetType: settings.targetType,
            DisbursementMethod: settings.method,
            TargetExistingFilter: targetFilterSet.filter,
            UsageStatFilter: unsavedUsageFilterSet.filter,
            DimensionValues: settings.targetType === 'NewRecords' ? settings.dimensions ?? [] : [],
            IsDimensionValuesExclusionList: settings.targetType === 'NewRecords' ? settings.blacklist : undefined,
        });
        onCancel?.();
    }, [onApply, targetFilterSet, settings]);
    const handleCancelClick = useCallback(() => onCancel?.(), [onCancel]);

    const canApply =
        (settings.targetType === 'Existing' && !targetFilterSetModel.isEmpty()) ||
        (settings.targetType === 'NewRecords' && (settings.dimensions.length || settings.blacklist));

    useEvent(usageFilterSetModel.onFilterChanged);
    useEvent(targetFilterSetModel.onFilterChanged);

    return (
        <Group noWrap sx={{ height: '100%' }} spacing={0}>
            <Stack sx={{ height: '100%', flex: '0 0 500px' }} spacing={0}>
                <Title p="lg" py="xs" order={3}>
                    Disbursement Target
                </Title>
                <Divider />
                <TargetTypeSettingsSection allocDim={allocationDimension} settings={settings} onChange={setSettings} />
                <Divider />
                <DisbursementOptionsSection
                    settings={settings}
                    onChange={setSettings}
                    existingFilter={targetFilterSetModel}
                    usageFilter={usageFilterSetModel}
                    allocDim={allocationDimension}
                    datasource={datasource}
                    schemaSvc={schemaSvc}
                    month={month}
                />
                <Divider />
                <SidePanelToolbarEl>
                    <Button disabled={!canApply} onClick={handleApplyClick}>
                        Apply
                    </Button>
                    <Button variant="outline" onClick={handleCancelClick}>
                        Cancel
                    </Button>
                </SidePanelToolbarEl>
            </Stack>
            <Divider orientation="vertical" />
            <Stack
                sx={{
                    height: '100%',
                    flex: 1,
                    overflow: 'hidden',
                    background: theme.colors.gray[2],
                    backgroundImage: 'linear-gradient(90deg, #0001, transparent 12px)',
                }}
                pb="lg"
                px="lg"
                spacing={0}
            >
                <Box sx={{ flex: 1, minWidth: 0, minHeight: 0 }}>
                    <DisbTargetInvoiceBrowser
                        {...{
                            datasource,
                            month,
                            targetFilterSetModel,
                            usageFilterSetModel,
                            settings,
                            allocationDimension,
                        }}
                    />
                </Box>
            </Stack>
        </Group>
    );
}

interface TargetSettings {
    name: string;
    targetType: DisbursementTargetTargetType;
    method: DisbursementTargetDisbursementMethod;
    dimensions: string[];
    blacklist: boolean;
}

interface IDisbTargetInvoiceBrowser {
    settings: TargetSettings;
    datasource: InvoiceDataSource;
    targetFilterSetModel: NamedFilterSetModel;
    usageFilterSetModel: NamedFilterSetModel;
    month: Date;
    allocationDimension: AllocationDimension;
}
function DisbTargetInvoiceBrowser(props: IDisbTargetInvoiceBrowser) {
    const showbackSvc = useDi(ShowbackPersistence);
    const { settings, datasource, targetFilterSetModel, usageFilterSetModel, month, allocationDimension } = props;
    const { targetType, method } = settings;
    const fmtSvc = useDi(FormatService);
    const datasourceApi = useMemo(() => ({ query: datasource }), [datasource]);
    const dateRange = useMemo(() => ({ from: month, to: month }), [month]);
    const allocDimField = showbackSvc.getSchemaQualifiedDimensionField(allocationDimension);
    const dimName = showbackSvc.getDimensionName(allocationDimension);

    const mode: 'Existing' | 'Dimensions' | 'Usage' = targetType === 'Existing' ? 'Existing' : method === 'SplitByDimension' ? 'Dimensions' : 'Usage';

    const title = mode === 'Existing' ? 'Preview Affected Line Items' : mode === 'Usage' ? 'Preview Usage Stats' : 'Browse ' + dimName;

    const dimFilterSet = useMemo(() => NamedFilterSetModel.create({ filter: {} }, 'filter'), [settings.dimensions]);
    const filterSet = mode === 'Existing' ? targetFilterSetModel : mode === 'Usage' ? usageFilterSetModel : dimFilterSet;
    const queryExpr = useFilterSetExpr(filterSet);

    const defaultGroupBy = useMemo(
        () =>
            (mode === 'Existing'
                ? [{ id: 'product.product/ProductName', sortDir: 'Desc', sortMode: 'value' }]
                : mode === 'Dimensions'
                ? [
                      { id: allocDimField, sortDir: 'Asc', sortMode: 'value' },
                      { id: 'product.product/ProductName', sortDir: 'Desc', sortMode: 'value' },
                  ]
                : [
                      { id: 'product.product/ProductName', sortDir: 'Desc', sortMode: 'value' },
                      { id: allocDimField, sortDir: 'Desc', sortMode: 'value' },
                  ]) as GridGroupByState[],
        [mode]
    );

    return (
        <DailyInvoiceGrid
            key={mode}
            persistenceKey={`AllocTargetEditorGrid-${mode}`}
            defaultGroupBy={defaultGroupBy}
            dateRange={dateRange}
            invoiceApi={datasourceApi}
            scope={queryExpr}
            filtersDisabled
            disabledSavedViews
            rightPlaceholder={
                <Box mr="lg">
                    <Title mt="xs" order={3}>
                        {title}
                    </Title>
                    <Text align="right" size="xs">
                        <Text color="dimmed" component="span">
                            Billing Period:
                        </Text>
                        {fmtSvc.formatMonth(month)}
                    </Text>
                </Box>
            }
        />
    );
}

function TargetTypeSettingsSection(props: { onChange: (settings: TargetSettings) => void; settings: TargetSettings; allocDim: AllocationDimension }) {
    const { onChange, settings, allocDim } = props;
    const theme = useMantineTheme();
    const { name, targetType, method } = settings;
    const showbackSvc = useDi(ShowbackPersistence);
    const dimName = showbackSvc.getDimensionName(allocDim);

    const toggleTargetType = useCallback(() => {
        const nextType = targetType === 'NewRecords' ? 'Existing' : 'NewRecords';
        onChange({
            ...settings,
            targetType: nextType,
            method: nextType === 'NewRecords' ? 'SplitByDimension' : 'SplitByUsage',
        });
    }, [settings]);

    const handleNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            onChange({ ...settings, name: e.currentTarget.value });
        },
        [settings]
    );

    const targetTooltip =
        targetType === 'Existing'
            ? 'Existing invoice line item costs will be adjusted by the costs transferred from allocation sources'
            : `New invoice line items will be created by splitting allocation source line items by ${dimName}`;

    return (
        <Stack px="lg" py="sm" spacing={4}>
            <TextInput label="Name" value={name} onChange={handleNameChange} />
            <Space h={4} />
            <SwitchCard onClick={toggleTargetType}>
                <Switch sx={{ input: { background: theme.colors.primary[6] } }} checked={targetType === 'Existing'} />
                <Text size="sm">Disburse {targetType === 'Existing' ? 'to Existing Line Items' : `by ${dimName}`}</Text>
                <InfoIconTooltip label={targetTooltip} />
            </SwitchCard>
        </Stack>
    );
}

interface DisbursementOptionsSectionProps {
    settings: TargetSettings;
    onChange: (settings: TargetSettings) => void;
    existingFilter: NamedFilterSetModel;
    usageFilter: NamedFilterSetModel;
    allocDim: AllocationDimension;
    datasource: InvoiceDataSource;
    schemaSvc: SchemaService;
    month: Date;
}

function DisbursementOptionsSection(props: DisbursementOptionsSectionProps) {
    const targetType = props.settings.targetType;
    return (
        <Box p="lg" sx={{ flex: 1, overflow: 'auto' }}>
            {targetType === 'Existing' ? <DisburseToExisting {...props} /> : <DisburseByDimension {...props} />}
        </Box>
    );
}

function DisburseByDimension(props: DisbursementOptionsSectionProps) {
    const theme = useMantineTheme();
    const { settings, onChange, usageFilter, allocDim, datasource, schemaSvc } = props;
    const { method, dimensions, blacklist } = settings;
    const showbackSvc = useDi(ShowbackPersistence);
    const dimName = showbackSvc.getDimensionName(allocDim);

    const handleDimensionChange = useCallback((dimensions: string[]) => onChange({ ...settings, dimensions }), [settings]);

    const handleBlacklistChange = useCallback(() => onChange({ ...settings, blacklist: !blacklist }), [settings]);

    const toggleMethod = useCallback(
        () => onChange({ ...settings, method: method === 'SplitByUsage' ? 'SplitByDimension' : 'SplitByUsage' }),
        [settings]
    );

    const methodTooltip =
        method === 'SplitByUsage'
            ? 'New line items will receive a portion of the transferred cost based on usage'
            : 'New line items will receive an equal portion of the transferred cost';

    const blacklistTooltip = blacklist
        ? `All ${dimName} values will receive disbursement except for the selected`
        : `Only the selected ${dimName} values will receive a disbursement`;

    return (
        <Stack>
            <Text>{dimName} Disbursement</Text>
            <SwitchCard onClick={handleBlacklistChange}>
                <Switch sx={{ input: { background: theme.colors.primary[6] } }} checked={!blacklist} />
                <Text size="sm">
                    {blacklist ? 'Do not disburse' : 'Disburse'} to selected {dimName} values
                </Text>
                <InfoIconTooltip label={blacklistTooltip} />
            </SwitchCard>

            <Card withBorder sx={{ height: 416, background: theme.colors.gray[2] }}>
                <AllocationDimensionValueSelector
                    allocDim={props.allocDim}
                    datasource={props.datasource}
                    method={method}
                    blacklist={blacklist}
                    onSelect={handleDimensionChange}
                    selections={dimensions}
                    month={props.month}
                    statsFilter={usageFilter}
                />
            </Card>
            <SwitchCard onClick={toggleMethod}>
                <Switch sx={{ input: { background: theme.colors.primary[6] } }} checked={method === 'SplitByDimension'} />
                <Text size="sm">Disburse {method === 'SplitByUsage' ? 'by Usage' : 'Evenly'}</Text>
                <InfoIconTooltip label={methodTooltip} />
            </SwitchCard>
            {method !== 'SplitByUsage' ? null : (
                <>
                    <Text>Usage Statistics Rules</Text>
                    <FilterSetEditor
                        {...{ datasource, schemaSvc, filterSet: usageFilter }}
                        descriptions={{
                            inclusion: {
                                helpText: '',
                            },
                            exclusion: {
                                helpText: '',
                            },
                        }}
                    />
                </>
            )}
        </Stack>
    );
}

interface AllocDimValue {
    value: string;
    metric: number;
    selected: boolean;
}
interface AllocationDimensionValueSelectorProps {
    datasource: InvoiceDataSource;
    selections: string[];
    blacklist: boolean;
    onSelect: (value: string[]) => void;
    allocDim: AllocationDimension;
    method: DisbursementTargetDisbursementMethod;
    month: Date;
    statsFilter: NamedFilterSetModel;
}

function AllocationDimensionValueSelector(props: AllocationDimensionValueSelectorProps) {
    const { allocDim, datasource, method, month, onSelect, statsFilter, blacklist } = props;
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const showbackSvc = useDi(ShowbackPersistence);
    const dimName = showbackSvc.getDimensionName(allocDim);
    const methodChanged = useMemo(() => new EventEmitter<string>(method), []);
    useEffect(() => methodChanged.emit(method), [method]);
    const blacklistChanged = useMemo(() => new EventEmitter<boolean>(blacklist), []);
    useEffect(() => blacklistChanged.emit(blacklist), [blacklist]);

    // selections
    const { selectionLookup, selectionChanged } = useMemo(() => {
        return {
            selectionLookup: new Set(props.selections),
            selectionChanged: EventEmitter.empty(),
        };
    }, []);
    const raiseOnSelect = useCallback(() => {
        onSelect([...selectionLookup]);
    }, [onSelect, selectionChanged]);
    useEvent(selectionChanged, raiseOnSelect);

    // load values
    const [loading, setLoading] = useState(true);
    const [dimensionValues, setDimensionValues] = useState<string[]>([]);
    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const loadedValues = await showbackSvc.getDimensionValueOptions(allocDim, { from: month, to: month });
                const values = new Set([...loadedValues, ...props.selections]);
                setDimensionValues([...values].sort());
            } finally {
                setLoading(false);
            }
        })();
    }, [datasource]);
    const createRecord = (value: string) =>
        ({
            value,
            metric: 0,
            get selected() {
                return selectionLookup.has(value);
            },
        } as AllocDimValue);
    const baseRecordsContainer = useMemo(
        () => ({
            baseRecords: [] as AllocDimValue[],
            recordsUpdated: EventEmitter.empty(),
            update: (values: string[]) => {
                baseRecordsContainer.baseRecords = values.map(createRecord);
                baseRecordsContainer.recordsUpdated.emit();
            },
        }),
        []
    );
    useEvent(baseRecordsContainer.recordsUpdated);
    useEffect(() => baseRecordsContainer.update(dimensionValues), [dimensionValues]);

    // usage stats
    const usageStats = useMemo(() => ({ statsUpdated: EventEmitter.empty(), stats: new Map<string, number>(), reqKey: 0 }), []);
    const loadUsageStats = useCallback(() => {
        (async () => {
            usageStats.reqKey++;
            const reqKey = usageStats.reqKey;
            const filterExpr = showbackSvc.getCriteriaForFilterSet(statsFilter.filterSet) as QueryExpr;
            const dimField = showbackSvc.getDimensionInvoiceField(allocDim);
            let qb = await queryBuilder<IInvoiceRollup & { [key: string]: string }>();
            if (filterExpr) {
                qb = qb.where((b) => b.fromExpr(filterExpr));
            }
            const results = await qb
                .select((b) => ({
                    value: b.model[dimField] as string,
                    total: b.sum(b.model['lineItem/UnblendedCost']!),
                }))
                .execute(datasource);

            if (usageStats.reqKey === reqKey) {
                usageStats.stats = (results.Results ?? []).reduce((stats, item) => stats.set(item.value, item.total), new Map<string, number>());
                usageStats.statsUpdated.emit();
            }
        })();
    }, []);
    useEvent(statsFilter.onFilterChanged, loadUsageStats);
    useEffect(loadUsageStats, []);

    // metric calculation
    const metricsCalculating = useMemo(() => new EventEmitter(false), []);
    const updateMetricsByUsage = (values: AllocDimValue[]) => {
        const total = values.reduce((total, item) => total + Math.abs(usageStats.stats.get(item.value) ?? 0), 0);
        for (const item of values) {
            item.metric = total ? Math.abs(usageStats.stats.get(item.value) ?? 0) / total : 0;
        }
    };
    const updateMetricsEvenly = (values: AllocDimValue[]) => {
        const total = values.length;
        for (const item of values) {
            item.metric = total > 0 ? 1 / total : 0;
        }
    };
    const calculateMetrics = useCallback(() => {
        metricsCalculating.emit(true);
        const selectedValues = baseRecordsContainer.baseRecords.filter((r) => selectionLookup.has(r.value) === !blacklist);
        if (method === 'SplitByUsage') {
            updateMetricsByUsage(selectedValues);
        } else {
            updateMetricsEvenly(selectedValues);
        }
        metricsCalculating.emit(false);
    }, [method, blacklist]);
    useEvent(baseRecordsContainer.recordsUpdated, calculateMetrics);
    useEvent(selectionChanged, calculateMetrics);
    useEvent(blacklistChanged, calculateMetrics);
    useEvent(usageStats.statsUpdated, calculateMetrics);
    useEffect(calculateMetrics, [method]);

    // grid config
    const [gridModel, setGridModel] = useState<DataGridModel>();
    const valueController = useMemo(() => ({ addValue: (value: string) => {} }), []);
    const gridDs = useMemo(() => {
        const result = new ArrayDataSource(baseRecordsContainer.baseRecords, [
            { field: 'selected', type: 'boolean', getValue: (r) => r.selected },
            { field: 'value', type: 'string', getValue: (r) => r.value },
            { field: 'metric', type: 'number', getValue: (r) => r.metric },
        ]);
        return result;
    }, [baseRecordsContainer.baseRecords]);
    valueController.addValue = (value: string) => {
        if (!baseRecordsContainer.baseRecords.find((r) => r.value === value)) {
            const newValue = createRecord(value);
            baseRecordsContainer.baseRecords.push(newValue);
            gridDs.items = baseRecordsContainer.baseRecords;
            selectionLookup.add(value);
            selectionChanged.emit();
            gridModel?.viewInvalidated.emit();
            if (!value.toLowerCase().includes(filterText.toLowerCase())) {
                setFilterText('');
            }
            setTimeout(() => {
                const index = gridModel?.treeModel?.getItemIndex(newValue);
                if (index !== undefined) {
                    gridModel?.treeView?.getContainer()?.current?.scrollTo({ behavior: 'smooth', top: index * 30 });
                }
            }, 500);
        }
    };
    const MetricCell = useMemo(
        () =>
            function MetricCell({ row }: { row: AllocDimValue }) {
                const blacklist = useEventValue(blacklistChanged);
                const loading = useEventValue(metricsCalculating);
                return (
                    <GridFullCell>
                        {loading ? (
                            <Skeleton width={100} height={20} />
                        ) : !selectionLookup.has(row.value) !== blacklist ? (
                            <>&mdash;</>
                        ) : (
                            fmtSvc.formatPercent(row.metric)
                        )}
                    </GridFullCell>
                );
            },
        []
    );
    const PortionHeader = useMemo(
        () =>
            function () {
                const method = useEventValue(methodChanged);
                return method === 'SplitByUsage' ? (
                    <TooltipWhite
                        withinPortal
                        multiline
                        label={
                            <Box sx={{ width: 300 }}>
                                For usage-based disbursement, the portion displayed is an estimate based on data available for the billing period
                            </Box>
                        }
                    >
                        <Group>
                            <AlertTriangle size={16} stroke={theme.colors.warning[5]} />
                            Portion
                        </Group>
                    </TooltipWhite>
                ) : (
                    <Box mr="md">Portion</Box>
                );
            },
        []
    );
    const columns = useMemo(
        () =>
            [
                {
                    accessor: 'selected',
                    defaultHidden: true,
                    id: 'selected',
                    defaultWidth: 0,
                    type: 'boolean',
                },
                {
                    accessor: 'value',
                    header: dimName,
                    id: 'value',
                    noRemove: true,
                    defaultWidth: 250,
                    type: 'string',
                    cellRenderer: (r) => <VisibleSpaces value={r.value} />,
                    footerRenderer: () => <AddAssetIdCell {...{ valueController, dimName }} allItems={baseRecordsContainer} />,
                },
                {
                    accessor: 'metric',
                    header: 'Portion',
                    id: 'metric',
                    defaultWidth: 120,
                    type: 'number',
                    noRemove: true,
                    align: 'right',
                    headerRenderer: () => <PortionHeader />,
                    cellRenderer: (r) => <MetricCell row={r} />,
                },
            ] as DataColumnConfig<AllocDimValue>[],
        []
    );
    const handleGridSelectionChanged = useCallback(
        async ({ getItems }: { getItems: () => Promise<AllocDimValue[]> }) => {
            const items = await getItems();
            selectionLookup.clear();
            items.reduce((lookup, item) => lookup.add(item.value), selectionLookup);
            selectionChanged.emit();
        },
        [selectionChanged, selectionLookup]
    );
    const [filterText, setFilterText] = useState('');
    const clearFilter = useCallback(() => setFilterText(''), []);
    const getFilterExpr = (filterText: string) => {
        return filterText ? [{ Operation: 'contains', Operands: [{ Field: 'value' }, { Value: filterText }] }] : [];
    };
    const selectionStrat = useMemo(() => new SetBasedSelectionStrategy(selectionLookup, baseRecordsContainer), []);
    const handleGridStateChange = useCallback((state: DataGridState) => {
        setGridState({ ...state });
    }, []);
    const [gridState, setGridState] = useState<DataGridState>({
        columns: [
            { id: 'value', width: 250 },
            { id: 'metric', width: 120 },
        ],
        sort: [{ Expr: { Field: 'value' }, Direction: 'Asc' }],
        filters: [],
    });
    const getGridSort = (sortExprs: undefined | QuerySortExpr[]) => {
        const sort = (sortExprs ?? [])[0];
        const field = sort?.Expr && 'Field' in sort.Expr ? sort.Expr.Field : null;
        const dir = sort?.Direction;
        return { field, dir };
    };
    const sortedBy = useMemo(() => getGridSort(gridState?.sort), [JSON.stringify(gridState?.sort)]);
    const sortedByField = sortedBy.field === 'selected';
    const SelectionSortIcon = sortedByField && sortedBy.dir === 'Asc' ? SortDescending2 : SortAscending2;
    const selectionSortColor: MantineColor = sortedBy.field === 'selected' ? 'primary.6' : 'gray.9';
    const sortSelectionTooltip = sortedByField && sortedBy.dir === 'Asc' ? 'Sort selections to bottom' : 'Sort selections to top';
    useEffect(() => {
        if (gridModel) {
            gridModel.applyFilters(getFilterExpr(filterText));
        }
        if (selectionStrat) {
            selectionStrat.filterText = filterText;
        }
    }, [filterText]);
    const sortBySelectedState = useCallback(() => {
        if (gridModel) {
            const sortExprs = gridModel.gridState.sort;
            const { field, dir } = getGridSort(sortExprs);
            const nextSortDir = field === 'selected' && dir === 'Desc' ? 'Asc' : 'Desc';
            const secondarySort = field !== 'selected' ? sortExprs : [];
            gridModel.sort([{ Expr: { Field: 'selected' }, Direction: nextSortDir }, ...secondarySort]);
        }
    }, [gridModel]);

    const selectedCt = blacklist ? baseRecordsContainer.baseRecords.length - selectionLookup.size : selectionLookup.size;

    return (
        <FillerSwitch loading={loading}>
            {() => (
                <>
                    <Group pb="xs">
                        <TooltipWhite label={sortSelectionTooltip} position="left" withinPortal>
                            <ActionIcon
                                sx={{ opacity: sortedByField ? 1 : 0.6, ['&:hover']: { opacity: 1 } }}
                                onClick={sortBySelectedState}
                                color={selectionSortColor as MantineColor}
                            >
                                <SelectionSortIcon size={16} />
                            </ActionIcon>
                        </TooltipWhite>
                        <TextInput
                            icon={<Filter size={16} />}
                            size="xs"
                            radius="lg"
                            sx={{ width: 220 }}
                            value={filterText}
                            onChange={(e) => setFilterText(e.currentTarget.value)}
                            placeholder={`Filter ${dimName}`}
                            rightSection={filterText ? <CloseButton variant="transparent" onClick={clearFilter} /> : null}
                        />
                        <Text align="right" color="dimmed" size="sm" sx={{ flex: 1 }}>
                            {fmtSvc.formatInt(selectedCt)} / {fmtSvc.formatInt(baseRecordsContainer.baseRecords.length)}
                        </Text>
                    </Group>
                    <Box sx={{ height: 340, width: 425 }}>
                        <DataGrid
                            dataSource={gridDs}
                            columns={columns}
                            onModelLoaded={setGridModel}
                            minimumLoadingMs={0}
                            selectionMode="multiple"
                            onRowClick="select"
                            hideHeader
                            hideColumnSelector
                            hideFilter
                            renderFooter
                            renderRowSelector={(item, _, isHeader) => (
                                <AllocDimRowSelector {...{ item, isHeader, selectionChanged, selectionStrat }} />
                            )}
                            rowStyle={(item, index, hover) => ({ background: hover ? theme.colors.primary[2] : 'transparent' })}
                            selectionStrategy={selectionStrat}
                            state={gridState}
                            onStateChanged={handleGridStateChange}
                            onSelectedChanged={handleGridSelectionChanged}
                        />
                    </Box>
                </>
            )}
        </FillerSwitch>
    );
}

function AddAssetIdCell({
    allItems,
    valueController,
    dimName,
}: {
    allItems: { baseRecords: AllocDimValue[] };
    valueController: { addValue: (value: string) => void };
    dimName: string;
}) {
    const theme = useMantineTheme();
    const [value, setValue] = useState('');
    const [warnings, setWarnings] = useState<ReactNode[]>([]);
    const [exists, setExists] = useState(false);
    const handleValueChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            const value = e.currentTarget.value;
            setValue(value);
        },
        [setValue]
    );

    useEffect(() => {
        const warnings: ReactNode[] = [];
        let foundDuplicate = false;
        const lcValue = value.toLowerCase();
        for (const item of allItems.baseRecords) {
            if (item.value === value) {
                foundDuplicate = true;
            } else if (item.value.toLowerCase() === lcValue) {
                warnings.push(
                    <Text>
                        {dimName} exists with different capitalization: "
                        <strong>
                            <VisibleSpaces value={item.value} />
                        </strong>
                        "
                    </Text>
                );
            }
        }
        setExists(foundDuplicate);
        if (value.trimStart() !== value) {
            warnings.push(
                <Text>
                    {dimName} "<VisibleSpaces value={value} />" starts with a space
                </Text>
            );
        }
        if (value.trimEnd() !== value) {
            warnings.push(
                <Text>
                    {dimName} "<VisibleSpaces value={value} />" ends with a space
                </Text>
            );
        }
        setWarnings(warnings);
    }, [value]);

    const handleAddClick = useCallback(() => {
        if (!exists && !!value) {
            valueController.addValue(value);
            setValue('');
        }
    }, [value, exists]);

    const tooltip = exists ? (
        <Text>
            "
            <strong>
                <VisibleSpaces value={value} />
            </strong>
            " is already in the {dimName} list
        </Text>
    ) : warnings.length ? (
        warnings.map((w, i) => <Fragment key={i}>{w}</Fragment>)
    ) : (
        <Text>
            Add "
            <strong>
                <VisibleSpaces value={value} />
            </strong>
            " to {dimName} list
        </Text>
    );
    const tooltipDisabled = !value;
    const buttonColor: MantineColor = exists ? 'error.5' : warnings.length ? 'warning.5' : !value ? 'gray.4' : 'primary.6';
    const Icon = exists ? AlertTriangle : warnings.length ? ExclamationMark : Plus;

    return (
        <GridFullCell style={{ padding: 0, borderWidth: '0 1px', borderColor: theme.colors.gray[4], borderStyle: 'solid' }}>
            <TextInput
                value={value}
                onChange={handleValueChange}
                placeholder={`Add ${dimName}...`}
                sx={{ ['input']: { minHeight: 30, height: 30, border: 'none' } }}
                rightSection={
                    <TooltipWhite label={tooltip} disabled={tooltipDisabled} position="right" withinPortal>
                        <ActionIcon
                            disabled={!value}
                            sx={{ cursor: exists ? 'not-allowed' : undefined }}
                            size={24}
                            radius="sm"
                            variant="filled"
                            onClick={handleAddClick}
                            color={buttonColor as MantineColor}
                        >
                            <Icon size={20} />
                        </ActionIcon>
                    </TooltipWhite>
                }
            />
        </GridFullCell>
    );
}

function AllocDimRowSelector({
    item,
    isHeader,
    selectionStrat,
    selectionChanged,
}: {
    item: AllocDimValue | null;
    isHeader: boolean;
    selectionStrat: SetBasedSelectionStrategy;
    selectionChanged: EventEmitter<void>;
}) {
    const selected = (isHeader && selectionStrat.allSelected()) || (item && selectionStrat.isSelected(item));
    const setSelected = useCallback(() => {
        const nextValue = !selected;
        if (item) {
            selectionStrat.setSelected(item, nextValue);
        } else if (isHeader) {
            selectionStrat.internalSetSelectAll(nextValue);
        }
        selectionChanged.emit();
    }, [item, isHeader, selected]);
    const handleCheckChange = useCallback(() => setSelected(), [setSelected]);
    useEvent(selectionChanged);

    return (
        <Checkbox size="xs" checked={!!selected} onChange={handleCheckChange} indeterminate={isHeader && !selected && selectionStrat.count() > 0} />
    );
}

class SetBasedSelectionStrategy implements ISelectionStrategy<AllocDimValue> {
    constructor(private readonly selectionLookup: Set<string>, private allItems: { baseRecords: AllocDimValue[] }) {}
    public filterText = '';
    public isSelected(item: AllocDimValue): boolean {
        return this.selectionLookup.has(item.value);
    }
    public getSelectAllValidity(): string | undefined {
        return '';
    }
    public setSelected(item: AllocDimValue, selected: boolean): void {
        if (selected) {
            this.selectionLookup.add(item.value);
        } else {
            this.selectionLookup.delete(item.value);
        }
    }
    public async setSelectAll(selected: boolean): Promise<void> {
        /* ignore grid select all events */
    }
    public internalSetSelectAll(selected: boolean) {
        this.setAllSelected(selected);
    }
    private setAllSelected(selected: boolean) {
        const lcFilter = this.filterText.toLowerCase();
        for (const item of this.allItems.baseRecords) {
            if (!lcFilter || item.value.toLowerCase().includes(lcFilter)) {
                if (selected) {
                    this.selectionLookup.add(item.value);
                } else {
                    this.selectionLookup.delete(item.value);
                }
            }
        }
    }
    public allSelected() {
        return this.allItems.baseRecords.length === this.selectionLookup.size;
    }
    public async getSelected(): Promise<AllocDimValue[]> {
        return this.allItems.baseRecords.filter((r) => this.selectionLookup.has(r.value));
    }
    public count(): number {
        return this.selectionLookup.size;
    }
}

function DisburseToExisting(props: DisbursementOptionsSectionProps) {
    const { existingFilter, datasource, schemaSvc } = props;

    return (
        <Stack>
            <Text>Line Item Disbursement Rules</Text>
            <FilterSetEditor
                {...{ datasource, schemaSvc, filterSet: existingFilter }}
                descriptions={{
                    inclusion: {
                        helpText: (
                            <>
                                Invoice line items which meet all criteria of any of these rules <strong>will </strong>
                            </>
                        ),
                    },
                    exclusion: {
                        helpText: (
                            <>
                                Invoice line items which meet all criteria of any of these rules <strong>will not receive</strong> cost adjustments
                                <br />
                                Exclusion rules will override inclusion rules.
                            </>
                        ),
                    },
                }}
            />
        </Stack>
    );
}
