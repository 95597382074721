import { Center, Loader } from '@mantine/core';
import { BarChartSettings } from '@root/Components/Charts/BarChart';
import { GaugeChartSettings } from '@root/Components/Charts/GaugeChart';
import { LineChartSettings } from '@root/Components/Charts/LineChart';
import { PieChartSettings } from '@root/Components/Charts/PieChart';
import { ChartDashboardItem } from '@root/Components/DashboardLayout/ChartDashboardItem';
import { CustomizableDashboard, DashboardAddOption } from '@root/Components/DashboardLayout/CustomizableDashboard';
import { IDashboardConfig, IDashboardItemType, QueryDatasource } from '@root/Components/DashboardLayout/Models';
import { PageBody, PageContent } from '@root/Design/Layout';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { DailyInvoiceSchemaProvider, InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { addDays, endOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { inject, injectable } from 'tsyringe';
import { InvoiceDateRange } from '../../Components/Invoices/InvoiceDateRange';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { withSchemaPreloader } from '@root/Components/Invoices/SchemaPreloader';
import { IInvoiceFieldCompatibilityLookup, InvoiceFieldCompatibilityService } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';
import { DateOnlyQueryApiWrapper } from '@root/Services/Query/DateOnlyQueryApiWrapper';

export function InvoiceViewer({ dashboard, model }: { dashboard?: string; model: InvoiceViewerModel }) {
    const invoiceSchemaSvc = useDi(DailyInvoiceSchemaProvider);
    const invoiceApi = useDi(InvoiceApiService);
    const dateonlyApiWrapper = useDi(DateOnlyQueryApiWrapper);
    const nav = useNav();

    useEffect(() => {
        model.init();
    }, []);

    //#region Dashboard Config
    const datasource = useMemo(() => {
        if (!model.fieldCompat) return [];
        const svcCategory = model.fieldCompat.getAvailableField('ServiceCategory');
        const [date, cost] = model.fieldCompat.getAvailableFields('ChargePeriodStart', 'BilledCost');
        const wrappedApi = dateonlyApiWrapper.wrap((q) => invoiceApi.query(q, model.dateRange.value));

        return [
            {
                name: 'cur',
                source: wrappedApi,
                schema: invoiceSchemaSvc,
                getValueProviderFactory: (schemaSvc) => {
                    const schemaValueProvider = new SchemaValueProvider(schemaSvc, (q) => invoiceApi.query(q, model.dateRange.value));
                    return {
                        getValueProvider: schemaValueProvider.getValueProvider,
                    };
                },
                getDefaultGroup: () => ({ Expr: { Field: svcCategory?.path }, Alias: svcCategory?.name }),
                getDefaultValue: () => ({ Expr: { Operation: 'sum', Operands: [{ Field: cost }] }, Alias: 'Cost' }),
                getDefaultHistogram: () => ({
                    Expr: { Field: date },
                    Alias: 'Date',
                }),
            },
        ] as QueryDatasource[];
    }, [model.fieldCompat]);

    const itemTypes = useMemo(() => {
        return [ChartDashboardItem.itemType] as IDashboardItemType[];
    }, []);

    const defaultConfig = useMemo((): IDashboardConfig | null => {
        if (!model.fieldCompat) return null;
        const [date, cost, region, svcCategory] = model.fieldCompat.getAvailableFields(
            'ChargePeriodStart',
            'BilledCost',
            'RegionId',
            'ServiceCategory'
        );

        return {
            name: 'Invoice Dashboard',
            layout: [
                {
                    data: {
                        type: 'chart',
                        chartType: 'kpi',
                        values: [
                            { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } },
                            { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } },
                        ],
                        groups: [],
                        settings: {
                            valueFilters: [
                                [
                                    {
                                        Operation: 'startsWith',
                                        Operands: [{ Field: region }, { Value: ['us'] }],
                                    },
                                ],
                                [],
                            ],
                            labels: ['US Regions', 'All Regions'],
                            format: ['money-whole', 'money-whole'],
                        },
                        datasourceName: 'cur',
                        title: 'Region Spend',
                    },
                    h: 3,
                    w: 6,
                    x: 0,
                    y: 0,
                },
                {
                    data: {
                        type: 'chart',
                        chartType: 'line',
                        groups: [{ Alias: 'Date', Expr: { Field: date } }],
                        values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } }],
                        settings: {
                            format: 'money',
                            interval: 'week',
                            margin: { top: 30, bottom: 70, left: 70, right: 20 },
                        } as LineChartSettings,
                        datasourceName: 'cur',
                        title: 'Weekly Spend',
                    },
                    h: 6,
                    w: 6,
                    x: 0,
                    y: 3,
                },
                {
                    data: {
                        type: 'chart',
                        chartType: 'grid',
                        groups: [],
                        values: [],
                        settings: {
                            state: {
                                columns: [
                                    { id: 'Category', width: 200 },
                                    { id: 'Spend', width: 100 },
                                ],
                                filters: [],
                                sort: [{ id: 'Spend' }],
                            },
                            columns: [
                                { type: 'string', id: 'Category', select: { Alias: 'Category', Expr: { Field: svcCategory } } },
                                {
                                    type: 'number',
                                    id: 'Spend',
                                    select: { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } },
                                },
                            ],
                        },
                        datasourceName: 'cur',
                        title: 'Spend by Product',
                    },
                    h: 6,
                    w: 5,
                    x: 6,
                    y: 0,
                },
            ],
        };
    }, [model.fieldCompat]);

    const addOptions = useMemo(() => {
        if (!model.fieldCompat) return [];

        const [date, cost, svcCategory, acctName] = model.fieldCompat.getAvailableFields(
            'ChargePeriodStart',
            'BilledCost',
            'ServiceCategory',
            'SubAccountName'
        );

        return [
            {
                label: 'New KPI',
                category: 'blank',
                settings: {
                    type: 'chart',
                    chartType: 'kpi',
                    groups: [],
                    values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } }],
                    settings: { valueFilters: [[]], labels: [''], format: ['money-whole'] },
                    datasourceName: 'cur',
                    title: 'Spend',
                },
            },
            {
                label: 'New Table',
                category: 'blank',
                settings: {
                    type: 'chart',
                    chartType: 'grid',
                    groups: [],
                    values: [],
                    settings: {
                        state: {
                            columns: [
                                { id: 'Account', width: 200 },
                                { id: 'Spend', width: 100 },
                            ],
                            filters: [],
                            sort: [],
                        },
                        columns: [
                            { type: 'string', id: 'Account', select: { Alias: 'Account', Expr: { Field: acctName } } },
                            {
                                type: 'number',
                                id: 'Spend',
                                select: { Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } },
                            },
                        ],
                    },
                    datasourceName: 'cur',
                    title: 'Spend by Account',
                },
            },
            {
                label: 'New Gauge',
                category: 'blank',
                settings: {
                    type: 'chart',
                    chartType: 'gauge',
                    groups: [{ Alias: 'Account', Expr: { Field: acctName } }],
                    values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } }],
                    settings: { format: 'money', angle: 'large', margin: { top: 30, bottom: 30, left: 20, right: 20 } } as GaugeChartSettings,
                    datasourceName: 'cur',
                    title: 'Spend by Account',
                },
            },
            {
                label: 'New Pie',
                category: 'blank',
                settings: {
                    type: 'chart',
                    chartType: 'pie',
                    groups: [{ Alias: 'Category', Expr: { Field: svcCategory } }],
                    values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } }],
                    settings: {
                        valueFormat: 'money',
                        margin: { top: 30, bottom: 30, left: 20, right: 20 },
                        threshold: 3,
                        topN: 10,
                    } as PieChartSettings,
                    datasourceName: 'cur',
                    title: 'Spend by Cateory',
                },
            },
            {
                label: 'New Bar',
                category: 'blank',
                settings: {
                    type: 'chart',
                    chartType: 'bar',
                    groups: [{ Alias: 'Category', Expr: { Field: svcCategory } }],
                    values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } }],
                    settings: { format: 'money', margin: { top: 30, bottom: 70, left: 70, right: 20 } } as BarChartSettings,
                    datasourceName: 'cur',
                    title: 'Spend by Category',
                },
            },
            {
                label: 'New Line',
                category: 'blank',
                settings: {
                    type: 'chart',
                    chartType: 'line',
                    groups: [{ Alias: 'Date', Expr: { Field: date } }],
                    values: [{ Alias: 'Spend', Expr: { Operation: 'sum', Operands: [{ Field: cost }] } }],
                    settings: { format: 'money', interval: 'week', margin: { top: 30, bottom: 70, left: 70, right: 20 } } as LineChartSettings,
                    datasourceName: 'cur',
                    title: 'Spend over Time',
                },
            },
        ] as DashboardAddOption[];
    }, [model.fieldCompat]);

    const loadDashboard = useCallback((id?: number) => {
        nav.mergeParams({ dashboardId: id?.toString() ?? '' });
    }, []);
    //#endregion

    const loading = useEventValue(model.loading);
    useEvent(model.dateRange);

    return (
        <ConnectionCheck>
            {() => (
                <PageBody>
                    <PageContent>
                        {loading || !defaultConfig ? (
                            <Center sx={{ height: 400 }}>
                                <Loader />
                            </Center>
                        ) : (
                            <>
                                <CustomizableDashboard
                                    addOptions={addOptions}
                                    dashboardKey="InvoiceExplorerDashboard"
                                    datasources={datasource}
                                    defaultConfig={defaultConfig}
                                    itemTypes={itemTypes}
                                    allowAdd
                                    allowFilter
                                    allowLoader
                                    implicitFilter={model.getDateFilter()}
                                    id={(dashboard && parseInt(dashboard)) || undefined}
                                    onIdChanged={loadDashboard}
                                    toolRightPlaceholder={
                                        <InvoiceDateRange
                                            constraint={model.constraint}
                                            onChange={model.navigateToDateRange}
                                            value={model.dateRange.value}
                                        />
                                    }
                                />
                            </>
                        )}
                    </PageContent>
                </PageBody>
            )}
        </ConnectionCheck>
    );
}

function InvoiceViewerPage() {
    const nav = useNav();
    const container = useDiContainer();
    const model = useMemo(() => container.resolve(InvoiceViewerModel), []);
    const { dashboardId, range } = nav.getData('dashboardId', 'range');

    useEffect(() => {
        model.updateDateRange(range ?? '');
    }, [range]);

    return <ConnectionCheck>{() => <InvoiceViewer model={model} dashboard={dashboardId} />}</ConnectionCheck>;
}

@injectable()
class InvoiceViewerModel {
    public dateRange = new EventEmitter<{ from?: Date; to?: Date }>({});
    public constraint: { min?: Date; max?: Date } = {};
    public loading = new EventEmitter<boolean>(false);
    public fieldCompat?: IInvoiceFieldCompatibilityLookup;

    public constructor(
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService,
        @inject(FormatService) private readonly fmtSvc: FormatService,
        @inject(NavigationService) private readonly navSvc: NavigationService,
        @inject(InvoiceFieldCompatibilityService) private readonly fieldLookupFactory: InvoiceFieldCompatibilityService,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService
    ) {}

    public async init() {
        try {
            this.loading.emit(true);
            const [range, schema] = await Promise.all([this.invoiceApi.getDateRange(), this.invoiceSchemaSvc.getDailySchema()]);
            const max = range.to ? endOfMonth(range.to) : new Date();
            const schemaSvc = new SchemaService(schema);
            this.fieldCompat = await this.fieldLookupFactory.getLookup(schemaSvc, { useLegacyFallbacks: true });
            this.constraint = { min: range.from, max };
            if (!this.dateRange.value.from && !this.dateRange.value.to && range.to) {
                const to = range.to;
                this.dateRange.emit({ from: addDays(to, -30), to: to });
            }
        } finally {
            this.loading.emit(false);
        }
    }

    public updateDateRange(range: string) {
        const [rawFrom, rawTo] = range.split('-');
        const from = rawFrom ? this.fmtSvc.from8DigitDate(rawFrom) : undefined;
        const to = rawTo ? this.fmtSvc.from8DigitDate(rawTo) : undefined;
        this.dateRange.emit({ from, to });
    }

    public navigateToDateRange = (range: { from?: Date; to?: Date }) => {
        const from = range.from ? this.fmtSvc.to8DigitDate(range.from) : '';
        const to = range.to ? this.fmtSvc.to8DigitDate(range.to) : '';
        this.navSvc.mergeParams({ range: `${from}-${to}` });
    };

    public getDateFilter() {
        const { from, to } = this.dateRange.value;
        const filterParts = [
            { value: from, op: 'gte' },
            { value: to, op: 'lte' },
        ];
        const filters = filterParts
            .filter((f) => f.value)
            .map((f) => ({
                Operation: f.op,
                Operands: [{ Field: 'ChargePeriodStart' }, { Value: this.fmtSvc.toJsonShortDate(this.fmtSvc.toLocalDate(f.value!)) }],
            }));

        return filters.length ? filters : undefined;
    }
}

endpoint('invoice-viewer', withSchemaPreloader(InvoiceViewerPage), 'Invoice Viewer');
