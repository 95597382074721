import { IQueryExpr, Query, QuerySortExpr } from '@apis/Invoices/model';
import { QueryConstant, QueryExpr, QueryOperation, QueryResult } from '@apis/Resources';
import { Center, Loader, useMantineTheme, Text, Tooltip, ThemeIcon } from '@mantine/core';
import { IncreaseIndicator } from '@root/Design/Data';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { ArrayDataSource } from '@root/Services/Query/ArrayDataSource';
import {
    exprBuilder,
    FieldInfo,
    naiveJsonCopy,
    SchemaService,
    SchemaValueProvider,
    traverseExpr,
    traverseExprDepthFirst,
    TypeInfo,
    ValuesGroupOtherText,
} from '@root/Services/QueryExpr';
import { format } from 'date-fns';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FileSpreadsheet, Tilde } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { IDataSource, ColumnConfig, DataGridState, GridGroupByState, GridColumnState, ISelectionStrategy } from '../DataGrid/Models';
import { IValueProviderFactory } from '../Filter/Filters';
import { FieldPicker } from '../Picker/FieldPicker';
import { ActivityPanelModel } from '../Actions/ActivityPanel/Models';
import { ExportRequest, ExportRequestTarget, ExportRequestType } from '@apis/Export/model';
import { postExportExport } from '@apis/Export';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import type { Company } from '@apis/Customers/model';
import { IInvoiceFieldCompatibilityLookup, InvoiceFieldCompatibilityService } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';

export function MonthlyInvoicesGrid({
    persistenceKey,
    months,
    invoiceApi,
    includeDifference,
    includeTotal,
    defaultGroupBy,
    filters,
    onSelectionChanged,
    onFilterChanged,
    selectionStrategy,
    renderRowSelector,
    rightPlaceholder,
    costField,
}: {
    persistenceKey: string;
    months: Date[];
    invoiceApi: InvoiceApiService;
    includeDifference?: boolean;
    includeTotal?: boolean;
    defaultGroupBy?: GridGroupByState[];
    filters?: QueryExpr[];
    onFilterChanged?: (filters: QueryExpr[]) => void;
    onSelectionChanged?: (selectionState: { getItems: () => Promise<MonthlyInvoiceRow[]> }) => void;
    selectionStrategy?: ISelectionStrategy<MonthlyInvoiceRow>;
    renderRowSelector?: (
        item: MonthlyInvoiceRow | null,
        selectionState: { selected?: boolean; some?: boolean; all?: boolean; toggle: (selected: boolean) => void },
        isHeader: boolean
    ) => ReactNode;
    rightPlaceholder?: ReactNode;
    costField: string;
}) {
    const theme = useMantineTheme();
    const container = useDiContainer();
    const model = useMemo(() => {
        return container
            .resolve(MonthlyInvoicesGridModel)
            .setMonths(months)
            .setFilters(filters ?? [])
            .setCostField(costField)
            .init(invoiceApi, !!includeDifference, !!includeTotal, persistenceKey);
    }, []);
    useEffect(() => {
        model
            .setMonths(months)
            .setFilters(filters ?? [])
            .setCostField(costField)
            .setOnFilterChanged(onFilterChanged)
            .setDefaultGroupBy(defaultGroupBy)
            .setSelectionEnabled(!!onSelectionChanged);
    }, [JSON.stringify([months, filters]), !onSelectionChanged, !onFilterChanged, costField, JSON.stringify(defaultGroupBy)]);
    const initializing = useEventValue(model.initializing);
    const renderKey = useEventValue(model.renderKey);
    const selectionProps = onSelectionChanged ? { selectionMode: 'multiple' as 'multiple', onSelectedChanged: onSelectionChanged! } : {};
    const groupByFieldPicker = useMemo(
        () => (select: (selection: { id: string }) => void) => <GroupByFieldPicker model={model} select={select} />,
        [model]
    );
    return initializing ? (
        <Center>
            <Loader />
        </Center>
    ) : (
        <DataGrid
            key={renderKey}
            childAccessor={model.childAccessor}
            columns={model.columns}
            dataSource={model.datasource}
            statePersistence={persistenceKey ? { key: persistenceKey } : undefined}
            onStateReverting={model.handleStateReverting}
            onStateLoaded={model.handleStateLoaded}
            allowSavedViews
            onModelLoaded={model.attach}
            onRowClick={model.onGridRowClick}
            {...selectionProps}
            selectionStrategy={selectionStrategy}
            renderRowSelector={renderRowSelector}
            schemaSvc={model.schema}
            allowGroupBy
            groupByAsRows
            groupByLabels={{ count: 'Cost' }}
            groupByNameLookup={model.getGroupByName}
            groupByFieldPicker={groupByFieldPicker}
            groupByDisableSort
            groupByRequired={1}
            defaultGroupBy={defaultGroupBy}
            filterValueProvider={model.filterValueProvider}
            groupConfig={includeDifference ? { ['Approximate Variance']: { color: theme.colors.primary[2] } } : undefined}
            showHeaderGroups={includeDifference}
            allowNonColumnFilters
            hideGlobalSearch
            indentLeafNodes
            renderFooter
            footerPosition="top"
            rightToolPlaceHolder={rightPlaceholder}
            export={model.export}
            hideMenu={false}
            hideColumnSelector={true}
        />
    );
}

type MonthlyInvoiceRowMonth = Record<`month${number}`, number>;
export type MonthlyInvoiceRow = {
    parent?: MonthlyInvoiceRow;
    value: string;
    type: string;
    depth: number;
    resourceId?: string;
    differenceTotal?: number;
    differencePercent?: number;
    children?: MonthlyInvoiceRow[];
    nullValue: boolean;
    total: number;
} & MonthlyInvoiceRowMonth;

@injectable()
export class MonthlyInvoicesGridModel {
    private _datasource?: IDataSource<MonthlyInvoiceRow>;
    private _columns?: ColumnConfig<MonthlyInvoiceRow>[];
    private _defaultState?: DataGridState;
    private _defaultGroupBy?: GridGroupByState[];
    private onFilterChanged?: (filters: QueryExpr[]) => void;
    private months: Date[] = [];
    private filters: QueryExpr[] = [];
    private invoiceApi!: InvoiceApiService;
    private gridModel?: DataGridModel;
    private sortDatasource?: (state: DataGridState) => void;
    private invalidateDatasource?: () => void;
    private selectionEnabled = false;
    private totalRowCache = { rootData: [] as MonthlyInvoiceRow[], totalRow: undefined as undefined | { [key in keyof MonthlyInvoiceRow]: number } };
    private costField?: string;
    private fieldCompat?: IInvoiceFieldCompatibilityLookup;

    public rootDataUpdated = new EventEmitter<MonthlyInvoiceRow[]>([]);
    public totalRow?: { [key in keyof MonthlyInvoiceRow]: number };
    public initializing = new EventEmitter(true);
    public schema?: SchemaService;
    public filterValueProvider?: IValueProviderFactory;
    public renderKey = new EventEmitter(0);
    public get datasource(): IDataSource<MonthlyInvoiceRow> {
        if (!this._datasource) {
            this._datasource = this.createDatasource();
        }
        return this._datasource;
    }
    public get columns(): ColumnConfig<MonthlyInvoiceRow>[] {
        if (!this._columns) {
            this._columns = this.createColumns();
        }
        return this._columns;
    }
    public get defaultState(): DataGridState {
        if (!this._defaultState) {
            this._defaultState = this.createDefaultState();
        }
        return this._defaultState;
    }
    public get grid() {
        return this.gridModel;
    }
    public includeDifference: boolean = false;
    public includeTotal: boolean = false;
    public gridName: string = '';
    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(ActivityPanelModel) private readonly activityPanelModel: ActivityPanelModel,
        @inject(NotificationService) private readonly notificationSvc: NotificationService,
        @inject(InvoiceFieldCompatibilityService) private readonly fieldCompatSvc: InvoiceFieldCompatibilityService
    ) {}

    public init(invoiceApi: InvoiceApiService, includeDifference: boolean, includeTotal: boolean, gridName: string) {
        this.invoiceApi = invoiceApi;
        this.includeDifference = includeDifference;
        this.includeTotal = includeTotal;
        this.gridName = gridName;
        this.initialize();
        return this;
    }

    public async initialize() {
        try {
            this.initializing.emit(true);
            const types = await this.invoiceSchemaSvc.getMonthlySchema();
            this.schema = new SchemaService(types);
            this.filterValueProvider = new SchemaValueProvider(this.schema, (q) => {
                if (this.filters?.length) {
                    q.Where = { Operation: 'and', Operands: [...this.filters, ...(q.Where ? [q.Where] : [])] };
                }
                return this.invoiceApi.queryMonthlyRollup(q, this.months);
            });
            this.schema.resolveChildren();
            this.fieldCompat = await this.fieldCompatSvc.getLookup(this.schema);
        } finally {
            this.initializing.emit(false);
        }
    }

    public setCostField(costField: string) {
        this.costField = costField;
        return this;
    }

    public setFilters(filters: QueryExpr[]) {
        if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
            this.filters = filters;
        }
        return this.invalidate();
    }

    public setDefaultGroupBy(groupBy: GridGroupByState[] | undefined) {
        if (JSON.stringify(groupBy) !== JSON.stringify(this._defaultGroupBy)) {
            this._defaultGroupBy = groupBy;
        }
        return this;
    }

    public setOnFilterChanged(handler: ((filters: QueryExpr[]) => void) | undefined) {
        if (handler !== this.onFilterChanged) {
            this.onFilterChanged = handler;
        }
        return this;
    }

    public getMonths() {
        return this.months;
    }

    public setMonths(months: Date[]) {
        if (JSON.stringify(months) !== JSON.stringify(this.months)) {
            this.months = months;
            this.updateColumns();
        }
        return this.invalidate();
    }

    public setSelectionEnabled(enabled: boolean) {
        if (enabled !== this.selectionEnabled) {
            this.selectionEnabled = enabled;
        }
        return this.invalidate();
    }

    public getTotalRow() {
        const rootData = this.rootDataUpdated.value;
        if (rootData !== this.totalRowCache.rootData) {
            const keys = [
                ...Array(this.getMonths().length)
                    .fill(0)
                    .map((_, i) => `month${i}` as keyof MonthlyInvoiceRow),
                'total',
                'differenceTotal',
            ] as (keyof MonthlyInvoiceRow)[];

            const result = {
                depth: 0,
                total: 0,
                type: 'total',
                value: 'Total',
            } as unknown as { [key in keyof MonthlyInvoiceRow]: number };

            for (const key of keys) {
                result[key] = 0;
            }

            rootData?.forEach((row) => {
                for (const key of keys) {
                    result[key] = (result[key] ?? 0) + ((row[key] as number) ?? 0);
                }
            });

            if (result.differenceTotal) {
                result.differencePercent = result.differenceTotal / (Math.abs(result.month0) || 1);
            }

            this.totalRowCache.rootData = rootData;
            this.totalRowCache.totalRow = result;
        }

        return this.totalRowCache.totalRow!;
    }

    private invalidate() {
        this._datasource = undefined;
        this._columns = undefined;
        this._defaultState = undefined;
        this.renderKey.emit(this.renderKey.value + 1);
        return this;
    }

    public attach = (gridModel: DataGridModel) => {
        this.gridModel = gridModel;
        gridModel.gridStateChanged.listen((change) => {
            if (change?.changes.has('filters')) {
                this.onFilterChanged?.((change.state.filters ?? []).slice() as QueryExpr[]);
            }
            if (change?.changes.has('sort') && change.changes.size === 1) {
                this.sortDatasource?.(change.state);
            } else {
                this.invalidateDatasource?.();
            }
        });
    };

    public export = async () => {
        const openActivity = this.activityPanelModel.toggleRequested.emit;
        try {
            const request = this.createExportRequest();
            if (request) {
                await postExportExport(request);
                this.notificationSvc.notify(
                    'Export Invoice Details',
                    `Export Requested. Click to check the activity log for download.`,
                    'primary',
                    <ThemeIcon variant="light" size="xl" radius="xl">
                        <FileSpreadsheet />
                    </ThemeIcon>,
                    openActivity
                );
            }
        } catch {
            this.notificationSvc.notify(
                'Export Failed',
                `Error: Export failed. Click to check the activity log for details.`,
                'error',
                null,
                openActivity
            );
        }
    };

    private createExportRequest(): ExportRequest | undefined {
        const name = this.gridName == 'InvoiceComparison' ? 'Invoice Comparison' : 'Trend Analysis';
        const extFilters: IQueryExpr[] = this.filters?.slice() ?? [];
        const stateOverride = naiveJsonCopy(this.gridModel?.gridState);

        this.fieldCompat?.adjustQueryFields([...(stateOverride?.filters ?? []), ...extFilters]);

        const config = this.gridModel?.getDashboardConfigForExport(name, name, stateOverride);
        if (config) {
            const fieldInfo = this.fieldCompat?.getDetailsById(...(stateOverride?.groupBy?.map((g) => g.id) ?? []));

            return {
                CompanyId: this.company.Id,
                Target: this.gridName == 'InvoiceComparison' ? ExportRequestTarget.InvoiceComparisonQuery : ExportRequestTarget.TrendAnalysisQuery,
                Type: ExportRequestType.Excel,
                DashboardConfig: config,
                TargetParameters: {
                    From: this.formatSvc.toJsonShortDate(this.months[0]),
                    To: this.formatSvc.toJsonShortDate(this.months[this.months.length - 1]),
                    CostField: this.costField,
                    FieldInfo: fieldInfo,
                },
            };
        }
    }

    public onGridRowClick = (row: MonthlyInvoiceRow) => {
        if (this.selectionEnabled) {
            this.gridModel?.setSelected(row, !this.gridModel?.isSelected(row));
        } else {
            this.gridModel?.treeModel?.toggle(row);
        }
    };

    public getGroupByName = (id: string) => {
        return this.schema?.getFieldWithId(id)?.name ?? this.schema?.getField(id)?.name ?? this.schema?.fieldLookup.get(id)?.name ?? id;
    };

    public childAccessor = {
        hasChildren: (row: MonthlyInvoiceRow) => row.depth < (this.gridModel?.gridState.groupBy?.length ?? 0) - 1,
    };

    public handleStateReverting = () => {
        if (this.gridModel) {
            return {
                filters: [],
                columns: this.createColumnState(),
                sort: [{ Expr: { Field: 'value' }, Direction: 'Asc' }],
                groupBy: this._defaultGroupBy,
            } as DataGridState;
        }
    };

    public handleStateLoaded = () => {
        this.mergeStateColumns();
        this.invalidateDatasource?.();
        this.onFilterChanged?.((this.gridModel?.gridState.filters ?? []).slice() as QueryExpr[]);
    };

    private updateColumns() {
        this.mergeStateColumns();
        if (this.gridModel) {
            this.gridModel?.applyColumnSelection(this.createColumns(), new Set<string>(['value']), false);
        }
    }

    private mergeStateColumns() {
        const state = this.gridModel?.gridState;
        if (state) {
            state.columns = this.createColumnState(state);
        }
    }

    private createColumnState(state?: DataGridState) {
        const stateLookup = new Map<string, GridColumnState>(state?.columns.map((c) => [c.id, c]) ?? []);
        const defaultColumns = this.createColumns();
        return defaultColumns.map((c) => stateLookup.get(c.id) ?? { id: c.id, width: c.defaultWidth, fixed: c.defaultFixed });
    }

    private createDefaultState() {
        return {
            filters: [],
            columns: [],
            sort: [{ Expr: { Field: 'value' }, Direction: 'Asc' }],
        } as DataGridState;
    }

    private createColumns() {
        const result: ColumnConfig<MonthlyInvoiceRow>[] = [
            {
                accessor: 'value',
                defaultWidth: 350,
                id: 'value',
                header: 'Detail',
                type: 'string',
                defaultFixed: true,
                footerRenderer: () => {
                    return <>Total</>;
                },
            },
        ];
        for (let i = 0; i < this.months.length; i++) {
            const month = this.months[i];
            result.push({
                accessor: `month${i}`,
                defaultWidth: 120,
                id: `month${i}`,
                header: format(month, 'LLL yyyy'),
                type: 'number',
                align: 'right',
                formatter: (v) => this.formatSvc.formatMoneyNoDecimals(v[`month${i}`]),
                footerRenderer: () => {
                    return <TotalRowCell model={this} totalKey={`month${i}`} />;
                },
            });
        }
        if (this.includeDifference) {
            result.push({
                accessor: 'differenceTotal',
                defaultWidth: 160,
                id: 'differenceTotal',
                header: 'Dollars',
                type: 'number',
                align: 'right',
                groupName: 'Approximate Variance',
                formatter: (v) => this.formatSvc.formatMoneyNoDecimals(v.differenceTotal ?? 0),
                cellRenderer: (item: MonthlyInvoiceRow) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                            {this.formatSvc.formatMoneyNoDecimals(item.differenceTotal ?? 0)}
                            <IncreaseIndicator value={item.differenceTotal ?? 0} preferDecrease size="sm" />
                        </div>
                    );
                },
                footerRenderer: () => {
                    return (
                        <TotalRowCell
                            model={this}
                            renderer={(data) => (
                                <Tooltip
                                    withinPortal
                                    position="bottom"
                                    label={
                                        <>
                                            This is an approximation, a more accurate <br />
                                            calculation is available in the right panel.
                                        </>
                                    }
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                                        <Tilde size={16} />
                                        {this.formatSvc.formatMoneyNoDecimals(data.differenceTotal ?? 0)}
                                        <IncreaseIndicator value={data.differenceTotal ?? 0} preferDecrease size="sm" />
                                    </div>
                                </Tooltip>
                            )}
                        />
                    );
                },
            });
            result.push({
                accessor: 'differencePercent',
                defaultWidth: 160,
                id: 'differencePercent',
                header: 'Percent',
                type: 'number',
                align: 'right',
                groupName: 'Approximate Variance',
                cellRenderer: (item: MonthlyInvoiceRow) => {
                    const clamped = Math.min(100, Math.max(-10, item.differencePercent ?? 0));
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                            {(clamped != item.differencePercent ? '>' : '') + this.formatSvc.formatPercent(clamped)}
                            <IncreaseIndicator value={item.differencePercent ?? 0} preferDecrease size="sm" />
                        </div>
                    );
                },
                footerRenderer: () => {
                    return (
                        <TotalRowCell
                            model={this}
                            renderer={(data) => (
                                <Tooltip
                                    withinPortal
                                    position="bottom"
                                    label={
                                        <>
                                            This is an approximation, a more accurate <br />
                                            calculation is available in the right panel.
                                        </>
                                    }
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                                        <Tilde size={16} />
                                        {this.formatSvc.formatPercent(data.differencePercent ?? 0)}
                                        <IncreaseIndicator value={data.differencePercent ?? 0} preferDecrease size="sm" />
                                    </div>
                                </Tooltip>
                            )}
                        />
                    );
                },
            });
        }
        if (this.includeTotal) {
            result.push({
                accessor: 'total',
                defaultWidth: 160,
                id: 'total',
                header: 'Total',
                type: 'number',
                align: 'right',
                formatter: (v) => this.formatSvc.formatMoneyNoDecimals(v.total ?? 0),
                footerRenderer: () => {
                    return <TotalRowCell model={this} totalKey="total" />;
                },
            });
        }
        return result.map((c) => ({ ...c, noRemove: true, noReorder: true }));
    }

    private createDatasource() {
        const root = { value: 'root', type: 'root' } as MonthlyInvoiceRow;
        this.sortDatasource = (state: DataGridState) => {
            const sort = state.sort?.length ? state.sort : [{ Expr: { Field: 'value' }, Direction: 'Asc' } as QuerySortExpr];
            this.visitRows(root, (row) => {
                if (row.children?.length) {
                    row.children.splice(0, Infinity, ...new ArrayDataSource(row.children).applyState({ filters: [], sort }));
                    this.gridModel?.treeModel?.invalidateItem(row);
                }
            });
            this.gridModel?.treeModel?.clearChildrenLoaded?.();
            this.gridModel?.refresh(true);
        };
        this.invalidateDatasource = () => {
            root.children = undefined;
        };
        return {
            getPage: async (start, end, state, parent) => {
                const parents = this.getParents(parent);
                parent = parent ?? root;
                if (!parent?.children) {
                    parent.children = await this.getRows(state, parents, parent === root ? undefined : parent);
                }
                if (parent === root) {
                    this.rootDataUpdated.emit(parent.children);
                }
                return { items: parent.children.slice(start, end), total: parent.children.length };
            },
        } as IDataSource<MonthlyInvoiceRow>;
    }

    private visitRows(row: MonthlyInvoiceRow, visitor: (row: MonthlyInvoiceRow) => void) {
        visitor(row);
        if (row.children) {
            for (const child of row.children) {
                this.visitRows(child, visitor);
            }
        }
    }

    private async getRows(state: DataGridState, parents: MonthlyInvoiceRow[], parent: MonthlyInvoiceRow | undefined) {
        const filters = state.filters?.slice() ?? [];
        if (parents.length) {
            filters.push(
                ...parents.map((p) =>
                    p.nullValue
                        ? { Operation: 'isNull', Operands: [{ Field: p.type }] }
                        : { Operation: 'eq', Operands: [{ Field: p.type }, { Value: p.value }] }
                )
            );
        }
        filters.push({ Operation: 'eq', Operands: [{ Field: `UsageMonth` }, { Value: this.months.map((m) => this.formatSvc.formatYearMonth(m)) }] });
        filters.push(...this.filters);
        const groupById = state.groupBy?.[parents.length]?.id;
        const groupByFieldInfo = this.schema?.getFieldWithId(groupById ?? '') ?? this.schema?.getField(groupById ?? '');
        const groupByField = groupByFieldInfo?.fieldName ?? '';

        const query: Query = {
            Select: [
                {
                    Alias: 'value',
                    Expr: { Operation: 'values', Operands: [{ Field: groupByField }, { Value: '' }, { Value: ValuesGroupOtherText }] },
                },
            ],
            Where: { Operation: 'and', Operands: filters },
        };
        for (let i = 0; i < this.months.length; i++) {
            const month = this.months[i];
            query.Select!.push({
                Alias: `month${i}`,
                Expr: exprBuilder<{ UsageMonth: string } & { [key: string]: number }>()
                    .createFluentExpr((b) => b.aggIf(b.model.UsageMonth.eq(this.formatSvc.formatYearMonth(month)), b.sum(b.model[this.costField!])))
                    .resolve(),
            });
        }
        if (this.includeTotal) {
            query.Select!.push({
                Alias: `total`,
                Expr: exprBuilder<{ [key: string]: number }>()
                    .createFluentExpr((b) => b.sum(b.model[this.costField!]))
                    .resolve(),
            });
        }
        const results = (await this.invoiceApi.queryMonthlyRollup(query, this.months)) as QueryResult<{ value: string } & MonthlyInvoiceRowMonth>;
        const items = (results.Results ?? []).map((r) => {
            for (let i = 0; i < this.months.length; i++) {
                r[`month${i}`] ??= 0;
            }
            const differenceTotal = this.includeDifference ? r[`month1`] - r[`month0`] : undefined;
            return {
                ...r,
                parent,
                value: r.value === ValuesGroupOtherText ? 'Other' : r.value,
                type: groupByField,
                depth: parents.length,
                nullValue: r.value === ValuesGroupOtherText,
                differenceTotal,
                differencePercent: !this.includeDifference
                    ? undefined
                    : !r['month0'] && !r['month1']
                    ? 0
                    : !r['month1']
                    ? -1
                    : differenceTotal! / r[`month0`],
            } as MonthlyInvoiceRow;
        });

        const datasource = new ArrayDataSource(items, [
            { field: 'value', type: 'string', getValue: (item: MonthlyInvoiceRow) => (item.nullValue ? '\uffff' : item.value) },
        ]);
        return datasource.applyState({ filters: [], sort: state.sort });
    }

    private getParents(parent: MonthlyInvoiceRow | undefined) {
        const parents: MonthlyInvoiceRow[] = [];
        while (parent) {
            parents.push(parent);
            parent = parent.parent;
        }
        return parents;
    }
}

function GroupByFieldPicker({ model, select }: { model: MonthlyInvoicesGridModel; select: (selection: { id: string }) => void }) {
    const fieldFilter = useCallback((fieldInfo: FieldInfo) => {
        return fieldInfo.typeName === 'string' && fieldInfo.name !== 'UsageMonth' && fieldInfo.name !== 'Resource Id';
    }, []);
    const schemaFilter = useCallback((item: FieldInfo | TypeInfo) => {
        return 'fields' in item ? item.fields.some(fieldFilter) : fieldFilter(item);
    }, []);
    const handleChange = useCallback(
        (field: FieldInfo[]) => {
            if (field.length) {
                select({ id: field[0].pathWithRoot });
            }
        },
        [select]
    );
    return model.schema ? (
        <FieldPicker mode="single" onChange={handleChange} schema={model.schema} selections={[]} minimizeHeight schemaFilter={schemaFilter} />
    ) : null;
}

type RowKey = { type: string; value: string; nullValue: boolean };
export function getFilterFromSelection(selection: MonthlyInvoiceRow[]) {
    const filters: QueryExpr[] = [];
    const getParents = (parent: MonthlyInvoiceRow | undefined) => {
        const path: RowKey[] = [];
        while ((parent = parent?.parent)) {
            path.unshift({ type: parent.type, value: parent.value, nullValue: parent.nullValue });
        }
        return path;
    };
    const getExpr = ({ type, value, nullValue }: RowKey) => {
        return nullValue
            ? { Operation: 'isNull', Operands: [{ Field: type }] }
            : { Operation: 'eq', Operands: [{ Field: type }, { Value: [value] }] };
    };
    const getExprs = (parents: RowKey[], current: MonthlyInvoiceRow) => {
        const result: QueryExpr[] = [];
        for (const parent of parents) {
            result.push(getExpr(parent));
        }
        result.push({ Operation: 'or', Operands: [getExpr(current)] });
        return result;
    };

    const mergeExpr = (root: QueryExpr, item: RowKey) => {
        traverseExpr(root, (expr) => {
            if ('Operation' in expr && expr.Operation === 'or') {
                const orItems = expr.Operands;
                if (item.nullValue) {
                    if (!orItems.some((o) => 'Operation' in o && o.Operation === 'isNull')) {
                        orItems.push(getExpr(item));
                    }
                } else {
                    const valuesList = orItems
                        .flatMap((o) => ('Operation' in o && o.Operation === 'eq' ? (o.Operands as QueryExpr[]) : []))
                        .find((o) => 'Value' in o && o.Value instanceof Array);
                    if (valuesList) {
                        (valuesList as QueryConstant).Value.push(item.value);
                    } else {
                        orItems.push(getExpr(item));
                    }
                }
                return true;
            }
            return false;
        });
    };

    const simplifyExpr = (root: QueryExpr) => {
        traverseExprDepthFirst(root, (expr: QueryExpr, parents: QueryOperation[]) => {
            if ('Operation' in expr) {
                if ((expr.Operation === 'and' || expr.Operation === 'or') && expr.Operands.length === 1) {
                    const parent = parents[parents.length - 1];
                    if (parent) {
                        parent.Operands.splice(parent.Operands.indexOf(expr), 1, ...expr.Operands);
                    }
                }
            }
        });
    };

    const queryLookup = new Map<string, QueryExpr>();

    for (const item of selection) {
        const parents = getParents(item);
        const parentKey = JSON.stringify(parents);
        let query = queryLookup.get(parentKey);
        if (!query) {
            queryLookup.set(parentKey, (query = { Operation: 'and', Operands: getExprs(parents, item) }));
            filters.push(query);
        } else {
            mergeExpr(query, item);
        }
    }

    const result = { Operation: 'or', Operands: filters };
    simplifyExpr(result);

    return result.Operands;
}

function TotalRowCell({
    model,
    totalKey,
    renderer,
}: {
    model: MonthlyInvoicesGridModel;
    totalKey?: keyof MonthlyInvoiceRow;
    renderer?: (value: { [key in keyof MonthlyInvoiceRow]: number }) => ReactNode;
}) {
    const fmtSvc = useDi(FormatService);
    useEvent(model.rootDataUpdated);
    const data = model.getTotalRow();

    return <>{renderer ? renderer(data) : totalKey ? fmtSvc.formatMoneyNoDecimals(data[totalKey] ?? 0) : <Text align="center">&mdash;</Text>}</>;
}
