import { getUserGetCompanyUsers } from '@apis/Customers';
import { User } from '@apis/Customers/model';
import { CostForecastRequest, CostForecastRequestedJobStatus } from '@apis/Invoices/model';
import { JobOfCostForecast, QueryExpr } from '@apis/Resources';
import { useThrottledEffect } from '@react-hookz/web';
import {
    Box,
    Button,
    Card,
    Center,
    CloseButton,
    Divider,
    Drawer,
    Grid,
    Group,
    List,
    Loader,
    LoadingOverlay,
    MultiSelect,
    NumberInput,
    Select,
    Space,
    Stack,
    Text,
    TextInput,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { DataFilterModel, DataFilters } from '@root/Components/Filter/Filters';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { PanelToolbar } from '@root/Design/Layout';
import { AnchorButton } from '@root/Design/Primitives';
import { CustomColors } from '@root/Design/Themes';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDi } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import {
    CostForecastFieldService,
    CostForecastFieldServiceFactory,
    CostForecastPersistence,
    SavedForecast,
} from '@root/Services/Invoices/CostForecastService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { useNav } from '@root/Services/NavigationService';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { addDays, startOfDay, eachMonthOfInterval, differenceInCalendarDays } from 'date-fns';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { AlertTriangle, Plus } from 'tabler-icons-react';
import { AccountValueItem, AccountPickerItem } from '../Components/AccountPicker';
import { SpendForecastModel } from './Models';
import { postForecastEstimateCardinality } from '@apis/Invoices';
import { ForecastCard, ForecastCardTitle } from './Components';

export function ForecastDetails({
    ranges,
    closeSidePanel,
    job,
    savedForecast,
    onCreate,
    onRename,
}: {
    ranges: { accountName: string; accountId: string; from: Date; to: Date }[];
    closeSidePanel: () => void;
    job?: JobOfCostForecast;
    savedForecast?: SavedForecast;
    onCreate?: (request: CostForecastRequest) => Promise<void>;
    onRename?: (name: string) => Promise<void>;
}) {
    const model = job?.Parameters;
    const readonly = !!model;
    const userId = useDi(AuthenticationService)?.user?.Id;
    const isOwner = userId === job?.RequestedById;
    const [users, setUsers] = useState<User[]>();
    const forecastSvc = useDi(CostForecastPersistence);
    const fldSvcFactory = useDi(CostForecastFieldServiceFactory);
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const invoiceSchemaSvc = useDi(InvoiceSchemaService);
    const invoiceApi = useDi(InvoiceApiService);
    const [accounts, setAccounts] = useState<string[]>(model?.Accounts ? model.Accounts : ranges.map((r) => r.accountId));
    const dateFmt = (date: Date) => fmtSvc.toJsonShortDate(date);
    const [sHFrom, setSHFrom] = useState<Date>(
        model?.SpendHistoryStartDate
            ? fmtSvc.parseDateNoTime(model.SpendHistoryStartDate)
            : addDays(ranges.reduce((a, b) => (a.from < b.from ? a : b), { from: startOfDay(new Date()) }).from, 1)
    );
    const [sHTo, setSHTo] = useState<Date>(
        model?.SpendHistoryEndDate ? startOfDay(fmtSvc.toLocalDate(model.SpendHistoryEndDate)) : startOfDay(addDays(new Date(), -1))
    );
    const [fPTo, setFPTo] = useState<string>(
        model ? fmtSvc.toShortDate(addDays(fmtSvc.parseDateNoTime(model.SpendHistoryEndDate ?? ''), model.DaysToForecast ?? 1)) : ''
    );
    const [historicalDays, setHistoricalDays] = useState<number>(0);
    const [forecastDays, setForecastDays] = useState<number>(model?.DaysToForecast ?? 0);
    const [forecastMaxDays, setForecastMaxDays] = useState<number>(0);
    const [validationIssues, setValidationIssues] = useState<ValidationIssues>({ total: 0 });
    const [filters, setFilters] = useState<QueryExpr[]>((model?.Filter ?? []) as QueryExpr[]);
    const [groupBy, setGroupBy] = useState<string[]>();
    const [createButtonEnabled, setCreateButtonEnabled] = useState(false);
    const notificationSvc = useDi(NotificationService);
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const [schema, setSchema] = useState<SchemaService>();
    const [schemaValueProvider, setSchemaValueProvider] = useState<SchemaValueProvider>();
    const [fieldInfoProvider, setFieldInfoProvider] = useState<SchemaFieldNameProvider>();
    const [groupValues, setGroupValues] = useState<{ label: string; value: string }[]>([]);
    const [name, setName] = useState<string>(savedForecast?.name ?? '');
    const [renaming, setRenaming] = useState(false);
    const [fieldSvc, setFieldSvc] = useState<CostForecastFieldService>();
    const description = model && fieldSvc ? forecastSvc.createDescription(model, fieldSvc) : null;
    const owner = users?.find((u) => u.Id === job?.RequestedById);
    const [cardinality, setCardinality] = useState<number>();
    const [sampleSizeRecords, setSampleSizeRecords] = useState<number>(0);
    const [cardinalityCheckStatus, setCardinalityCheckStatus] = useState<'timedout' | 'failed' | 'loading' | 'none'>();
    const [eta, setEta] = useState<number>();
    const cardReqKey = useMemo(() => ({ lastRequest: 1 }), []);

    useEffect(() => {
        (async () => {
            const types = await invoiceSchemaSvc.getMonthlySchema();
            const schema = new SchemaService(types);
            const schemaValueProvider = new SchemaValueProvider(schema, (q) => invoiceApi.queryMonthlyRollup(q, []));

            const fieldInfoProvider = new SchemaFieldNameProvider(schema);
            setSchema(schema);
            setSchemaValueProvider(schemaValueProvider);
            setFieldInfoProvider(fieldInfoProvider);

            const fieldSvc = await fldSvcFactory.getService();
            setFieldSvc(fieldSvc);

            setGroupValues(fieldSvc.getFieldOptions());

            if (model) {
                setSHFrom(fmtSvc.toLocalDate(model.SpendHistoryStartDate!));
                setSHTo(fmtSvc.toLocalDate(model.SpendHistoryEndDate!));
                setAccounts(forecastSvc.getAccounts(model));
                setUsers(await getUserGetCompanyUsers());
            }
        })().finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        setCreateButtonEnabled(!!(accounts?.length && sHFrom && sHTo && fPTo && validationIssues.total === 0));
    }, [accounts, sHFrom, sHTo, fPTo, validationIssues]);

    const accountOptions = useMemo(
        () => ranges.map((a) => ({ value: a.accountId, label: fmtSvc.awsAccountId(a.accountId), accountName: a.accountName })),
        [ranges]
    );
    const availableMonths = useMemo(() => {
        if (!ranges.length || !accounts.length) {
            return [];
        }
        const currentDate = new Date();
        const earliestDate = addDays(
            ranges.filter((r) => accounts.includes(r.accountId)).reduce((a, b) => (a.from < b.from ? a : b), { from: currentDate }).from,
            1
        );
        const earliestDateValue = dateFmt(earliestDate);
        const months = eachMonthOfInterval({ start: earliestDate, end: earliestDate >= currentDate ? earliestDate : currentDate });
        return [
            { value: earliestDateValue, label: fmtSvc.toShortDate(earliestDate) },
            ...months
                .filter((m) => differenceInCalendarDays(currentDate, m) >= 11 && m > earliestDate)
                .map((m) => ({ value: dateFmt(m), label: fmtSvc.formatShortMonthYear(m) })),
        ];
    }, [ranges, accounts]);

    useEffect(() => {
        if (!readonly) {
            if (!sHFrom || !sHTo) {
                setForecastMaxDays(0);
                setForecastDays(0);
            }
            const earliestDate = sHFrom;
            const latestDate = new Date(sHTo);
            const historicalDays = differenceInCalendarDays(latestDate, earliestDate);
            setHistoricalDays(historicalDays);
            const maxDays = Math.floor(historicalDays / 3);
            setForecastMaxDays(maxDays);
            setForecastDays(forecastDays > 0 ? Math.min(forecastDays, maxDays) : maxDays);
        }
    }, [sHFrom, sHTo]);

    useEffect(() => {
        if (!readonly) {
            setFPTo(fmtSvc.toShortDate(addDays(sHTo, forecastDays)));
        }
    }, [sHFrom, forecastDays]);

    const onFilterChange = useCallback(
        (filters: QueryExpr[]) => {
            setFilters(filters.slice());
            if (filterModel) {
                const validationIssues = filterModel.getValidationIssues();
                setValidationIssues(
                    validationIssues.reduce(
                        (result, { issue }) => {
                            result[issue] = result && result[issue] ? (result[issue] ?? 0) + 1 : 1;
                            result.total++;
                            return result;
                        },
                        { total: 0 } as ValidationIssues
                    )
                );
            }
        },
        [filterModel]
    );

    const createForecastRequest = () =>
        ({
            SpendHistoryStartDate: fmtSvc.toJsonShortDate(sHFrom),
            SpendHistoryEndDate: fmtSvc.toJsonShortDate(sHTo),
            DaysToForecast: forecastDays,
            Filter: filters,
            Groups: groupBy,
            Accounts: accounts,
        } as CostForecastRequest);

    const createForecast = async () => {
        setCreating(true);
        const forecastRequest = createForecastRequest();

        await onCreate?.(forecastRequest);

        closeSidePanel();
        setCreating(false);

        const icon = <i className="ti ti-circle-check"></i>;
        notificationSvc.notify('New Forecast Request Created', `Forecast Requested. Check the activity log for request status.`, 'primary', icon);
        setCreating(false);
    };

    const renameClick = async () => {
        if (name) {
            setRenaming(true);
            await onRename?.(name);
            setRenaming(false);
        }
    };

    useThrottledEffect(
        () => {
            if (!readonly && !validationIssues.total) {
                (async () => {
                    cardReqKey.lastRequest++;
                    const key = cardReqKey.lastRequest;
                    setCardinality(undefined);
                    setSampleSizeRecords(0);
                    setCardinalityCheckStatus('loading');
                    setEta(undefined);
                    const request = createForecastRequest();
                    try {
                        const result = await postForecastEstimateCardinality(request);
                        if (key === cardReqKey.lastRequest) {
                            setCardinality(result.Cardinality ?? -1);
                            setSampleSizeRecords(result.Records ?? 0);
                            setCardinalityCheckStatus(result.TimedOut ? 'timedout' : result.Failed ? 'failed' : !result.Records ? 'none' : undefined);
                        }
                    } catch (error) {
                        setCardinality(-1);
                    }
                })();
            }
        },
        [filters, groupBy, accounts, validationIssues, sHFrom],
        500
    );

    const effectiveCardinality = (cardinality ?? -1) < 0 ? 1000000 : (cardinality as number);
    const cardinalityRange =
        cardinalityCheckStatus === 'none' || cardinalityCheckStatus === 'timedout' || cardinalityCheckStatus === 'failed'
            ? 'invalid'
            : effectiveCardinality > 150000
            ? 'high'
            : effectiveCardinality > 50000
            ? 'warning'
            : 'success';
    const cardinalityColor =
        cardinalityRange === 'high' ? theme.colors.error[6] : cardinalityRange === 'warning' ? theme.colors.warning[5] : theme.colors.success[6];
    const { label: etaLabel, minutes: etaMinutes } = forecastSvc.getEta(historicalDays, effectiveCardinality);

    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <Box p="lg" sx={{ flex: 1, overflow: 'auto' }}>
                <Stack>
                    {readonly ? (
                        <ForecastCard>
                            <Grid columns={3} align="flex-end">
                                <Grid.Col span={2}>
                                    <ForecastCardTitle title="Forecast Name & Details" readonly readonlyDescription="" description="" />
                                    <Group align="flex-end" spacing={6}>
                                        <TextInput
                                            label="Forecast Name"
                                            value={name}
                                            onChange={(v) => setName(v.target.value)}
                                            sx={{ flex: '1', ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 } }}
                                            disabled={!isOwner}
                                        />
                                        {isOwner ? (
                                            <Button
                                                leftIcon={!renaming ? null : <Loader color={'white' as CustomColors} size="xs" />}
                                                disabled={!name || renaming}
                                                onClick={renameClick}
                                            >
                                                Rename
                                            </Button>
                                        ) : null}
                                    </Group>
                                </Grid.Col>
                                <Grid.Col span={1}>
                                    {owner ? (
                                        <>
                                            <Text color="dimmed" size="sm">
                                                Owner
                                            </Text>
                                            <Text size="sm">{owner.EMail}</Text>
                                            <Space h="xs" />
                                        </>
                                    ) : null}
                                    <Text color="dimmed" size="sm">
                                        Created At
                                    </Text>
                                    <Text size="sm">{fmtSvc.formatDatetimeNoSecs(fmtSvc.toLocalDate(job.QueuedAt))}</Text>
                                </Grid.Col>
                            </Grid>
                        </ForecastCard>
                    ) : null}
                    <ForecastCard>
                        <ForecastCardTitle
                            title="Accounts"
                            readonlyDescription="The AWS payer accounts for which the forecast was generated."
                            readonly={readonly}
                            description="Specify the AWS payer accounts for which the forecast will be generated."
                        />
                        <Grid columns={3}>
                            <Grid.Col span={2}>
                                {readonly ? (
                                    <List>
                                        {accountOptions
                                            .filter((o) => accounts.includes(o.value))
                                            .map((o) => (
                                                <List.Item key={o.value}>{`${o.label} - ${o.accountName}`}</List.Item>
                                            ))}
                                    </List>
                                ) : (
                                    <MultiSelect
                                        withinPortal
                                        data={accountOptions}
                                        value={accounts}
                                        onChange={setAccounts}
                                        valueComponent={AccountValueItem}
                                        itemComponent={AccountPickerItem}
                                        clearable
                                        disabled={readonly}
                                    />
                                )}
                            </Grid.Col>
                        </Grid>
                    </ForecastCard>
                    <ForecastCard>
                        <ForecastCardTitle
                            title="Historical Spend Analysis"
                            readonly={readonly}
                            readonlyDescription="The historical spending data analyzed for generating the forecast."
                            description="Indicate the amount of historical spending data to be used. A longer history can improve the forecast's accuracy by identifying spending patterns and trends."
                        />
                        <Grid columns={3}>
                            <Grid.Col span={1}>
                                {readonly ? (
                                    <TextInput
                                        label="From"
                                        sx={{ ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 } }}
                                        value={fmtSvc.formatShortMonthYear(fmtSvc.parseDateNoTime(model.SpendHistoryStartDate ?? ''))}
                                        disabled
                                    />
                                ) : (
                                    <Select
                                        withinPortal
                                        label="From"
                                        data={availableMonths}
                                        value={dateFmt(sHFrom)}
                                        onChange={(v) => (v ? setSHFrom(fmtSvc.parseDateNoTime(v)) : null)}
                                        disabled={readonly}
                                    ></Select>
                                )}
                            </Grid.Col>
                            <Grid.Col span={1}>
                                <TextInput
                                    label="To"
                                    sx={{ ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 } }}
                                    value={model ? fmtSvc.toShortDate(fmtSvc.parseDateNoTime(model.SpendHistoryEndDate ?? '')) : 'Current Date'}
                                    disabled
                                />
                            </Grid.Col>
                        </Grid>
                    </ForecastCard>
                    <ForecastCard>
                        <ForecastCardTitle
                            title="Forecast Horizon"
                            readonly={readonly}
                            readonlyDescription="The forecast period, starting on the last day of historical spend data."
                            description="Define the length of the forecast period in days. This sets the future timeframe over which you wish to estimate costs, helping you plan budgets. The maximum days is approximately one-third of the historical period. "
                        />
                        <Grid columns={3}>
                            <Grid.Col span={1}>
                                {readonly ? (
                                    <TextInput
                                        label="Days"
                                        value={forecastDays.toString()}
                                        sx={{ ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 } }}
                                        disabled
                                    />
                                ) : (
                                    <NumberInput
                                        label="Days"
                                        onChange={(v) => setForecastDays(v ?? forecastMaxDays)}
                                        value={forecastDays}
                                        max={forecastMaxDays}
                                        min={0}
                                    />
                                )}
                            </Grid.Col>
                            <Grid.Col span={1}>
                                <TextInput
                                    label="End Date"
                                    value={fPTo}
                                    sx={{ ['input:disabled']: { color: theme.colors.gray[7], opacity: 1 } }}
                                    disabled
                                />
                            </Grid.Col>
                        </Grid>
                    </ForecastCard>

                    <ForecastCard>
                        <Grid columns={3}>
                            <Grid.Col span={2}>
                                <ForecastCardTitle
                                    title="Extra Dimensions"
                                    readonly={readonly}
                                    readonlyDescription="The extra dimensions by which the forecast data is grouped."
                                    description="Optionally, choose the dimensions (e.g., tags, regions, accounts) to group the forecast data by. Grouping by different dimensions can provide insights into specific cost drivers."
                                />
                                {readonly ? (
                                    <>
                                        {!description?.extraDimensions?.length ? (
                                            <Text color="dimmed" italic>
                                                No Extra Dimentions
                                            </Text>
                                        ) : (
                                            <List>
                                                {description.extraDimensions.map((d) => (
                                                    <List.Item key={d}>{d}</List.Item>
                                                ))}
                                            </List>
                                        )}
                                    </>
                                ) : (
                                    <MultiSelect
                                        label="Group By"
                                        data={groupValues}
                                        value={groupBy}
                                        onChange={setGroupBy}
                                        clearable
                                        disabled={readonly}
                                    ></MultiSelect>
                                )}
                            </Grid.Col>
                            <Grid.Col span={1}>
                                {!readonly ? (
                                    <Stack sx={{ height: '100%' }} align="center" spacing={0}>
                                        <Text color="dimmed" size="sm">
                                            Estimated forecast elements
                                        </Text>
                                        <Center sx={{ flex: 1 }}>
                                            {typeof cardinality !== 'number' ? (
                                                <Loader size="lg" />
                                            ) : cardinalityRange === 'invalid' ? null : (
                                                <Title sx={{ color: cardinalityColor }}>
                                                    {(cardinalityRange === 'high' ? '>' : '') +
                                                        fmtSvc.formatInt0Dec(Math.min(effectiveCardinality, 150000))}
                                                </Title>
                                            )}
                                        </Center>
                                        <Text align="center" italic color="error" size="sm">
                                            {cardinalityCheckStatus === 'loading'
                                                ? ''
                                                : cardinalityRange === 'invalid'
                                                ? 'No records found for sample period. Adjust filters'
                                                : cardinalityRange === 'high'
                                                ? 'Too many elements. Remove dimensions or add filters'
                                                : cardinalityRange === 'warning'
                                                ? 'Forecast may run long, consider removing dimensions or adding filters'
                                                : ''}
                                        </Text>
                                        {typeof etaMinutes === 'number' ? (
                                            <>
                                                <Text color="dimmed" size="sm">
                                                    Max forecast time:
                                                </Text>
                                                <Text>{cardinalityCheckStatus ? 'N/A' : etaLabel}</Text>
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </Stack>
                                ) : null}
                            </Grid.Col>
                        </Grid>
                    </ForecastCard>
                    <ForecastCard>
                        <ForecastCardTitle
                            title="Filters"
                            readonly={readonly}
                            readonlyDescription="The custom rules used to include only specific data in the forecast."
                            description="Create custom rules to include only specific data in the forecast. If no rules are specified, all available data will be considered. "
                        />
                        {loading ? (
                            <></>
                        ) : (
                            <>
                                <DataFilters
                                    dataFiltersAsLineItem
                                    valueProvider={schemaValueProvider}
                                    fieldInfoProvider={fieldInfoProvider!}
                                    filters={filters}
                                    onModelLoaded={setFilterModel}
                                    onChange={onFilterChange}
                                    renderFieldPicker={(select) => (
                                        <Box>
                                            <FieldPicker mode="single" selections={[]} schema={schema!} onChange={([f]) => select(f.path)} />
                                        </Box>
                                    )}
                                    disabled={readonly}
                                />
                                {!readonly ? (
                                    <AnchorButton
                                        data-atid="AddFilterButton"
                                        text="Add Filter"
                                        onClick={() => filterModel?.addEmptyFilter(true, false)}
                                        icon={<Plus size={16} />}
                                    />
                                ) : filters.length === 0 ? (
                                    <Text italic color="dimmed">
                                        No Filters
                                    </Text>
                                ) : null}
                            </>
                        )}
                    </ForecastCard>
                </Stack>
            </Box>
            <div>
                <Divider />
                <PanelToolbar>
                    {!readonly ? (
                        <>
                            <Button variant="outline" onClick={closeSidePanel}>
                                Cancel
                            </Button>
                            <Button
                                onClick={createForecast}
                                disabled={!createButtonEnabled || creating || cardinalityRange === 'high' || cardinalityRange === 'invalid'}
                                rightIcon={creating ? <Loader color="gray.0" size="sm" /> : undefined}
                            >
                                {creating ? 'Creating' : 'Create'} Forecast
                            </Button>
                        </>
                    ) : (
                        <Button onClick={closeSidePanel}>Close</Button>
                    )}
                </PanelToolbar>
            </div>
        </>
    );
}

export function ForecastDetailsDrawer({ model }: { model: SpendForecastModel }) {
    const nav = useNav();
    const notificationSvc = useDi(NotificationService);
    const onCreate = useCallback(
        async (request: CostForecastRequest) => {
            try {
                const job = await model.startForecast(request);
                if (job.Status == CostForecastRequestedJobStatus.Failed) {
                    showForecastError();
                } else {
                    nav.replaceParams({ forecastId: job.Id ?? '' });
                }
            } catch (error) {
                showForecastError();
            }
        },
        [model]
    );

    function showForecastError() {
        notificationSvc.notify('Error', 'Failed to start cost forecast. Please try again.', 'error', <AlertTriangle />);
    }

    const detailsRequest = useEventValue(model.detailsRequested);
    const show = !!detailsRequest;
    const job = detailsRequest?.job;
    const savedForecast = detailsRequest?.savedForecast;
    const onRename = useCallback(
        async (name: string) => {
            await model.rename(job?.Id, name);
        },
        [model, job?.Id]
    );
    return (
        <Drawer opened={show} onClose={model.closeDetails} position="right" size={850} withCloseButton={false}>
            <Stack sx={{ height: '100%' }} spacing={0}>
                {!show ? null : (
                    <>
                        <Box>
                            <Group position="apart" p="lg">
                                <Title order={4}>{job ? 'Cost Forecast Details' : 'New Cost Forecast'}</Title>
                                <CloseButton onClick={model.closeDetails} />
                            </Group>
                            <Divider />
                        </Box>
                        <ForecastDetails
                            onCreate={onCreate}
                            ranges={model.accountDateRange}
                            closeSidePanel={model.closeDetails}
                            savedForecast={savedForecast}
                            onRename={onRename}
                            job={job}
                        />
                    </>
                )}
            </Stack>
        </Drawer>
    );
}

type ValidationIssues = { total: number } & Partial<Record<'Missing Field' | 'Missing Value' | 'Missing Child Type', number>>;
