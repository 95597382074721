import { IQueryExpr } from '@apis/Customers/model';
import { InvoiceSchemaInfo, SchemaType } from '@apis/Invoices/model';
import { QueryExpr } from '@apis/Resources';
import { useEffect, useState } from 'react';
import { inject, injectable, singleton } from 'tsyringe';
import { useDiContainer } from '../DI';
import { Platform } from '../PlatformService';
import { FieldInfo, normalizeAndTraverseQuery, SchemaService, traverseExprDepthFirst } from '../QueryExpr';
import { FocusMetadataInfo, FocusMetadataService } from './FocusMetadataService';
import { IBaseInvoiceRecord, InvoiceSchemaService } from './InvoiceSchemaService';

type FieldSchemaPair = { schema: FieldSchemas; field: string };
type CompatibilityLookup = (knownField: { schema: FieldSchemas; field: string }) => Iterable<Partial<FieldSchemaPair> | undefined>;
interface IFieldCompatibilityLookup {
    getCompatibleFields: CompatibilityLookup;
}

class FieldCompatibilitySet implements IFieldCompatibilityLookup {
    private readonly leftLookup = new Map<string, string>();
    private readonly rightLookup = new Map<string, string>();
    public constructor(private readonly schemaLeft: FieldSchemas, private readonly schemaRight: FieldSchemas, fieldPairs: [string, string][]) {
        for (const [left, right] of fieldPairs) {
            this.leftLookup.set(left, right);
            this.rightLookup.set(right, left);
        }
    }

    public *getCompatibleFields({ schema, field }: { schema: FieldSchemas; field: string }) {
        if (schema === this.schemaLeft) {
            yield this.createResult(this.schemaRight, this.leftLookup.get(field));
        } else if (schema === this.schemaRight) {
            yield this.createResult(this.schemaLeft, this.rightLookup.get(field));
        }
        yield undefined;
    }

    private createResult(schema: FieldSchemas, field?: string) {
        return field ? { schema, field } : undefined;
    }
}

class FieldCompatibilityIterator {
    public constructor(private readonly compatLookups: IFieldCompatibilityLookup[] = []) {}

    public extend(lookups: IFieldCompatibilityLookup[]) {
        return new FieldCompatibilityIterator([...this.compatLookups, ...lookups]);
    }

    public *getCompatibleFields(knownField: { schema: FieldSchemas; field: string }): Iterable<FieldSchemaPair> {
        for (const lookup of this.compatLookups) {
            for (const result of lookup.getCompatibleFields(knownField)) {
                if (result && result.field && result.schema) {
                    yield result as FieldSchemaPair;
                }
            }
        }
    }
}

interface ICompatibilitySearchOptions {
    useLegacyFallbacks?: boolean;
}

@singleton()
class InvoiceFieldCompatibilityCache {
    private baseIterator?: Promise<FieldCompatibilityIterator>;

    public constructor(@inject(FocusMetadataService) private readonly focusMetadataSvc: FocusMetadataService) {}

    public getBaseCompatibilityService() {
        return (this.baseIterator ??= this.createBaseIterator());
    }

    public applyOptions(iterator: FieldCompatibilityIterator, options: ICompatibilitySearchOptions = {}) {
        if (options.useLegacyFallbacks) {
            iterator = iterator.extend([this.createLegacyFallbackLookup()]);
        }
        return iterator;
    }

    private async createBaseIterator() {
        const focusMeta = await this.focusMetadataSvc.getFocusMetadata();
        const focusCompat = this.createFocusCompatibilityLookup(focusMeta);
        const proprietaryCompats = this.createProprietaryFieldCompatibilityLookups();
        const legacyCompat = this.createLegacyFieldCompatibilityLookup();

        return new FieldCompatibilityIterator([focusCompat, ...proprietaryCompats, legacyCompat]);
    }

    // # region FOCUS
    private createFocusCompatibilityLookup(focusMeta: FocusMetadataInfo) {
        const getCompatibleFields: CompatibilityLookup = function* (knownField: { schema: FieldSchemas; field: string }) {
            if (knownField.schema === 'FocusV1') {
                const focusFieldInfo = focusMeta.getField(knownField.field);
                if (focusFieldInfo) {
                    for (const alias of focusFieldInfo.Aliases ?? []) {
                        const schema: null | FieldSchemas =
                            alias.Schema === 'AwsCurV1'
                                ? 'AwsCurV1'
                                : alias.Schema === 'AwsDataExportsCurV2'
                                ? 'AzureIdealV1'
                                : alias.Schema === 'AzureCostExportIdeal20240101'
                                ? 'AzureIdealV1'
                                : null;

                        if (schema) {
                            yield { schema, field: alias.Field ?? '' };
                        }
                    }
                }
            } else {
                const platform: Platform | undefined =
                    knownField.schema === 'AwsCurV1' ? 'Aws' : knownField.schema === 'AzureIdealV1' ? 'Azure' : undefined;
                if (platform) {
                    const focusFieldInfo = focusMeta.getFieldByAlias(platform, knownField.field);
                    if (focusFieldInfo) {
                        yield { schema: 'FocusV1', field: focusFieldInfo.Field ?? '' };
                    }
                }
            }
        };
        return { getCompatibleFields } as IFieldCompatibilityLookup;
    }
    // # endregion

    // # region Proprietary
    private *createProprietaryFieldCompatibilityLookups(): Iterable<IFieldCompatibilityLookup> {
        yield new FieldCompatibilitySet('Proprietary', 'AzureIdealV1', [['NativeLineItemType', 'ChargeType']]);
        yield new FieldCompatibilitySet('Proprietary', 'AwsCurV1', [
            ['NativeLineItemType', 'lineItem/LineItemType'],
            ['NativeUsageType', 'lineItem/UsageType'],
        ]);
    }
    // # endregion

    // # Legacy CUR
    private createLegacyFieldCompatibilityLookup(): IFieldCompatibilityLookup {
        return new FieldCompatibilitySet('FocusV1', 'AwsCurV1', [
            ['ResourceId', 'lineItem/ResourceId'],
            ['ConsumedQuantity', 'lineItem/UsageAmount'],
            ['ContractedUnitPrice', 'lineItem/UnblendedRate'],
            ['BilledCost', 'lineItem/UnblendedCost'],
        ]);
    }

    private createLegacyFallbackLookup(): IFieldCompatibilityLookup {
        return new FieldCompatibilitySet('FocusV1', 'AwsCurV1', [
            ['ServiceCategory', 'product/ProductName'],
            ['InvoiceIssuerName', 'bill/BillingEntity'],
        ]);
    }
    // # endregion
}

type IngestionType = 'FOCUS' | 'Aws' | 'Azure';
type FieldSchemas = 'System' | 'Proprietary' | 'FocusV1' | 'AwsCurV1' | 'AzureIdealV1';

type CompatibilityFilter = { ingestionType?: IngestionType /* months: { year?: number, month?: number }[] */ };

export interface IInvoiceFieldCompatibilityLookup {
    /**
     * Pass a standard schema field to get a compatible, available field
     * If standard fields are available, they will be used. Otherwise, native fields will be returned
     *
     * @returns Matching FieldInfo for each passed standard field
     */
    getAvailableField<T extends IBaseInvoiceRecord>(standardField: string & keyof T, filterOptions?: CompatibilityFilter): FieldInfo | undefined;
    /**
     * Same as getAvailableField, but for multiple fields and returns a map of requested field to avail field
     * @returns Matching FieldInfo for each passed standard fields
     */
    getAvailableFields<T extends IBaseInvoiceRecord>(...standardFieldNames: Array<keyof T & string>): string[];
    /**
     * Same as getAvailableField, but for multiple fields and returns a map of requested versus result field
     * @returns Matching FieldInfo for each passed standard fields
     */
    getAvailableFieldInfo<P extends Array<keyof T & string>, T = IBaseInvoiceRecord>(
        ...standardFieldNames: P
    ): { [K in P[number]]?: FieldInfo | undefined };
    /**
     * Get basic field details by field ID. Details include DisplayName and IndexedField
     * @param fieldIds
     */
    getDetailsById(...fieldIds: string[]): Record<string, { DisplayName: string; IndexedField: string }>;
    /**
     * Traverse query expressions and update fields to match available fields
     * @param query
     */
    adjustQueryFields<T extends QueryExpr | IQueryExpr>(exprs: T[]): void;
}

class InvoiceFieldCompatibilityLookup implements IInvoiceFieldCompatibilityLookup {
    private static ingestionTypeFieldSchemaMap: Record<IngestionType, FieldSchemas> = { FOCUS: 'FocusV1', Aws: 'AwsCurV1', Azure: 'AzureIdealV1' };
    private static fieldSchemaIngestionTypeMap = new Map<string, string>([
        ['FocusV1', 'FOCUS'],
        ['AwsCurV1', 'Aws'],
        ['AzureIdealV1', 'Azure'],
    ]) as Map<FieldSchemas, IngestionType>;

    public constructor(
        private readonly compatibilityIterator: FieldCompatibilityIterator,
        private readonly schemaSvc: SchemaService,
        private readonly schemaInfo: InvoiceSchemaInfo
    ) {}

    public getAvailableField(standardField: string, filterOptions?: CompatibilityFilter): FieldInfo | undefined {
        const fieldSchema: FieldSchemaPair = { schema: 'FocusV1', field: standardField };

        const exactMatch = this.getFieldInfo(fieldSchema);
        if (exactMatch) {
            return exactMatch;
        }

        if (!this.schemaInfo.ShouldUseFocusSchema) {
            for (const result of this.search(fieldSchema, filterOptions)) {
                return result;
            }
        }

        return this.getFieldInfo(standardField);
    }

    public getAvailableFieldInfo(...standardFieldNames: string[]) {
        const result: Record<string, undefined | FieldInfo> = {};
        for (const field of standardFieldNames) {
            result[field] = this.getAvailableField(field);
        }
        return result;
    }

    public getAvailableFields(...standardFieldNames: string[]) {
        return standardFieldNames.map((name) => {
            return this.getAvailableField(name)?.path ?? name;
        }) as ReturnType<IInvoiceFieldCompatibilityLookup['getAvailableFields']>;
    }

    public getDetailsById(...fieldIds: string[]): Record<string, { DisplayName: string; IndexedField: string }> {
        return fieldIds.reduce((result, fieldId) => {
            const schFld = this.schemaSvc.getFieldWithId(fieldId);
            if (schFld) {
                result[fieldId] = { DisplayName: schFld.name, IndexedField: schFld.fieldName };
            }
            return result;
        }, {} as Record<string, { DisplayName: string; IndexedField: string }>);
    }

    public adjustQueryFields<T>(exprs: T[]) {
        for (const expr of exprs) {
            traverseExprDepthFirst(expr, (expr) => {
                if ('Field' in expr && !!expr.Field) {
                    const schFld = this.schemaSvc.getFieldWithId(expr.Field);
                    if (schFld) {
                        expr.Field = schFld.fieldName;
                    }
                }
            });
        }
    }

    private *search(knownField: FieldSchemaPair, filterOptions?: CompatibilityFilter): Iterable<FieldInfo> {
        for (const result of this.searchPairs(knownField, filterOptions)) {
            const fieldInfo = this.getFieldInfo(result);
            if (fieldInfo) {
                yield fieldInfo;
            }
        }
    }

    private *searchPairs(knownField: FieldSchemaPair, filterOptions?: CompatibilityFilter): Iterable<FieldSchemaPair> {
        const filter = this.createFilter(filterOptions);
        for (const result of this.compatibilityIterator.getCompatibleFields(knownField)) {
            if (filter(result)) {
                yield result;
            }
        }
    }

    private createFilter({ ingestionType }: CompatibilityFilter = {}) {
        return (result: FieldSchemaPair) => {
            if (ingestionType && result.schema !== this.getFieldSchema(ingestionType)) {
                return false;
            }
            return true;
        };
    }

    private getFieldInfo(fieldIdOrPathOrPair: FieldSchemaPair | string): FieldInfo | undefined {
        if (typeof fieldIdOrPathOrPair === 'string') {
            return this.schemaSvc.getFieldWithId(fieldIdOrPathOrPair) ?? this.schemaSvc.getField(fieldIdOrPathOrPair);
        } else {
            const { schema, field } = fieldIdOrPathOrPair;
            const ingestionType = this.getIngestionType(schema);
            return this.schemaSvc.getFieldWithId(`${ingestionType}.${field}`);
        }
    }

    private getIngestionType(schema: FieldSchemas) {
        return InvoiceFieldCompatibilityLookup.fieldSchemaIngestionTypeMap.get(schema);
    }
    private getFieldSchema(ingestionType: IngestionType) {
        return InvoiceFieldCompatibilityLookup.ingestionTypeFieldSchemaMap[ingestionType];
    }
}

@injectable()
export class InvoiceFieldCompatibilityService {
    public constructor(
        @inject(InvoiceFieldCompatibilityCache) private readonly cache: InvoiceFieldCompatibilityCache,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService
    ) {}

    public async getLookup(
        schema: 'monthly' | 'daily' | SchemaService | SchemaType[],
        options: ICompatibilitySearchOptions = {}
    ): Promise<IInvoiceFieldCompatibilityLookup> {
        const [baseSearchSvc, schemaInfo, tenantSchema] = await Promise.all([
            this.cache.getBaseCompatibilityService(),
            this.invoiceSchemaSvc.getInvoiceSchemaInfo(),
            this.normalizeSchema(schema),
        ]);
        const searchSvc = this.cache.applyOptions(baseSearchSvc, options);
        return new InvoiceFieldCompatibilityLookup(searchSvc, tenantSchema, schemaInfo);
    }

    private async normalizeSchema(schema: 'monthly' | 'daily' | SchemaService | SchemaType[]) {
        if (typeof schema === 'object' && schema && !Array.isArray(schema)) {
            return schema;
        } else {
            const types =
                schema === 'monthly'
                    ? await this.invoiceSchemaSvc.getMonthlySchema()
                    : schema === 'daily'
                    ? await this.invoiceSchemaSvc.getDailySchema()
                    : schema ?? [];

            return new SchemaService(types);
        }
    }
}

export function useInvoiceFieldCompatibility(schema: 'monthly' | 'daily' | SchemaService | SchemaType[], useLegacyFallbacks?: boolean) {
    const container = useDiContainer();
    const [fieldCompat, setFieldCompat] = useState<IInvoiceFieldCompatibilityLookup>();
    useEffect(() => {
        let destroyed = false;
        (async () => {
            const compatSvc = container.resolve(InvoiceFieldCompatibilityService);
            const lookup = await compatSvc.getLookup(schema, { useLegacyFallbacks });
            if (!destroyed) {
                setFieldCompat(lookup);
            }
        })();
        return () => {
            destroyed = true;
        };
    }, []);
    return fieldCompat;
}
