import { getAccountGetAccounts } from '@apis/Customers';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { InvoiceFieldCompatibilityService, useInvoiceFieldCompatibility } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';
import { IBaseInvoiceRecord, IMonthlyRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useState, useEffect } from 'react';

export interface RangePickerItem {
    month: Date;
    accountId: string;
    accountName: string;
}

export function useRangePickerData(invoiceApi: InvoiceApiService) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<RangePickerItem[]>();
    const fmtSvc = useDi(FormatService);
    const fieldCompat = useInvoiceFieldCompatibility('monthly');

    useEffect(() => {
        if (fieldCompat) {
            setLoading(true);
            (async () => {
                const [acctId, acctName] = await fieldCompat!.getAvailableFields('BillingAccountId', 'BillingAccountName');

                const accountMonths = await queryBuilder<IBaseInvoiceRecord & Record<string, string>>()
                    .select((b) => ({
                        month: b.model.UsageMonth,
                        accountId: b.model[acctId],
                        accountName: b.model[acctName],
                        count: b.count(),
                    }))
                    .execute((q) => invoiceApi.queryMonthlyRollup(q, []));

                const items = (accountMonths.Results ?? []).map((r) => ({
                    ...r,
                    month: fmtSvc.parseDateNoTime(r.month),
                    accountName: r.accountName ?? '',
                }));
                setData(items.filter((r) => r.month.getTime() <= new Date().getTime()));
            })().finally(() => setLoading(false));
        }
    }, [fieldCompat]);

    return { loading, data };
}

export function getUniqueAccounts(ranges: RangePickerItem[]) {
    const result: RangePickerItem[] = [];
    for (const item of ranges.reduce((result, item) => result.set(item.accountId, item), new Map<string, RangePickerItem>()).values()) {
        if (item.accountId) {
            result.push(item);
        }
    }
    result.sort((a, b) => a.accountName.localeCompare(b.accountName, undefined, { sensitivity: 'base' }));
    return result;
}

export function getUniqueMonths(ranges: RangePickerItem[], fmtSvc: FormatService) {
    const monthsAdded = new Set<string>();
    const result: { label: string; value: string; date: Date }[] = [];
    const months = ranges
        .filter((r) => !!r.accountId)
        .map((r) => r.month)
        .sort((a, b) => a.getTime() - b.getTime());
    for (const month of months) {
        const label = fmtSvc.formatLongMonthYear(month);
        if (!monthsAdded.has(label)) {
            monthsAdded.add(label);
            result.push({ label, value: fmtSvc.formatYearMonth(month), date: month });
        }
    }
    return result;
}

export function formatMonths(ranges: Date[], fmtSvc: FormatService) {
    const monthsAdded = new Set<string>();
    const result: { label: string; value: string; date: Date }[] = [];
    const months = ranges.map((r) => r).sort((a, b) => a.getTime() - b.getTime());
    for (const month of months) {
        const label = fmtSvc.formatLongMonthYear(month);
        if (!monthsAdded.has(label)) {
            monthsAdded.add(label);
            result.push({ label, value: fmtSvc.formatYearMonth(month), date: month });
        }
    }
    return result;
}
