import { QueryExpr, QueryResult } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Box, Center, Group, Modal, Text, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { GridGroupByState } from '@root/Components/DataGrid/Models';
import { DailyMatrixInvoiceGrid } from '@root/Components/Invoices/DailyMatrixInvoiceGrid';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { addDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { VarianceDetails } from './VarianceDetails';
import { useNav } from '@root/Services/NavigationService';
import { useInvoiceFieldCompatibility } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';

export function DailyVariance({ invoiceApi, day, filters }: { invoiceApi: InvoiceApiService; day: Date; filters?: QueryExpr[] }) {
    const fmtSvc = useDi(FormatService);
    const { from, to } = useMemo(() => ({ from: addDays(day, -1), to: day }), [day]);
    const [gridFilters, setGridFilters] = useState<QueryExpr[]>([]);
    const varianceQueryApi = useMemo(
        () =>
            function <T>(query: Query) {
                return invoiceApi.query(query, { from, to }) as Promise<QueryResult<T>>;
            },
        [invoiceApi, from, to]
    );
    const varianceFilters = useMemo(() => {
        const result = [
            { Operation: 'eq', Operands: [{ Field: 'ChargePeriodStart' }, { Value: [fmtSvc.toJsonShortDate(from), fmtSvc.toJsonShortDate(to)] }] },
        ] as QueryExpr[];
        if (filters) {
            result.push(...filters);
        }
        result.push(...gridFilters);
        return result;
    }, [filters, gridFilters]);
    const fieldCompat = useInvoiceFieldCompatibility('daily', true);
    const groupBy = useMemo(
        () => [{ id: fieldCompat?.getAvailableField('ServiceCategory')?.path, sortDir: 'Asc', sortMode: 'value' }] as GridGroupByState[],
        [fieldCompat]
    );
    const dayRange = useMemo(() => [from, to], [from, to]);
    return (
        <Group noWrap sx={{ height: '100%' }}>
            <Box sx={{ height: '100%', width: 1000 }}>
                <DailyMatrixInvoiceGrid
                    persistenceKey="DailyVariance"
                    filters={filters}
                    days={dayRange}
                    invoiceApi={invoiceApi}
                    onFilterChanged={setGridFilters}
                    includeDifference
                    defaultGroupBy={groupBy}
                />
            </Box>
            <Box sx={{ width: 300 }}>
                <VarianceDetails
                    filters={varianceFilters}
                    from={from}
                    to={to}
                    groupBy="ChargePeriodStart"
                    queryApi={varianceQueryApi}
                    costField="BilledCost"
                />
            </Box>
        </Group>
    );
}

export function DailyVarianceModal({
    invoiceApi,
    opener,
    filters,
}: {
    invoiceApi: InvoiceApiService;
    opener: (opener: { open: (day: Date) => void }) => void;
    filters?: QueryExpr[];
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [opened, { open, close }] = useDisclosure(false);
    const [day, setDay] = useState<Date>(new Date());
    const openModal = useCallback(
        (day: Date) => {
            setDay(day);
            open();
        },
        [opener]
    );
    useEffect(() => opener({ open: openModal }), [openModal, opener]);
    const { from, to } = useMemo(() => ({ from: addDays(day, -1), to: day }), [day]);

    const title = (
        <Box>
            <Text size="xs" color="dimmed">
                Daily Variance
            </Text>
            <Text>
                {fmtSvc.toShortDate(from)} - {fmtSvc.toShortDate(to)}
            </Text>
        </Box>
    );

    return (
        <Modal
            zIndex={100}
            size={1350}
            styles={{ modal: { background: theme.colors.gray[1] } }}
            radius="lg"
            opened={opened}
            onClose={close}
            title={title}
            withinPortal
        >
            <Box sx={{ height: 'calc(100vh - 170px)' }}>
                {from.getMonth() !== to.getMonth() ? (
                    <Center sx={{ height: '100%' }}>
                        <Text align="center" color="dimmed">
                            Cross-month daily variance is not available.
                            <br /> Invoices would not match.
                        </Text>
                    </Center>
                ) : opened ? (
                    <DailyVariance filters={filters} invoiceApi={invoiceApi} day={day} />
                ) : null}
            </Box>
        </Modal>
    );
}
