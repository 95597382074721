import { FundSource, FundSourceAmortization, FundSourceAmortizationAmortizationPeriodUnit } from '@apis/Invoices/model';
import { QueryExpr } from '@apis/Resources';
import styled from '@emotion/styled';
import {
    Alert,
    Box,
    Button,
    Card,
    Collapse,
    Divider,
    Group,
    List,
    NumberInput,
    Overlay,
    Popover,
    Portal,
    SegmentedControl,
    Select,
    Space,
    Stack,
    Text,
    TextInput,
    ThemeIcon,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { BarItemProps, ResponsiveBar } from '@nivo/bar';
import { GridGroupByState } from '@root/Components/DataGrid/Models';
import { LineItemCompactToken } from '@root/Components/Filter/Design';
import { DataFilterModel, DataFilters } from '@root/Components/Filter/Filters';
import { SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { DailyInvoiceGrid } from '@root/Components/Invoices/DailyInvoiceGrid';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { FillerSwitch } from '@root/Design/Filler';
import { TooltipWhite, useReadonlyInputStyles } from '@root/Design/Primitives';
import { SidePanelToolbarEl } from '@root/Design/SidePanel';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { IInvoiceRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { cleanBoolExpr, FieldInfo, queryBuilder, SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { endOfMonth, startOfDay, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Calculator, CalendarTime, Checkbox, ChevronDown, ChevronUp, Plus, Poo } from 'tabler-icons-react';
import { FilterSetEditor, useFilterValueProviders } from '../FilterSetEditor/FilterSetEditor';
import { NamedFilterSetModel } from '../FilterSetEditor/NamedFilterSetModel';
import { useFilterSetExpr, useInvoiceDatasource } from './Components';
import { BaseAllocSettingsEditorProps, InvoiceDataSource } from './Models';
import { FullSwitchCard, LabeledSwitch } from '@root/Design/Switches';

export interface LineItemSourceEditorProps extends BaseAllocSettingsEditorProps {
    source: FundSource;
    onApply?: (target: FundSource) => void;
    onCancel: () => void;
}

export function AdvancedLineItemSourceEditor({ month, source, schemaSvc, onApply, onCancel }: LineItemSourceEditorProps) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const name = useMemo(() => new EventEmitter<string>(source.Name ?? ''), [source.Name]);
    const pendingSourceFilterSet = useMemo(() => ({ sourceFilter: source.Filters ?? {} }), [source]);
    const sourceFilterSetModel = useMemo(() => NamedFilterSetModel.create(pendingSourceFilterSet, 'sourceFilter', true), [pendingSourceFilterSet]);
    const { datasource, datasourceApi } = useInvoiceDatasource(month);
    const queryExpr = useFilterSetExpr(sourceFilterSetModel);
    const dateRange = useMemo(() => ({ from: month, to: month }), [month]);
    const defaultGroupBy = useMemo(() => [{ id: 'product.product/ProductName', sortDir: 'Desc', sortMode: 'count' }] as GridGroupByState[], []);
    const handleApplyClick = useCallback(() => {
        onApply?.({ Name: name.value, Filters: pendingSourceFilterSet.sourceFilter, SourceType: 'LineItems', Amortization: source.Amortization });
        onCancel();
    }, [onApply, name, pendingSourceFilterSet]);
    const handleCancelClick = useCallback(() => onCancel?.(), [onCancel]);
    const hasRules = !sourceFilterSetModel.isEmpty() && !!cleanBoolExpr(queryExpr);
    const canApply = hasRules;

    return (
        <Group noWrap sx={{ height: '100%' }} spacing={0}>
            <Stack sx={{ height: '100%', flex: '0 0 500px' }} spacing={0}>
                <Title p="lg" py="xs" order={3}>
                    Allocation Source
                </Title>
                <Divider />
                <SourceName name={name} datasource={datasource} filters={queryExpr} />
                <Divider />
                <Box p="md" sx={{ flex: 1, overflow: 'auto' }}>
                    <Stack>
                        <Text>Line Item Filter Rules</Text>
                        <FilterSetEditor
                            descriptions={{
                                inclusion: {
                                    helpText: (
                                        <>
                                            <List size="sm">
                                                <List.Item>Condition Groups are OR'ed together.</List.Item>
                                                <List.Item>Conditions within a group are AND'ed together.</List.Item>
                                            </List>
                                            <br />
                                            Invoice line items which meet all criteria of any of these condition groups will be{' '}
                                            <strong>included in</strong> this allocation source.
                                        </>
                                    ),
                                },
                                exclusion: {
                                    helpText: (
                                        <>
                                            Invoice line items which meet all criteria of any of these rules will be <strong>excluded from</strong>{' '}
                                            this allocation source.
                                            <br />
                                            Exclusion conditions will override inclusion conditions.
                                        </>
                                    ),
                                },
                            }}
                            datasource={datasource}
                            filterSet={sourceFilterSetModel}
                            schemaSvc={schemaSvc}
                        />
                        <AmortizationSettings source={source} schemaSvc={schemaSvc} month={month} />
                    </Stack>
                </Box>
                <Divider />
                <SidePanelToolbarEl>
                    <Button variant="outline" onClick={handleCancelClick}>
                        Cancel
                    </Button>
                    <Button disabled={!canApply} onClick={handleApplyClick}>
                        Apply
                    </Button>
                </SidePanelToolbarEl>
            </Stack>
            <Divider orientation="vertical" />
            <Stack
                sx={{
                    height: '100%',
                    flex: 1,
                    overflow: 'hidden',
                    background: theme.colors.gray[2],
                    backgroundImage: 'linear-gradient(90deg, #0001, transparent 12px)',
                }}
                pb="lg"
                px="lg"
                spacing={0}
            >
                <Box sx={{ flex: 1, minWidth: 0, minHeight: 0 }}>
                    <DailyInvoiceGrid
                        persistenceKey="AllocSourceEditorGrid"
                        defaultGroupBy={defaultGroupBy}
                        dateRange={dateRange}
                        invoiceApi={datasourceApi}
                        scope={queryExpr}
                        filtersDisabled
                        disabledSavedViews
                        rightPlaceholder={
                            <Box mr="lg">
                                <Title mt="xs" order={3}>
                                    Rule Preview
                                </Title>
                                <Text align="right" size="xs">
                                    <Text color="dimmed" component="span" mr={4}>
                                        Billing Period:
                                    </Text>
                                    {fmtSvc.formatMonth(month)}
                                </Text>
                            </Box>
                        }
                    />
                </Box>
                <Space h="md" />
                <TotalsSection filter={queryExpr} datasource={datasource} month={month} />
            </Stack>
        </Group>
    );
}

class AmortSettingsModel {
    public readonly changed = EventEmitter.empty();
    public readonly enabled: EventEmitter<boolean>;
    public mode: 'expr' | 'adjustAmortCost' | 'amount' = 'adjustAmortCost';
    public cancelExprEnabled = false;
    public get amort() {
        return this.source.Amortization!;
    }

    public constructor(public readonly source: FundSource) {
        const amort = (source.Amortization ??= {});
        const enabled = !!amort.AmortizationPeriodUnit && amort.AmortizationPeriodUnit !== 'None';
        this.enabled = new EventEmitter<boolean>(enabled);
        this.mode = !enabled ? 'adjustAmortCost' : amort.AmountExpr ? 'expr' : amort.Amount ? 'amount' : 'adjustAmortCost';
        this.cancelExprEnabled = !!source.Amortization?.CanceledSourceExpr;
    }

    public updateMode(mode: typeof AmortSettingsModel.prototype.mode) {
        if (mode !== this.mode) {
            this.mode = mode;
            this.applyDefaults();
            this.changed.emit();
        }
    }

    public toggleEnabled = () => {
        const nextValue = !this.enabled.value;
        this.enabled.emit(nextValue);
        this.applyDefaults();
    };

    private applyDefaults() {
        if (!this.enabled) {
            this.updateSettings({ AmortizationPeriodUnit: 'None' }, true);
        } else if (this.mode === 'adjustAmortCost') {
            this.updateSettings({ AmortizationPeriodUnit: 'Months', AmortizationPeriodValue: 1 }, true);
        } else if (this.mode === 'expr') {
            this.updateSettings({ AmortizationPeriodUnit: 'Months', AmortizationPeriodValue: 1 }, true);
            this.cancelExprEnabled = false;
        } else {
            this.updateSettings(
                { AmortizationPeriodUnit: 'Years', Date: startOfMonth(startOfDay(new Date())).toISOString(), AmortizationPeriodValue: 1, Amount: 0 },
                true
            );
        }
    }

    public update = (settings: Partial<FundSourceAmortization>) => {
        this.updateSettings(settings);
    };

    public getField() {
        return !this.amort.AmountExpr || !('Field' in this.amort.AmountExpr) ? undefined : (this.amort.AmountExpr.Field as string);
    }

    public setField = (field: FieldInfo) => {
        this.update({ AmountExpr: { Field: field.path } });
    };

    public toggleCancelExpr = () => {
        this.cancelExprEnabled = !this.cancelExprEnabled;
        this.updateSettings({
            CanceledSourceExpr: !this.cancelExprEnabled
                ? undefined
                : { Operation: 'and', Operands: [{ Operation: 'eq', Operands: [{ Field: 'lineItem/LineItemType' }, { Value: [] }] }] },
        });
    };

    private updateSettings(settings: Partial<FundSourceAmortization>, replace = false) {
        this.source.Amortization = Object.assign(replace ? {} : this.amort, settings);
        this.changed.emit();
    }
}

function AmortizationSettings({ source, schemaSvc, month }: { source: FundSource; schemaSvc: SchemaService; month: Date }) {
    const theme = useMantineTheme();
    const settings = useMemo(() => new AmortSettingsModel(source), [source]);
    useEvent(settings.changed);
    const enabled = useEventValue(settings.enabled);
    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = useMemo(() => () => setTimeout(() => scrollRef.current?.scrollIntoView({ behavior: 'smooth' }), 200), []);
    const updateUnit = useCallback(
        (unit: FundSourceAmortizationAmortizationPeriodUnit) => settings.update({ AmortizationPeriodUnit: unit }),
        [settings]
    );
    const mode = settings.mode;

    type Modes = typeof settings.mode;
    const updateAmortMode = (mode: Modes) => {
        settings.updateMode(mode);
        scrollToBottom();
    };
    const updateEnabled = () => {
        settings.toggleEnabled();
        scrollToBottom();
    };

    return (
        <>
            <Text>Amortization Settings</Text>
            <FullSwitchCard
                checked={!!enabled}
                onClick={updateEnabled}
                label="Enable Amortization"
                tooltip="Apply settings to spread cost evenly over a time period, applies to the Adjusted Amortized Cost field."
            />
            <Card hidden={!enabled} p="xs" withBorder radius="md" sx={{ background: theme.colors.gray[2] }}>
                <SegmentedControl
                    fullWidth
                    data={
                        [
                            { label: 'Default', value: 'adjustAmortCost' },
                            { label: 'Amount', value: 'amount' },
                            { label: 'Field', value: 'expr' },
                        ] as { label: string; value: Modes }[]
                    }
                    value={mode}
                    onChange={(v) => updateAmortMode(v as Modes)}
                />
                <Space h={8} />
                <Card shadow="xs" withBorder radius={4}>
                    <Collapse in={mode === 'adjustAmortCost'}>
                        <AmortizeDescription title="Amortize Automatically">
                            <Text size="xs">
                                The total for of the source line item Adjusted Amortized Cost will be reallocated to target Adjusted Amortized Cost,
                                spread evenly over the days in the billing period.
                            </Text>
                        </AmortizeDescription>
                    </Collapse>
                    <Collapse in={mode === 'expr'}>
                        <AmortizeDescription title="Amortize by Field">
                            The total of the selected field will be applied to the target Adjusted Amortized Cost, spread evenly over the days in the
                            billing period.
                        </AmortizeDescription>
                        <Space h={8} />
                        <AmortizationAmountExprSettings month={month} settings={settings} schemaSvc={schemaSvc} />
                    </Collapse>
                    <Collapse in={mode === 'amount'}>
                        <AmortizeDescription title="Amortize Manually">
                            The amount will be applied to the target Adjusted Amortized Cost, spread evenly over the days in the amortization period.
                        </AmortizeDescription>
                        <Space h={8} />
                        <NumberInput
                            name="amount"
                            sx={{ width: 200 }}
                            placeholder="Enter amount"
                            label="Amount"
                            value={settings.amort.Amount ?? 0}
                            onChange={(value) => settings.update({ Amount: value ?? 0 })}
                        />
                        <DatePicker
                            sx={{ width: 200 }}
                            label="Date"
                            value={settings.amort.Date ? new Date(settings.amort.Date) : new Date()}
                            onChange={(value) => settings.update({ Date: value?.toISOString() })}
                            icon={<CalendarTime />}
                        />
                        <NumberInput
                            name="amount"
                            sx={{ width: 250 }}
                            placeholder="Enter amount"
                            label="Amortize Over"
                            min={1}
                            value={settings.amort.AmortizationPeriodValue ?? 0}
                            onChange={(value) => settings.update({ AmortizationPeriodValue: value ?? 0 })}
                            rightSectionWidth={125}
                            rightSection={
                                <Select
                                    data={[
                                        { label: 'Years', value: 'Years' },
                                        { label: 'Months', value: 'Months' },
                                        { label: 'Days', value: 'Days' },
                                    ]}
                                    sx={{ ['input']: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } }}
                                    value={settings.amort.AmortizationPeriodUnit ?? 'Months'}
                                    onChange={(value) => updateUnit(value as 'Years' | 'Months' | 'Days')}
                                />
                            }
                        />
                    </Collapse>
                </Card>
            </Card>
            <div ref={scrollRef}></div>
        </>
    );
}

function AmortizeDescription({ title, children }: { title: string; children: React.ReactNode }) {
    return (
        <>
            <Text size="sm" color="gray">
                {title}
            </Text>
            <Text size="sm">{children}</Text>
        </>
    );
}

function AmortizationAmountExprSettings(props: { settings: AmortSettingsModel; schemaSvc: SchemaService; month: Date }) {
    const { settings, schemaSvc, month } = props;
    const { getFilters, setFilters } = useMemo(
        () =>
            NamedFilterSetModel.createSimpleSet(
                {
                    cancelExpr: {
                        InclusionRules: !settings.cancelExprEnabled ? undefined : [{ Name: '', Filter: settings.amort.CanceledSourceExpr }],
                    },
                },
                'cancelExpr'
            ),
        [settings.cancelExprEnabled]
    );
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const { datasource } = useInvoiceDatasource(month);
    const valueRendererProvider = useFilterValueProviders(datasource);
    const addFilter = useCallback(() => filterModel?.addEmptyFilter(true), [filterModel]);
    const valueProvider = useMemo(() => new SchemaValueProvider(schemaSvc, datasource), [schemaSvc]);
    const fieldInfoProvider = useMemo(() => new SchemaFieldNameProvider(schemaSvc), [schemaSvc]);

    return (
        <>
            <AmortizationFieldPicker onChange={settings.setField} field={settings.getField()} schema={schemaSvc} />
            <Space h="md" />
            <LabeledSwitch
                onClick={settings.toggleCancelExpr}
                checked={settings.cancelExprEnabled}
                label="Identify Capitalized Expenses"
                tooltip={
                    <>
                        Apply rules to identify which of the source line items are being captialized. Those line items' Adjusted Amortized Cost will
                        be adjusted to zero.
                    </>
                }
            />
            <Space h="xs" />
            <Collapse in={settings.cancelExprEnabled}>
                <DataFilters
                    filters={getFilters()}
                    onChange={setFilters}
                    valueProvider={valueProvider}
                    fieldInfoProvider={fieldInfoProvider}
                    valueRendererProvider={valueRendererProvider}
                    renderFieldPicker={(select) => (
                        <FieldPicker mode="single" selections={[]} schema={props.schemaSvc} onChange={([f]) => select(f.path)} />
                    )}
                    onModelLoaded={setFilterModel}
                    dataFiltersAsLineItem
                    lineItemCompact
                />
                <Space h={4} />
                <LineItemCompactToken styles={{ width: '100%' }} onClick={addFilter}>
                    <Group spacing={4}>
                        <Plus size={16} /> Add Condition
                    </Group>
                </LineItemCompactToken>
            </Collapse>
        </>
    );
}

function AmortizationFieldPicker({ field, onChange, schema }: { schema: SchemaService; field?: string; onChange: (field: FieldInfo) => void }) {
    const [opened, { close, open, toggle }] = useToggle(false);
    const onFieldSelect = useCallback(
        (field: FieldInfo[]) => {
            onChange(field[0]);
            close();
        },
        [onChange]
    );
    const fieldInfo = field ? schema.getField(field) : undefined;
    const fieldName = fieldInfo?.name;

    return (
        <>
            <Portal>{opened ? <Overlay onClick={close} opacity={0} zIndex={300} /> : null}</Portal>
            <Popover withinPortal shadow="md" opened={opened} onClose={close} onOpen={open}>
                <Popover.Dropdown p={0}>
                    <FieldPicker
                        mode="single"
                        onChange={onFieldSelect}
                        schema={schema}
                        selections={fieldInfo ? [fieldInfo] : []}
                        height={300}
                        types={['number']}
                    />
                </Popover.Dropdown>
                <Popover.Target>
                    <TextInput
                        readOnly
                        inputContainer={(children) => <Box onClick={toggle}>{children}</Box>}
                        color="gray"
                        sx={{ cursor: 'pointer' }}
                        rightSection={opened ? <ChevronUp /> : <ChevronDown />}
                        value={fieldName}
                        placeholder="Select field..."
                    />
                </Popover.Target>
            </Popover>
        </>
    );
}

function SourceName({ name: nameRef, filters, datasource }: { name: EventEmitter<string>; filters?: QueryExpr; datasource: InvoiceDataSource }) {
    const fmtSvc = useDi(FormatService);
    const [rulesTotal, setRulesTotal] = useState<{ amount: number }>();
    const [loading, setLoading] = useState(false);
    const getTotals = useTotalsRequest(datasource);
    useEffect(() => {
        setLoading(true);
        if (filters) {
            getTotals(filters)
                .then((r) => setRulesTotal(r.Results?.[0]))
                .finally(() => setLoading(false));
        } else {
            setRulesTotal(undefined);
        }
    }, [getTotals, filters]);
    const amountText = !filters
        ? '—'
        : loading || typeof rulesTotal?.amount !== 'number'
        ? '...'
        : fmtSvc.formatMoneyNonZeroTwoDecimals(rulesTotal?.amount);
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            nameRef.emit(e.target.value);
        },
        [nameRef]
    );
    const {
        classes: { readonly },
    } = useReadonlyInputStyles();
    const name = useEventValue(nameRef) ?? '';

    const helpText = (
        <Group noWrap align="flex-start">
            <ThemeIcon my="xs" variant="light" color="gray.5">
                <Calculator strokeWidth={1} />
            </ThemeIcon>
            <Box>
                <Text>Amount is calculated from invoice line items which match rules. </Text>
                {!filters ? (
                    <Text mt="sm" color="error">
                        No valid rules found
                    </Text>
                ) : null}
            </Box>
        </Group>
    );

    return (
        <Group p="lg" spacing={0}>
            <TextInput
                sx={{ flex: 1, ['input']: { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }}
                label="Name"
                value={name}
                onChange={handleChange}
                rightSection={<></>}
            />
            <TooltipWhite label={helpText} withinPortal multiline width={350} offset={0}>
                <TextInput
                    sx={{
                        textAlign: 'right',
                        maxWidth: 140,
                        ['input']: {
                            textAlign: 'right',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            cursor: 'default !important',
                            borderLeftWidth: 0,
                        },
                    }}
                    className={readonly}
                    label="Amount"
                    value={amountText}
                    disabled
                />
            </TooltipWhite>
        </Group>
    );
}

function useTotalsRequest(datasource: InvoiceDataSource) {
    return useCallback(
        (filter?: QueryExpr) => {
            let qb = queryBuilder<IInvoiceRollup>();
            if (filter) {
                qb = qb.where((b) => b.fromExpr<boolean>(filter));
            }
            return qb.select((b) => ({ amount: b.sum(b.model['lineItem/UnblendedCost']), count: b.count() })).execute(datasource);
        },
        [datasource]
    );
}

function TotalsSection({ filter, datasource, month }: { filter?: QueryExpr; datasource: InvoiceDataSource; month: Date }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState<{ amount: number; count: number }>();
    const [fullTotal, setFullTotal] = useState<{ amount: number; count: number }>();
    const [values, setValues] = useState<{ cost: number; date: number }[]>([]);
    const [fullValues, setFullValues] = useState<{ cost: number; date: number }[]>([]);
    const requestRef = useMemo(() => ({ req: 1 }), []);
    const [chartData, setChartData] = useState<{ date: Date; cost: number; lastDom: number }>();
    const getTotals = useTotalsRequest(datasource);
    const getDateTotals = useCallback(
        (filter?: QueryExpr) => {
            const date = fmtSvc.parseDateNoTime(month);
            const fromDate = startOfMonth(date);
            const toDate = startOfDay(endOfMonth(date));
            let qb = queryBuilder<IInvoiceRollup & { UsageStartDate: string }>();
            if (filter) {
                qb = qb.where((b) => b.fromExpr<boolean>(filter));
            }
            return qb
                .select((b) => ({
                    date: b.truncDate('day', b.model.UsageStartDate as unknown as Date, 0, fromDate, toDate) as unknown as number,
                    cost: b.sum(b.model['lineItem/UnblendedCost']),
                }))
                .execute(datasource);
        },
        [datasource, month]
    );
    useEffect(() => {
        getTotals().then((r) => setFullTotal(r.Results?.[0]));
        getDateTotals().then((r) => setFullValues(r.Results ?? []));
    }, []);
    useEffect(() => {
        setLoading(true);
        if (filter) {
            requestRef.req++;
            const currReq = requestRef.req;

            const totalsRequest = getTotals(filter);

            const datesRequest = getDateTotals(filter);

            Promise.all([totalsRequest, datesRequest]).then(([totals, dates]) => {
                if (requestRef.req === currReq) {
                    setTotal(totals.Results?.[0]);
                    setValues(dates.Results ?? []);
                    setLoading(false);
                }
            });
        } else {
            setTotal(undefined);
            setValues([]);
            setLoading(false);
        }
    }, [JSON.stringify(filter)]);

    const onBarHover = useCallback((item: { date: number; cost: number } | undefined) => {
        if (!item) {
            setChartData(undefined);
        } else {
            const date = !item.date ? undefined : fmtSvc.parseDateNoTime(item.date);
            const lastDom = !date ? null : endOfMonth(date).getDate();
            setChartData(!date || !lastDom ? undefined : { date, cost: item.cost, lastDom });
        }
    }, []);
    const clearBarHover = useCallback(() => setChartData(undefined), []);
    const labelPos = 100 * (!chartData ? 0 : chartData.date.getDate() / chartData.lastDom);

    return (
        <Group align="stretch">
            <Card onMouseLeave={clearBarHover} radius="md" p={0} pb={5} withBorder sx={{ background: theme.colors.gray[1], flex: 1, height: 120 }}>
                <FillerSwitch loading={loading}>
                    {() => (
                        <>
                            <DayTotalBarChart values={values.length ? values : fullValues} onHover={onBarHover} hasFilter={!!filter} />
                            <ChartLabel pos={labelPos}>
                                <Space w="md" />
                                {chartData ? (
                                    <Box sx={{ display: 'flex' }}>
                                        <Text color="gray.5" size="sm" sx={{ whiteSpace: 'nowrap' }}>
                                            {fmtSvc.formatLongDay(chartData.date)}
                                        </Text>
                                        <Space w="md" />
                                        <Text color="gray.6" size="sm">
                                            {fmtSvc.formatMoney(chartData.cost ?? 0)}
                                        </Text>
                                    </Box>
                                ) : (
                                    <Text color="gray.5" size="sm" sx={{ whiteSpace: 'nowrap' }}>
                                        Daily Totals
                                    </Text>
                                )}
                                <Space w="md" />
                            </ChartLabel>
                        </>
                    )}
                </FillerSwitch>
            </Card>
            <Card radius="md" p="sm" withBorder sx={{ background: theme.colors.gray[1], minWidth: 150 }}>
                <Text align="right" size="xs" color="dimmed">
                    Rule Total
                </Text>
                <Title align="right" order={4}>
                    {total ? fmtSvc.formatMoney(total.amount) : <>&mdash;</>}
                </Title>
                <Space h={8} />
                <Text align="right" size="xs" color="dimmed">
                    Invoice Total
                </Text>
                <Text color="dimmed" size="sm" align="right">
                    {fullTotal ? fmtSvc.formatMoney(fullTotal.amount) : <>&mdash;</>}
                </Text>
            </Card>
            <Card radius="md" p="sm" withBorder sx={{ background: theme.colors.gray[1], minWidth: 150 }}>
                <Text size="xs" align="right" color="dimmed">
                    Rule Line Items
                </Text>
                <Title align="right" order={4}>
                    {total ? fmtSvc.formatInt0Dec(total.count) : <>&mdash;</>}
                </Title>
                <Space h={8} />
                <Text size="xs" align="right" color="dimmed">
                    Invoice Line Items
                </Text>
                <Text color="dimmed" size="sm" align="right">
                    {fullTotal ? fmtSvc.formatInt0Dec(fullTotal.count) : <>&mdash;</>}
                </Text>
            </Card>
        </Group>
    );
}
const ChartLabel = styled.div<{ pos: number }>`
    position: absolute;
    top: 5px;
    display: grid;
    width: 100%;
    transition: 0.3s;
    flex: 1;
    grid-template-columns: minmax(10px, ${(p) => Math.max(0, p.pos - 10)}%) 1fr minmax(10px, ${(p) => Math.max(0, 100 - p.pos - 10)}%);
`;
function DayTotalBarChart({
    values,
    onHover,
    hasFilter,
}: {
    values: { date: number; cost: number }[];
    onHover: (item: { date: number; cost: number }) => void;
    hasFilter: boolean;
}) {
    const theme = useMantineTheme();
    const CustomBar = useMemo(
        () =>
            ({
                bar: { x, y, width, height, data },
            }: BarItemProps<{
                date: number;
                cost: number;
            }>) => {
                const hoverHandler = onHover.bind(null, data.data);
                if (data.data.cost !== 0 && height < 1) {
                    y = data.data.cost < 0 ? y : y - 1;
                }
                const color = !hasFilter ? theme.colors.gray[4] : data.data.cost < 0 ? theme.colors.success[4] : theme.colors.error[4];
                return (
                    <>
                        <rect rx={3} ry={3} x={x} width={width} y={y} height={height} fill={color}></rect>
                        <HoverRect onMouseEnter={hoverHandler} x={x - 2} width={width + 4} y={0} height={100}></HoverRect>
                    </>
                );
            },
        [hasFilter]
    );
    return (
        <ResponsiveBar
            indexBy={'date'}
            keys={['cost']}
            data={values}
            enableLabel={false}
            borderRadius={3}
            margin={{ top: 30, right: 0, bottom: 0, left: 0 }}
            padding={0.3}
            gridYValues={5}
            barComponent={CustomBar}
            axisBottom={{
                tickSize: 0,
                tickPadding: 0,
                tickRotation: 0,
                format: () => '',
            }}
        />
    );
}

const HoverRect = styled.rect`
    fill: ${(p) => p.theme.colors.gray[9]}04;
    &:hover {
        fill: ${(p) => p.theme.colors.gray[9]}1a;
    }
`;
