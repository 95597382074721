import { useCallback } from 'react';
import { container, DependencyContainer, inject, singleton } from 'tsyringe';
import { useDi, useDiContainer } from '../DI';
import { EventEmitter, useEvent } from '../EventEmitter';
import { useNav } from '../NavigationService';
import { EndpointRegistry, IEndpoint } from './EndpointRegistry';
import { IRouteChange, Router } from './Router';
import { IRouteSegment, Route, RouteSerializer } from './RouteSerializer';

export const IRouteMetaToken = 'IRouteMetaToken';
export const IChildRouteReady = 'IChildRouteReady';
export interface IRouteMeta {
    consumed: Route;
    consumedPath: string;
    fullRoute: Route;
    route: Route;
    parentRoute: Route;
    fullPath: string;
    currentPath: string;
    currentPathWithoutParams: string;
    endpointName: string;
    endpointInfo?: IEndpoint;
    params: Record<string, string>;
    container: DependencyContainer;
    /**
     * Identifier for the route, the path-so-far including params of the current route only if controlsDescendants is true of the endpoint.
     */
    routeMetaKey: string;
    invalidated?: boolean;
}

export interface IRouteMetaChangingEvent {
    current: IRouteMeta[];
    next: IRouteMeta[];
    pause(promise: Promise<void>): void;
}

@singleton()
export class ConsumedRouteService {
    public onDepthChanged = new EventEmitter(0);
    public get depth() {
        return this.onDepthChanged.value;
    }
    public set depth(value: number) {
        if (this.depth !== value) {
            this.onDepthChanged.emit(value);
        }
    }
}

@singleton()
export class BasicRouteLoader {
    private metaLookup = new Map<string, IRouteMeta>();
    public routeMeta = new EventEmitter<IRouteMeta[]>([]);
    public routeMetaChanging = new EventEmitter<IRouteMetaChangingEvent | undefined>(undefined);
    public ready = new EventEmitter<boolean>(false);

    public constructor(
        @inject(Router) private router: Router,
        @inject(RouteSerializer) private routeSerializer: RouteSerializer,
        @inject(EndpointRegistry) private endpointRegistry: EndpointRegistry,
        @inject(ConsumedRouteService) private consumedRoute: ConsumedRouteService
    ) {
        this.router.route.listen(this.handleRouteChange);
        this.init();
    }

    public getTopRouteMeta() {
        return this.routeMeta.value?.slice(-1)?.[0];
    }

    private handleConsumedDepthChange = () => {
        this.loadRoute(this.router.getCurrentRoute()?.newRoute ?? []);
    };

    private handleRouteChange = (change?: IRouteChange) => {
        if (change) {
            this.loadRoute(change.newRoute);
        }
    };

    private async init() {
        await this.loadRoute(this.router.getCurrentRoute()?.newRoute ?? []);
        this.consumedRoute.onDepthChanged.listen(this.handleConsumedDepthChange);
        this.ready.emit(true);
    }

    private async loadRoute(route: Route) {
        const consumed = route.slice(0, this.consumedRoute.depth);
        route = route.slice(this.consumedRoute.depth);
        if (route.length === 0) {
            route.push({ data: {}, name: '' });
        }
        const consumedPath = this.routeSerializer.serialize(consumed);
        const nextLookup = new Map<string, IRouteMeta>();
        const fullPath = this.routeSerializer.serialize(route);
        const partialRoute: IRouteSegment[] = [];
        const nextRouteMeta: IRouteMeta[] = [];
        const parentRoute: Route = [];
        let nextContainer = container;
        for (const item of route) {
            const endpointInfo = this.endpointRegistry.get(item.name);
            const currentPathWithoutParams = this.routeSerializer.serialize([...partialRoute, { name: item.name, data: {} }]);
            partialRoute.push(item);
            const currentPath = this.routeSerializer.serialize(partialRoute);
            const routeMetaKey = endpointInfo?.controlDescendants ? currentPath : currentPathWithoutParams;
            const reusedMeta = this.metaLookup.get(routeMetaKey);
            const container = reusedMeta?.container ?? nextContainer;

            const meta: IRouteMeta = {
                consumed,
                consumedPath,
                route: [...partialRoute],
                fullRoute: route,
                parentRoute: [...parentRoute],
                fullPath,
                container,
                currentPath,
                currentPathWithoutParams,
                endpointName: item.name,
                params: item.data,
                endpointInfo,
                routeMetaKey,
            };
            if (!meta.container.isRegistered(IChildRouteReady) || !reusedMeta) {
                meta.container.register(IChildRouteReady, { useValue: new EventEmitter<boolean>(!meta.endpointInfo?.controlDescendants) });
            }
            if (reusedMeta) {
                reusedMeta.invalidated = true;
            }
            meta.container.register(IRouteMetaToken, { useValue: meta });
            nextContainer = meta.container.createChildContainer();

            nextLookup.set(routeMetaKey, meta);
            nextRouteMeta.push(meta);
            parentRoute.push(item);
        }

        const changingEvent = this.createChangingEvent(this.routeMeta.value ?? [], nextRouteMeta);
        this.routeMetaChanging.emit(changingEvent.event);
        await Promise.all(changingEvent.promises).then(() => {
            this.metaLookup = nextLookup;
            this.routeMeta.emit(nextRouteMeta);
        });
    }

    private createChangingEvent(current: IRouteMeta[], next: IRouteMeta[]) {
        const promises: Promise<void>[] = [];
        return {
            promises,
            event: {
                current,
                next,
                pause: (promise: Promise<void>) => promises.push(promise),
            },
        };
    }
}

export function useEndpointUnloading(handler: () => Promise<void> | void) {
    const loader = useDi(BasicRouteLoader);
    const { routeMetaKey } = useDi(IRouteMetaToken) as IRouteMeta;
    const routeChangingHandler = useCallback(
        (evt: IRouteMetaChangingEvent | undefined) => {
            if (evt && !evt.next.find((r) => r.routeMetaKey === routeMetaKey)) {
                const handlerResult = handler();
                if (handlerResult) {
                    evt.pause(handlerResult);
                }
            }
        },
        [routeMetaKey, handler]
    );
    useEvent(loader.routeMetaChanging, routeChangingHandler);
}
